import sleep from "@cosine/lib/utils/async/sleep";
import useAuthStore from "@cosine/stores/useAuthStore";
import * as Sentry from "@sentry/vue";
import { LDClient, LDContext, LDFlagSet, initialize as createLaunchDarklyClient } from "launchdarkly-js-client-sdk";
import { defineStore, storeToRefs } from "pinia";
import { computed, readonly, ref, watch } from "vue";

export default defineStore("FlagStore", () => {
  const { currentUser } = storeToRefs(useAuthStore());

  const flagMap = ref<LDFlagSet>({});
  const context = computed((): LDContext => {
    return {
      kind: "user",
      key: currentUser.value?.UserId,
      anonymous: !currentUser.value,
    };
  });

  const launchDarklyClient = ref<LDClient>(createLaunchDarklyClient(import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_SIDE_ID, context.value));
  const isLaunchDarklyReady = ref(false);

  async function initLaunchDarkly() {
    try {
      await launchDarklyClient.value.waitForInitialization();
      launchDarklyClient.value.on("change", updateFlags);
      isLaunchDarklyReady.value = true;
      updateFlags();
    } catch (err) {
      Sentry.captureException(err);
      await sleep(500);
      await initLaunchDarkly();
    }
  }

  async function syncContext() {
    if (!launchDarklyClient.value) return;

    await launchDarklyClient.value.identify(context.value);
  }

  function updateFlags() {
    flagMap.value = launchDarklyClient.value.allFlags();
  }

  initLaunchDarkly();
  watch(context, syncContext);

  return {
    launchDarklyClient,
    isLaunchDarklyReady,
    flagMap: readonly(flagMap),
  };
});
