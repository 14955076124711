<template>
  <Transition>
    <SheetModal
      v-if="isAccountSheetModalOpen && editingAccount"
      class="AccountSheetModalView"
      theme="Light"
      @close="finishEditingAccount"
    >
      <template #summary>
        <AccountSheetSummary
          :account="editingAccount"
        />
      </template>
      <template #buttonList>
        <SheetButton
          class="AccountSheetModalView__transactionsButton"
          iconName="Dollar"
          label="View transactions"
          :disabled="isRequesting"
          @click="handleViewTransactions"
        />
        <SheetButton
          class="AccountSheetModalView__scopeButton"
          :iconName="editingAccount.Scope === 'Normal' ? 'EyeCrossed' : 'Eye'"
          :label="editingAccount.Scope === 'Normal' ? 'Deactivate account' : 'Activate account'"
          :disabled="isRequesting || editingAccount.Scope === 'Limited' && !canActivateMoreAccounts"
          @click="handleToggleAccount"
        />
      </template>
    </SheetModal>
  </Transition>
</template>

<script lang="ts" setup>
import AccountSheetSummary from "@cosine/components/AccountSheetSummary.vue";
import SheetButton from "@cosine/components/SheetButton.vue";
import SheetModal from "@cosine/components/SheetModal.vue";
import useRequest from "@cosine/composables/useRequest";
import { RouteName } from "@cosine/routes";
import useFinancialStore from "@cosine/stores/useFinancialStore";
import useModalStore from "@cosine/stores/useModalStore";
import { ModalName } from "@cosine/stores/useModalStore.types";
import { AccountScope } from "@cosine/types/api-models";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const {
  isModalOpen,
  closeModal,
} = useModalStore();
const financialStore = useFinancialStore();
const {
  editingAccount,
  canActivateMoreAccounts,
} = storeToRefs(financialStore);
const {
  finishEditingAccount,
  updateAccountScope,
} = financialStore;
const {
  isRequesting,
  handleRequest,
} = useRequest();

const isAccountSheetModalOpen = computed(() => isModalOpen(ModalName.EditAccount));

function handleViewTransactions() {
  if (!editingAccount.value) return;

  closeModal(ModalName.EditAccount);
  router.push({ name: RouteName.transactions, query: { account: editingAccount.value.EraAccountId }});
}

async function handleToggleAccount() {
  if (!editingAccount.value) return;

  const scope = editingAccount.value.Scope === AccountScope.Normal ? AccountScope.Limited : AccountScope.Normal;

  await handleRequest(
    updateAccountScope(editingAccount.value, scope),
    {
      showAlerts: true,
    },
  );
}
</script>
