export enum TimeZone {
  "Pacific/Pago_Pago" = "Pacific/Pago_Pago",
  "Pacific/Midway" = "Pacific/Midway",
  "Pacific/Apia" = "Pacific/Apia",
  "Pacific/Honolulu" = "Pacific/Honolulu",
  "America/Juneau" = "America/Juneau",
  "America/Los_Angeles" = "America/Los_Angeles",
  "America/Tijuana" = "America/Tijuana",
  "America/Phoenix" = "America/Phoenix",
  "America/Chihuahua" = "America/Chihuahua",
  "America/Mazatlan" = "America/Mazatlan",
  "America/Denver" = "America/Denver",
  "America/Guatemala" = "America/Guatemala",
  "America/Chicago" = "America/Chicago",
  "America/Mexico_City" = "America/Mexico_City",
  "America/Monterrey" = "America/Monterrey",
  "America/Regina" = "America/Regina",
  "America/Bogota" = "America/Bogota",
  "America/New_York" = "America/New_York",
  "America/Indiana/Indianapolis" = "America/Indiana/Indianapolis",
  "America/Lima" = "America/Lima",
  "America/Caracas" = "America/Caracas",
  "America/Halifax" = "America/Halifax",
  "America/Guyana" = "America/Guyana",
  "America/La_Paz" = "America/La_Paz",
  "America/Santiago" = "America/Santiago",
  "America/Montevideo" = "America/Montevideo",
  "America/St_Johns" = "America/St_Johns",
  "America/Sao_Paulo" = "America/Sao_Paulo",
  "America/Argentina/Buenos_Aires" = "America/Argentina/Buenos_Aires",
  "America/Godthab" = "America/Godthab",
  "Atlantic/South_Georgia" = "Atlantic/South_Georgia",
  "Atlantic/Azores" = "Atlantic/Azores",
  "Atlantic/Cape_Verde" = "Atlantic/Cape_Verde",
  "Africa/Casablanca" = "Africa/Casablanca",
  "Europe/Dublin" = "Europe/Dublin",
  "Europe/Lisbon" = "Europe/Lisbon",
  "Europe/London" = "Europe/London",
  "Africa/Monrovia" = "Africa/Monrovia",
  "Etc/UTC" = "Etc/UTC",
  "Europe/Amsterdam" = "Europe/Amsterdam",
  "Europe/Belgrade" = "Europe/Belgrade",
  "Europe/Berlin" = "Europe/Berlin",
  "Europe/Bratislava" = "Europe/Bratislava",
  "Europe/Brussels" = "Europe/Brussels",
  "Europe/Budapest" = "Europe/Budapest",
  "Europe/Copenhagen" = "Europe/Copenhagen",
  "Europe/Ljubljana" = "Europe/Ljubljana",
  "Europe/Madrid" = "Europe/Madrid",
  "Europe/Paris" = "Europe/Paris",
  "Europe/Prague" = "Europe/Prague",
  "Europe/Rome" = "Europe/Rome",
  "Europe/Sarajevo" = "Europe/Sarajevo",
  "Europe/Skopje" = "Europe/Skopje",
  "Europe/Stockholm" = "Europe/Stockholm",
  "Europe/Vienna" = "Europe/Vienna",
  "Europe/Warsaw" = "Europe/Warsaw",
  "Africa/Algiers" = "Africa/Algiers",
  "Europe/Zagreb" = "Europe/Zagreb",
  "Europe/Athens" = "Europe/Athens",
  "Europe/Bucharest" = "Europe/Bucharest",
  "Africa/Cairo" = "Africa/Cairo",
  "Africa/Harare" = "Africa/Harare",
  "Europe/Helsinki" = "Europe/Helsinki",
  "Europe/Istanbul" = "Europe/Istanbul",
  "Asia/Jerusalem" = "Asia/Jerusalem",
  "Europe/Kiev" = "Europe/Kiev",
  "Africa/Johannesburg" = "Africa/Johannesburg",
  "Europe/Riga" = "Europe/Riga",
  "Europe/Sofia" = "Europe/Sofia",
  "Europe/Tallinn" = "Europe/Tallinn",
  "Europe/Vilnius" = "Europe/Vilnius",
  "Asia/Baghdad" = "Asia/Baghdad",
  "Asia/Kuwait" = "Asia/Kuwait",
  "Europe/Minsk" = "Europe/Minsk",
  "Africa/Nairobi" = "Africa/Nairobi",
  "Asia/Riyadh" = "Asia/Riyadh",
  "Asia/Tehran" = "Asia/Tehran",
  "Asia/Muscat" = "Asia/Muscat",
  "Asia/Baku" = "Asia/Baku",
  "Europe/Moscow" = "Europe/Moscow",
  "Asia/Tbilisi" = "Asia/Tbilisi",
  "Asia/Yerevan" = "Asia/Yerevan",
  "Asia/Kabul" = "Asia/Kabul",
  "Asia/Karachi" = "Asia/Karachi",
  "Asia/Tashkent" = "Asia/Tashkent",
  "Asia/Kolkata" = "Asia/Kolkata",
  "Asia/Colombo" = "Asia/Colombo",
  "Asia/Kathmandu" = "Asia/Kathmandu",
  "Asia/Almaty" = "Asia/Almaty",
  "Asia/Dhaka" = "Asia/Dhaka",
  "Asia/Yekaterinburg" = "Asia/Yekaterinburg",
  "Asia/Rangoon" = "Asia/Rangoon",
  "Asia/Bangkok" = "Asia/Bangkok",
  "Asia/Jakarta" = "Asia/Jakarta",
  "Asia/Novosibirsk" = "Asia/Novosibirsk",
  "Asia/Shanghai" = "Asia/Shanghai",
  "Asia/Chongqing" = "Asia/Chongqing",
  "Asia/Hong_Kong" = "Asia/Hong_Kong",
  "Asia/Krasnoyarsk" = "Asia/Krasnoyarsk",
  "Asia/Kuala_Lumpur" = "Asia/Kuala_Lumpur",
  "Australia/Perth" = "Australia/Perth",
  "Asia/Singapore" = "Asia/Singapore",
  "Asia/Taipei" = "Asia/Taipei",
  "Asia/Ulaanbaatar" = "Asia/Ulaanbaatar",
  "Asia/Urumqi" = "Asia/Urumqi",
  "Asia/Irkutsk" = "Asia/Irkutsk",
  "Asia/Seoul" = "Asia/Seoul",
  "Asia/Tokyo" = "Asia/Tokyo",
  "Australia/Adelaide" = "Australia/Adelaide",
  "Australia/Darwin" = "Australia/Darwin",
  "Australia/Brisbane" = "Australia/Brisbane",
  "Pacific/Guam" = "Pacific/Guam",
  "Australia/Hobart" = "Australia/Hobart",
  "Australia/Melbourne" = "Australia/Melbourne",
  "Pacific/Port_Moresby" = "Pacific/Port_Moresby",
  "Australia/Sydney" = "Australia/Sydney",
  "Asia/Yakutsk" = "Asia/Yakutsk",
  "Pacific/Noumea" = "Pacific/Noumea",
  "Pacific/Guadalcanal" = "Pacific/Guadalcanal",
  "Asia/Vladivostok" = "Asia/Vladivostok",
  "Pacific/Auckland" = "Pacific/Auckland",
  "Pacific/Fiji" = "Pacific/Fiji",
  "Asia/Kamchatka" = "Asia/Kamchatka",
  "Asia/Magadan" = "Asia/Magadan",
  "Pacific/Majuro" = "Pacific/Majuro",
  "Pacific/Chatham" = "Pacific/Chatham",
  "Pacific/Tongatapu" = "Pacific/Tongatapu",
  "Pacific/Fakaofo" = "Pacific/Fakaofo",
}

export const TimeZones: { [key in TimeZone]: string } = {
  "Pacific/Pago_Pago": "American Samoa",
  "Pacific/Midway": "Midway Island",
  "Pacific/Apia": "Samoa",
  "Pacific/Honolulu": "Hawaii",
  "America/Juneau": "Alaska",
  "America/Los_Angeles": "Pacific Time (US & Canada)",
  "America/Tijuana": "Tijuana",
  "America/Phoenix": "Arizona",
  "America/Chihuahua": "Chihuahua",
  "America/Mazatlan": "Mazatlan",
  "America/Denver": "Mountain Time (US & Canada)",
  "America/Guatemala": "Central America",
  "America/Chicago": "Central Time (US & Canada)",
  "America/Mexico_City": "Mexico City",
  "America/Monterrey": "Monterrey",
  "America/Regina": "Saskatchewan",
  "America/Bogota": "Bogota",
  "America/New_York": "Eastern Time (US & Canada)",
  "America/Indiana/Indianapolis": "Indiana (East)",
  "America/Lima": "Lima",
  "America/Caracas": "Caracas",
  "America/Halifax": "Atlantic Time (Canada)",
  "America/Guyana": "Georgetown",
  "America/La_Paz": "La Paz",
  "America/Santiago": "Santiago",
  "America/Montevideo": "Montevideo",
  "America/St_Johns": "Newfoundland",
  "America/Sao_Paulo": "Brasilia",
  "America/Argentina/Buenos_Aires": "Buenos Aires",
  "America/Godthab": "Greenland",
  "Atlantic/South_Georgia": "Mid-Atlantic",
  "Atlantic/Azores": "Azores",
  "Atlantic/Cape_Verde": "Cape Verde Islands",
  "Africa/Casablanca": "Casablanca",
  "Europe/Dublin": "Dublin",
  "Europe/Lisbon": "Lisbon",
  "Europe/London": "London",
  "Africa/Monrovia": "Monrovia",
  "Etc/UTC": "UTC",
  "Europe/Amsterdam": "Amsterdam",
  "Europe/Belgrade": "Belgrade",
  "Europe/Berlin": "Berlin",
  "Europe/Bratislava": "Bratislava",
  "Europe/Brussels": "Brussels",
  "Europe/Budapest": "Budapest",
  "Europe/Copenhagen": "Copenhagen",
  "Europe/Ljubljana": "Ljubljana",
  "Europe/Madrid": "Madrid",
  "Europe/Paris": "Paris",
  "Europe/Prague": "Prague",
  "Europe/Rome": "Rome",
  "Europe/Sarajevo": "Sarajevo",
  "Europe/Skopje": "Skopje",
  "Europe/Stockholm": "Stockholm",
  "Europe/Vienna": "Vienna",
  "Europe/Warsaw": "Warsaw",
  "Africa/Algiers": "West Central Africa",
  "Europe/Zagreb": "Zagreb",
  "Europe/Athens": "Athens",
  "Europe/Bucharest": "Bucharest",
  "Africa/Cairo": "Cairo",
  "Africa/Harare": "Harare",
  "Europe/Helsinki": "Helsinki",
  "Europe/Istanbul": "Istanbul",
  "Asia/Jerusalem": "Jerusalem",
  "Europe/Kiev": "Kyiv",
  "Africa/Johannesburg": "Pretoria",
  "Europe/Riga": "Riga",
  "Europe/Sofia": "Sofia",
  "Europe/Tallinn": "Tallinn",
  "Europe/Vilnius": "Vilnius",
  "Asia/Baghdad": "Baghdad",
  "Asia/Kuwait": "Kuwait",
  "Europe/Minsk": "Minsk",
  "Africa/Nairobi": "Nairobi",
  "Asia/Riyadh": "Riyadh",
  "Asia/Tehran": "Tehran",
  "Asia/Muscat": "Abu Dhabi",
  "Asia/Baku": "Baku",
  "Europe/Moscow": "Moscow",
  "Asia/Tbilisi": "Tbilisi",
  "Asia/Yerevan": "Yerevan",
  "Asia/Kabul": "Kabul",
  "Asia/Karachi": "Karachi",
  "Asia/Tashkent": "Tashkent",
  "Asia/Kolkata": "Kolkata",
  "Asia/Colombo": "Sri Jayawardenepura",
  "Asia/Kathmandu": "Kathmandu",
  "Asia/Almaty": "Almaty",
  "Asia/Dhaka": "Dhaka",
  "Asia/Yekaterinburg": "Ekaterinburg",
  "Asia/Rangoon": "Rangoon",
  "Asia/Bangkok": "Bangkok",
  "Asia/Jakarta": "Jakarta",
  "Asia/Novosibirsk": "Novosibirsk",
  "Asia/Shanghai": "Beijing",
  "Asia/Chongqing": "Chongqing",
  "Asia/Hong_Kong": "Hong Kong",
  "Asia/Krasnoyarsk": "Krasnoyarsk",
  "Asia/Kuala_Lumpur": "Kuala Lumpur",
  "Australia/Perth": "Perth",
  "Asia/Singapore": "Singapore",
  "Asia/Taipei": "Taipei",
  "Asia/Ulaanbaatar": "Ulaanbaatar",
  "Asia/Urumqi": "Urumqi",
  "Asia/Irkutsk": "Irkutsk",
  "Asia/Seoul": "Seoul",
  "Asia/Tokyo": "Tokyo",
  "Australia/Adelaide": "Adelaide",
  "Australia/Darwin": "Darwin",
  "Australia/Brisbane": "Brisbane",
  "Pacific/Guam": "Guam",
  "Australia/Hobart": "Hobart",
  "Australia/Melbourne": "Melbourne",
  "Pacific/Port_Moresby": "Port Moresby",
  "Australia/Sydney": "Sydney",
  "Asia/Yakutsk": "Yakutsk",
  "Pacific/Noumea": "New Caledonia",
  "Pacific/Guadalcanal": "Solomon Islands",
  "Asia/Vladivostok": "Vladivostok",
  "Pacific/Auckland": "Auckland",
  "Pacific/Fiji": "Fiji",
  "Asia/Kamchatka": "Kamchatka",
  "Asia/Magadan": "Magadan",
  "Pacific/Majuro": "Marshall Islands",
  "Pacific/Chatham": "Chatham Island",
  "Pacific/Tongatapu": "Nuku'alofa",
  "Pacific/Fakaofo": "Tokelau Islands",
};

export const currentTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
