import { RouteName } from "@cosine/routes";
import { RoutineTemplate, RoutineTemplateSlug } from "@cosine/stores/useRoutineStore.types";
import useTransferStore from "@cosine/stores/useTransferStore";
import { AccountType, RoutineTypes } from "@cosine/types/api-models";
import { defineStore, storeToRefs } from "pinia";
import { computed } from "vue";

export default defineStore("RoutineExploreStore", () => {
  const { enabledTransferAccounts } = storeToRefs(useTransferStore());

  const savingsAccount = computed(() => {
    return enabledTransferAccounts.value.find((account) => account.Type === AccountType.Savings);
  });

  const checkingAccount = computed(() => {
    return enabledTransferAccounts.value.find((account) => account.Type === AccountType.Checking);
  });

  const transferTemplates = computed(() => <Array<RoutineTemplate>>([
    {
      type: RoutineTypes.UserFixedAmountFundsTransfer,
      title: "Transfer a fixed amount",
      subtitle: "e.g. Move $1,700 into checking for rent every month",
      route: {
        name: RouteName.routinesNew,
        params: {
          slug: RoutineTemplateSlug.FixedAmountFundsTransfer,
        },
      },
    },
    {
      type: RoutineTypes.UserFixedAmountFundsTransfer,
      title: "Set aside $100 each week",
      subtitle: "Checking → Savings",
      route: {
        name: RouteName.routinesNew,
        params: {
          slug: RoutineTemplateSlug.FixedAmountFundsTransfer,
        },
        query: {
          from: checkingAccount.value?.EraAccountId,
          to: savingsAccount.value?.EraAccountId,
          amount: "10000",
          cron: "0 0 8 ? * FRI",
        },
      },
    },
    {
      type: RoutineTypes.UserFixedAmountFundsTransfer,
      title: "Budget for weekend trips",
      subtitle: "Savings → Checking",
      route: {
        name: RouteName.routinesNew,
        params: {
          slug: RoutineTemplateSlug.FixedAmountFundsTransfer,
        },
        query: {
          from: savingsAccount.value?.EraAccountId,
          to: checkingAccount.value?.EraAccountId,
          amount: "25000",
          cron: "0 0 8 ? * TUE",
        },
      },
    },
    {
      type: RoutineTypes.UserThresholdBasedFundsTransfer,
      title: "Maintain a minimum balance",
      subtitle: "e.g. Keep a six month emergency fund in savings",
      route: {
        name: RouteName.routinesNew,
        params: {
          slug: RoutineTemplateSlug.MinimumBalance,
        },
      },
    },
    {
      type: RoutineTypes.UserThresholdBasedFundsTransfer,
      title: "Make sure I have enough to pay rent",
      subtitle: "Savings → Checking",
      route: {
        name: RouteName.routinesNew,
        params: {
          slug: RoutineTemplateSlug.MinimumBalance,
        },
        query: {
          from: savingsAccount.value?.EraAccountId,
          to: checkingAccount.value?.EraAccountId,
          balance: "170000",
          cron: "0 0 8 15 * ?",
        },
      },
    },
    {
      type: RoutineTypes.UserRoundUpSavingsFundsTransfer,
      title: "Round up transactions into savings",
      subtitle: "e.g. Coffee spending to the nearest $10",
      route: {
        name: RouteName.routinesNew,
        params: {
          slug: RoutineTemplateSlug.RoundUpSavings,
        },
      },
    },
    {
      type: RoutineTypes.UserRoundUpSavingsFundsTransfer,
      title: "Swear jar",
      subtitle: "Round up bar & restaurant spending",
      route: {
        name: RouteName.routinesNew,
        params: {
          slug: RoutineTemplateSlug.RoundUpSavings,
        },
        query: {
          from: checkingAccount.value?.EraAccountId,
          to: savingsAccount.value?.EraAccountId,
          increment: "2500",
          categories: [
            "[Food & Dining]-[Alcohol & Bars]",
            "[Food & Dining]-[Restaurants]",
          ],
        },
      },
    },
  ]));

  return {
    transferTemplates,
  };
});
