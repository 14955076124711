import { easeInOutCubicString } from "@shared/lib/animation/easing";

class SpinnerSegment {
  el: HTMLElement;
  frontRightOverlay: HTMLElement;
  transformStyle: string;

  constructor(el: HTMLElement) {
    this.el = el;
    this.frontRightOverlay = el.querySelector(".Spinner__face--frontRight.Spinner__face--overlay") as HTMLElement;

    // We repeat the initial css instead of using getComputedStyle because it actually returns an imprecise transform that doesn’t match up as well.
    this.transformStyle = "rotateX(-35deg) rotateY(45deg) translateY(calc(var(--index) * (var(--radius) * 0.5 - 0.5px)))";
  }

  spin(options: EffectTiming = {}): Array<Animation> {
    if (!this.el.animate) { return []; }

    const effectTiming: EffectTiming = {
      easing: easeInOutCubicString,
      fill: "forwards",
      ...options,
    };

    const spinningAnimation = this.el.animate([
      { transform: this.transformStyle },
      { transform: `${this.transformStyle} rotateY(-0.25turn)` },
    ], effectTiming);

    const shadingAnimation = this.frontRightOverlay.animate([
      { opacity: 0 },
      { opacity: 1 },
    ], effectTiming);

    return [
      spinningAnimation,
      shadingAnimation,
    ];
  }
}

export class SpinnerAnimation {
  segments: Array<SpinnerSegment>;

  constructor(el: HTMLElement) {
    this.segments = Array.from(el.querySelectorAll<HTMLElement>(".Spinner__segment")).map((segmentEl) => {
      return new SpinnerSegment(segmentEl);
    });
  }
}
