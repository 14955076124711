<template>
  <section class="ConnectBrokerageView">
    <FormButton
      variant="Tertiary"
      class="ConnectBrokerageView__button"
      @click="handleModalOpen"
    >
      Search for your brokerage
    </FormButton>

    <Teleport to="body">
      <Transition @afterLeave="handleModalLeave">
        <SnaptradeWidgetModal
          v-show="isModalOpen(ModalName.ConnectBrokerage)"
          @close="closeSnaptradeModal"
        >
          <SnaptradeSecurityScreen
            v-if="viewState === 'Security'"
            @click:continue="handleSecurityContinue"
          />
          <div
            v-show="viewState === 'Connect'"
            ref="snaptradeWidgetEl"
            class="ConnectBrokerageView__widget"
          >
            <div>Connect Placeholder</div>
          </div>
        </SnaptradeWidgetModal>
      </Transition>
    </Teleport>
  </section>
</template>

<script lang="ts" setup>
import FormButton from "@cosine/components/FormButton.vue";
import SnaptradeSecurityScreen from "@cosine/components/SnaptradeSecurityScreen.vue";
import SnaptradeWidgetModal from "@cosine/components/SnaptradeWidgetModal.vue";
import useModalStore from "@cosine/stores/useModalStore";
import { ModalName } from "@cosine/stores/useModalStore.types";
import { ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const { openModal, closeModal, isModalOpen } = useModalStore();
const snaptradeWidgetEl = ref<HTMLElement>();
const viewState = ref<"Security" | "Connect">("Security");
function handleSecurityContinue() {
  viewState.value = "Connect";
}

function closeSnaptradeModal() {
  closeModal(ModalName.ConnectBrokerage);
  const query = { ...router.currentRoute.value.query };
  delete query.connect;
  router.replace({ query });
}

function handleModalLeave() {
  // TODO
}

async function openSnaptradeModal() {
  viewState.value = "Security";
  openModal(ModalName.ConnectBrokerage);
}

function handleModalOpen() {
  openSnaptradeModal();
}
</script>

<style lang="scss" scoped>
.ConnectBrokerageView {
  position: relative;
}
</style>
