<template>
  <AppHeader
    v-bind="{
      badge,
      connectionState,
    }"
    :isChatAnimating="!hasChattedToday"
    :isThreadOpen="threadVisibility === ThreadVisibility.Thread"
    @click:logoIcon="handleLogoIconClick"
    @click:badge="handleBadgeClick"
    @close="closeThread"
  />
</template>

<script lang="ts" setup>
import { AppHeaderBadge } from "@cosine/components/AppHeader.types";
import AppHeader from "@cosine/components/AppHeader.vue";
import { RoutePath } from "@cosine/routes";
import useAuthStore from "@cosine/stores/useAuthStore";
import useConnectionStore from "@cosine/stores/useConnectionStore";
import useFeatureLimitStore from "@cosine/stores/useFeatureLimitStore";
import useSubscriptionStore from "@cosine/stores/useSubscriptionStore";
import useThreadStore, { ThreadVisibility } from "@cosine/stores/useThreadStore";
import { FeatureLimitType } from "@cosine/types/api-models";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const { connectionState } = storeToRefs(useConnectionStore());
const { currentUser } = storeToRefs(useAuthStore());
const subscriptionStore = useSubscriptionStore();
const {
  isSubscribed,
  canUpgrade,
} = storeToRefs(subscriptionStore);
const { startEditingSubscription } = subscriptionStore;
const timelineStore = useThreadStore();
const {
  openThread,
  closeThread,
} = timelineStore;
const { threadVisibility } = storeToRefs(timelineStore);
const { getFeatureLimit } = useFeatureLimitStore();

const badge = computed((): AppHeaderBadge => {
  return isSubscribed.value
    ? {
      label: "Plus",
      color: "Teal",
    }
    : {
      label: "Trial",
      color: "Grey",
    };
});

const hasChattedToday = computed((): boolean => {
  const limit = getFeatureLimit(FeatureLimitType.ChatMessageCount);

  return !limit || limit.UsageCount > 0;
});

function handleLogoIconClick() {
  openThread();
}

function handleBadgeClick() {
  if (canUpgrade.value) {
    startEditingSubscription(currentUser.value?.Subscription || null);
  } else {
    router.push(RoutePath.settings);
  }
}
</script>
