import { defineStore } from "pinia";
import { ref } from "vue";

export default defineStore("AppStore", () => {
  const isAppVisible = ref(true);
  const isLoading = ref(false);

  return {
    isAppVisible,
    isLoading,
  };
});
