import useEditModal from "@cosine/composables/useEditModal";
import useConnectionStore from "@cosine/stores/useConnectionStore";
import { ModalName } from "@cosine/stores/useModalStore.types";
import { ClientConstants, Constants, IClientTimelineEntry, IPaginatedList, ITimelineEntryRequest, TimelineEntryVisibilities } from "@cosine/types/api-models";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export default defineStore("TimelineStore", () => {
  const { signalRClient } = useConnectionStore();
  const entries = ref<Array<IClientTimelineEntry>>([]);
  const pagination = ref({
    pageIndex: 1,
    pageCount: 1,
  });
  const {
    editingItem: editingEntry,
    startEditingItem: startEditingEntry,
    finishEditingItem: finishEditingEntry,
  } = useEditModal<IClientTimelineEntry>(ModalName.EditTimelineEntry);
  const {
    editingItem: viewingArticle,
    startEditingItem: startViewingArticle,
    finishEditingItem: finishViewingArticle,
  } = useEditModal<IClientTimelineEntry>(ModalName.ViewArticle);

  const entryIds = computed((): Array<string> => entries.value.map((entry) => String(entry.IdReference)));
  const sortedEntries = computed((): Array<IClientTimelineEntry> => {
    return entries.value.sort((a, b) => {
      const aDate = a.DateForDisplay || a.DateCreated;
      const bDate = b.DateForDisplay || b.DateCreated;

      if (aDate < bDate) { return 1; }
      if (aDate > bDate) { return -1; }
      return 0;
    });
  });

  function connectTimeline() {
    signalRClient.connection.on(ClientConstants.ReceiveTimelineEntryFromServer, handleTimelineEntryFromServer);
  }

  function disconnectTimeline() {
    signalRClient.connection.off(ClientConstants.ReceiveTimelineEntryFromServer, handleTimelineEntryFromServer);
  }

  async function fetchEntries({ pageIndex } = { pageIndex: 1 }): Promise<void> {
    const params: ITimelineEntryRequest = {
      Page: pageIndex,
      PerPage: 50,
    };

    const {
      Items: fetchedEntries,
      Pagination: { Page, TotalPages },
    } = await signalRClient.invokeWithReconnect<IPaginatedList<IClientTimelineEntry>>(Constants.FindTimelineEntries, params);

    if (Page === 1) {
      entries.value = [...fetchedEntries];
    } else {
      const newEntries = fetchedEntries.filter((entry) => {
        return entry.IdReference && !entryIds.value.includes(entry.IdReference);
      });

      entries.value.push(...newEntries);
    }

    pagination.value.pageIndex = Page;
    pagination.value.pageCount = TotalPages;
  }

  function fetchEntry(id: string): Promise<IClientTimelineEntry> {
    return signalRClient.invokeWithReconnect<IClientTimelineEntry>(Constants.GetTimelineEntry, id);
  }

  // TODO: test
  async function bookmarkEntry(id: string, shouldBookmark: boolean): Promise<IClientTimelineEntry> {
    const methodName = shouldBookmark ? Constants.PinTimelineEntry : Constants.UnpinTimelineEntry;
    const updatedEntry = await signalRClient.invokeWithReconnect<IClientTimelineEntry>(methodName, id);
    handleTimelineEntryFromServer(updatedEntry);
    return updatedEntry;
  }

  function updateExistingEntry(existingEntry: IClientTimelineEntry, entry: IClientTimelineEntry) {
    const entryImageUrls: Pick<IClientTimelineEntry, "ImageUrls"> = {
      ImageUrls: entry.ImageUrls.length === 0 && existingEntry.ImageUrls.length > 0
        ? existingEntry.ImageUrls
        : entry.ImageUrls,
    };

    Object.assign(existingEntry, entry, entryImageUrls);
  }

  function handleTimelineEntryFromServer(entry: IClientTimelineEntry) {
    const existingEntry = entries.value.find((_entry) => _entry.IdReference === entry.IdReference);

    if (existingEntry && (entry.DateDeleted || entry.Visibility !== TimelineEntryVisibilities.Visible)) {
      entries.value.splice(entries.value.indexOf(existingEntry), 1);
    } else if (existingEntry) {
      updateExistingEntry(existingEntry, entry);
    } else if (!existingEntry) {
      entries.value.unshift(entry);
    }
  }

  return {
    entries: sortedEntries,
    pagination,
    editingEntry,
    viewingArticle,

    connectTimeline,
    disconnectTimeline,
    fetchEntries,
    fetchEntry,
    bookmarkEntry,
    startEditingEntry,
    finishEditingEntry,
    startViewingArticle,
    finishViewingArticle,
  };
});
