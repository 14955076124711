import { RoutineTypes } from "@cosine/types/api-models";
import { RouteLocationNamedRaw } from "vue-router";

export interface RoutineTemplate {
  type: RoutineTypes,
  title: string,
  subtitle: string,
  route: RouteLocationNamedRaw,
}

export enum RoutineTemplateSlug {
  FixedAmountFundsTransfer = "fixed-amount-funds-transfer",
  MinimumBalance = "minimum-balance",
  RoundUpSavings = "round-up-savings",
}
