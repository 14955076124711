export default function createQueryParams<T extends object>(params: T): string {
  return Object.entries(params).flatMap(([key, value]) => {
    if (Array.isArray(value)) {
      return value.map((_value) => {
        if (!_value) return;

        return `${key}=${encodeURIComponent(_value)}`;
      });
    } else if (value) {
      return `${key}=${encodeURIComponent(value)}`;
    }
  }).filter(Boolean).join("&");
}
