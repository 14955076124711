<template>
  <RouterLink
    class="TabNavLink"
    v-bind="{to}"
    :activeClass="isExact ? undefined : 'TabNavLink--isActive'"
    :exactActiveClass="isExact ? 'TabNavLink--isActive' : undefined"
    @click="handleClick"
  >
    <UIText
      class="TabNavLink__hiddenText"
      size="Small"
      weight="Medium"
      aria-hidden="true"
    >
      {{ label }}
    </UIText>
    <UIText
      class="TabNavLink__text"
      size="Small"
      weight="Medium"
    >
      {{ label }}
    </UIText>
  </RouterLink>
</template>

<script lang="ts" setup>
import UIText from "@cosine/components/UIText.vue";
import useTapToScrollToTop from "@cosine/composables/useTapToScrollToTop";
import { RouteLocationRaw, RouterLink } from "vue-router";

withDefaults(defineProps<{
  label: string,
  to: RouteLocationRaw,
  isExact?: boolean,
}>(), {
  isExact: false,
});
const { scrollToTop } = useTapToScrollToTop();

function handleClick(e: MouseEvent) {
  // TODO: see if there’s a way to check the active route without relying on the class
  if (!(e.currentTarget as HTMLElement).classList.contains("TabNavLink--isActive")) return;

  scrollToTop();
}
</script>

<style lang="scss" scoped>
.TabNavLink {
  position: relative;
  padding: 0 8px;
  height: var(--tabNavHeight);
  text-decoration: none;
  white-space: nowrap;
}

.TabNavLink__hiddenText {
  display: block;
  visibility: hidden;
}

.TabNavLink__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: var(--defaultMobileTransition);
  transition-property: font-variation-settings, color;
  color: var(--colorSwissAlphaBlack400);

  .TabNavLink--isActive & {
    font-weight: var(--fontWeightSaansSemibold);
    color: var(--colorSwissBlack);
  }
}
</style>
