<template>
  <Transition>
    <SheetModal
      v-if="isTransferModalOpen"
      class="InitiateTransferSheetModalView"
      theme="Light"
      size="Fill"
      @close="$router.push({name: RouteName.transfers})"
    >
      <Transition>
        <PrepareTransferView
          v-if="
            route.name === RouteName.transfersNew ||
              route.name === RouteName.transfersEdit && transfer
          "
          v-bind="{transfer}"
        />
        <ReviewTransferView
          v-else-if="route.name === RouteName.transfersReview && transfer"
          v-bind="{transfer}"
        />
      </Transition>
      <Spinner
        :isVisible="isTransferRequired && !transfer"
        isCentered
      />
    </SheetModal>
  </Transition>
</template>

<script lang="ts" setup>
import SheetModal from "@cosine/components/SheetModal.vue";
import Spinner from "@cosine/components/Spinner.vue";
import { RouteName } from "@cosine/routes";
import useTransferStore from "@cosine/stores/useTransferStore";
import PrepareTransferView from "@cosine/views/PrepareTransferView.vue";
import ReviewTransferView from "@cosine/views/ReviewTransferView.vue";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const {transfers} = storeToRefs(useTransferStore());

// TODO: fetch transfer if not in store
const transfer = computed(() => {
  if (!isTransferRequired.value) return;

  return transfers.value.find((transfer) => transfer.EraFundsTransferId === route.params.id);
});

const isTransferModalOpen = computed(() => {
  return [
    RouteName.transfersNew,
    RouteName.transfersEdit,
    RouteName.transfersReview,
  ].includes(route.name as RouteName);
});

const isTransferRequired = computed(() => {
  return [
    RouteName.transfersEdit,
    RouteName.transfersReview,
  ].includes(route.name as RouteName);
});
</script>
