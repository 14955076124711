<template>
  <section class="TimelineView">
    <TabNavView
      class="TimelineView__tabNav"
      :items="tabNavItems"
      isSubNav
    />
    <slot />
  </section>

  <Teleport to="body">
    <ArticleModalView />
  </Teleport>
</template>

<script lang="ts" setup>
import { TabNavItem } from "@cosine/components/TabNav.types";
import { RouteName } from "@cosine/routes";
import ArticleModalView from "@cosine/views/ArticleModalView.vue";
import TabNavView from "@cosine/views/TabNavView.vue";
import { ref } from "vue";

// TODO: test

const tabNavItems = ref<Array<TabNavItem>>([
  { label: "All", to: { name: RouteName.timelineAll } },
  { label: "News", to: { name: RouteName.timelineNews } },
  { label: "Chats", to: { name: RouteName.timelineChats } },
  { label: "Bookmarks", to: { name: RouteName.timelineBookmarks } },
]);
</script>

<style lang="scss" scoped>
.TimelineView {
  display: grid;
  grid: min-content auto / auto;
  height: 100%;
}
</style>
