<template>
  <section class="StockListView">
    <TransitionGroup
      class="StockListView__cardList"
      tag="div"
    >
      <StockCard
        v-for="(modelValue, index) in marketSeries"
        :key="modelValue.Summary.DisplaySymbol"
        v-bind="{
          modelValue,
          index,
        }"
      />
    </TransitionGroup>

    <Spinner
      :isVisible="marketSeries.length === 0"
      isCentered
    />
  </section>
</template>

<script lang="ts" setup>
import Spinner from "@cosine/components/Spinner.vue";
import StockCard from "@cosine/components/StockCard.vue";
import useMarketWatch from "@cosine/composables/useMarketWatch";
import { MarketSymbol } from "@cosine/stores/useMarketStore.types";

const { marketSeries } = useMarketWatch(Object.keys(MarketSymbol) as Array<MarketSymbol>);
</script>

<style lang="scss" scoped>
.StockListView {
  min-height: 100%;
  background: var(--colorSwissGrey50);
}

.StockListView__cardList {
  display: grid;
  gap: 16px;
}
</style>
