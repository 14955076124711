import { Ref, onBeforeUnmount, onMounted, watch } from "vue";

export default function (elRef: Ref<HTMLElement | undefined>) {
  onMounted(() => {
    window.addEventListener("resize", handleResize);
  });

  onBeforeUnmount(() => {
    window.removeEventListener("resize", handleResize);
  });

  watch(elRef, () => {
    handleResize();
  });

  function handleResize() {
    if (!elRef.value) return;

    const viewportWidth = elRef.value.clientWidth;
    const scrollbarWidth = window.innerWidth - viewportWidth;

    document.documentElement.style.setProperty("--viewportWidth", `${viewportWidth}px`);
    document.documentElement.style.setProperty("--scrollbarWidth", `${scrollbarWidth}px`);
  }
}
