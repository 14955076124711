<template>
  <Transition>
    <section
      v-if="isArticleModalOpen && combinedEntry"
      class="ArticleModalView"
    >
      <div
        class="ArticleModalView__background"
        @click="finishViewingArticle"
      />
      <div class="ArticleModalView__window">
        <ScrollContainer
          class="ArticleModalView__scrollContainer"
          includeScrollbar
        >
          <EntryArticleView
            :modelValue="combinedEntry"
          />
        </ScrollContainer>
        <IconButton
          class="ArticleModalView__backButton"
          iconName="ArrowLeft"
          @click="finishViewingArticle"
        />
        <LineSpinner
          class="ArticleModalView__lineSpinner"
          :isVisible="isLoading"
        />
      </div>
    </section>
  </Transition>
</template>

<script lang="ts" setup>
import IconButton from "@cosine/components/IconButton.vue";
import LineSpinner from "@cosine/components/LineSpinner.vue";
import ScrollContainer from "@cosine/components/ScrollContainer.vue";
import useRequest from "@cosine/composables/useRequest";
import useModalStore from "@cosine/stores/useModalStore";
import { ModalName } from "@cosine/stores/useModalStore.types";
import useThreadStore from "@cosine/stores/useThreadStore";
import useTimelineStore from "@cosine/stores/useTimelineStore";
import { IClientTimelineEntry } from "@cosine/types/api-models";
import EntryArticleView from "@cosine/views/EntryArticleView.vue";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";

const {
  isModalOpen,
} = useModalStore();
const {
  pinThread,
  unpinThread,
} = useThreadStore();
const timelineStore = useTimelineStore();
const {
  fetchEntry,
  finishViewingArticle,
} = timelineStore;
const {
  viewingArticle,
} = storeToRefs(timelineStore);
const {
  handleRequest,
  isRequesting: isLoading,
} = useRequest();

const fullStaticEntry = ref<IClientTimelineEntry | null>(null);

const isArticleModalOpen = computed(() => isModalOpen(ModalName.ViewArticle));
const combinedEntry = computed(() => {
  if (!viewingArticle.value) return;

  return {
    ...viewingArticle.value,
    BodyAsMarkdown: fullStaticEntry.value?.BodyAsMarkdown || viewingArticle.value.BodyAsMarkdown,
  };
});

watch([isArticleModalOpen, viewingArticle], async () => {
  if (isArticleModalOpen.value && viewingArticle.value) {
    openArticle(viewingArticle.value);
  } else if (!isArticleModalOpen.value) {
    closeArticle();
  }
});

async function openArticle(entry: IClientTimelineEntry) {
  if (!entry.IdReference) return;

  fullStaticEntry.value = null;
  pinThread({ entry });

  const [, fetchedEntry] = await handleRequest(fetchEntry(entry.IdReference));

  if (entry) {
    fullStaticEntry.value = fetchedEntry;
  }
}

function closeArticle() {
  unpinThread();
  fullStaticEntry.value = null;
}
</script>

<style lang="scss" scoped>
.ArticleModalView {
  display: grid;
  justify-items: flex-end;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  bottom: 0;
  transition: none 600ms;

  @media (max-width: 599px) {
    top: var(--appLogoBottom);
  }
}

.ArticleModalView__background {
  position: absolute;
  inset: 0;
  z-index: -1;
  background: var(--colorSwissAlphaWhite800);
  backdrop-filter: blur(var(--backgroundBlur));
  transition: opacity var(--mobileThemeTransition);
  cursor: pointer;

  .ArticleModalView.v-enter-from &,
  .ArticleModalView.v-leave-to & {
    opacity: 0;
  }
}

.ArticleModalView__window {
  --mobilePaddingBottom: 0px;

  display: grid;
  position: relative;
  max-width: var(--layoutMaxWidth);
  width: 100%;
  height: 100%;
  outline: 1px solid var(--colorSwissGrey100);
  background-color: var(--colorSwissGrey50);

  .ArticleModalView.v-enter-active &,
  .ArticleModalView.v-leave-active & {
    transition: 600ms cubic-bezier(0.22, 1, 0.36, 1);
    transition-property: opacity, transform;
  }

  .ArticleModalView.v-enter-from &,
  .ArticleModalView.v-leave-to & {
    transform: translateX(110%);

    @media (min-width: 600px) {
      transform: translateX(64px);
      opacity: 0;
    }
  }
}

.ArticleModalView__lineSpinner {
  position: relative;
  top: -2px;
}

.ArticleModalView__scrollContainer {
  --mobilePaddingBottom: 0px;

  position: absolute;
  inset: 0 0 calc(var(--threadInputHeight) + var(--mobilePaddingBottom)) 0;

  @media (display-mode: standalone) {
    .EraRoot[data-js-mobile-keyboard-state="closed"] & {
      --mobilePaddingBottom: calc(8px + var(--viewportPaddingBottom));
    }
  }
}

.ArticleModalView__backButton {
  position: absolute;
  left: 0;
  top: 32px;

  @media (max-width: 599px) {
    &.IconButton {
      border-left: none;
    }
  }

  @media (min-width: 600px) {
    transform: translateX(-100%);
  }
}
</style>
