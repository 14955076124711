import { HubConnectionState } from "@microsoft/signalr";

export enum SignalRClientEventType {
  connectionConnected = "connected:connection",
  connectionStateChange = "change:connectionState"
}
export interface SignalRClientEvent extends CustomEvent {
  type: SignalRClientEventType,
  detail: {
    fromState: HubConnectionState,
    toState: HubConnectionState,
  };
}
