import useApiClient from "@cosine/composables/useApiClient";
import { ListName } from "@cosine/stores/useListStore.types";
import { IApiResponse } from "@cosine/types/api-models";
import { AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { ref } from "vue";

type ListNameString = keyof typeof ListName;

export default defineStore("ListStore", () => {
  const { apiClient } = useApiClient();
  const lists = ref<Array<ListNameString>>([]);

  async function fetchLists() {
    return updateListsFromResponse(
      apiClient.value.get<IApiResponse<Array<ListNameString>>>("/list-subscriptions"),
    );
  }

  async function subscribeToList(listName: ListNameString) {
    return updateListsFromResponse(
      apiClient.value.post<IApiResponse<Array<ListNameString>>>("/list-subscriptions", {
        ListName: listName,
      }),
    );
  }

  async function updateListsFromResponse(promise: Promise<AxiosResponse<IApiResponse<Array<ListNameString>>>>) {
    const { data: { Result: _lists } } = await promise;

    if (_lists) {
      lists.value = _lists;
    }
  }

  return {
    lists,

    fetchLists,
    subscribeToList,
  };
});
