<template>
  <li
    class="TransferListItem"
    :class="{
      [`TransferListItem--status${modelValue.State}`]: true,
    }"
  >
    <Icon
      name="ArrowTransfer"
    />

    <div class="TransferListItem__header">
      <UIText
        class="TransferListItem__amount"
        tag="h3"
        size="Medium"
        weight="Medium"
      >
        {{ formattedAmount }}
      </UIText>
      <UIText
        class="TransferListItem__status"
        tag="strong"
        size="Small"
        weight="Medium"
      >
        {{ formattedState }}
      </UIText>
    </div>

    <div class="TransferListItem__footer">
      <UIText
        class="TransferListItem__account--source"
        size="2XSmall"
        weight="Medium"
      >
        <AccountLabel
          :modelValue="sourceAccount"
          prefix="From:"
        />
      </UIText>
      <UIText
        class="TransferListItem__account--destination"
        size="2XSmall"
        weight="Medium"
      >
        <AccountLabel
          :modelValue="destinationAccount"
          prefix="To:"
        />
      </UIText>
      <UIText
        class="TransferListItem__timestamp"
        size="2XSmall"
        weight="Medium"
      >
        <template v-if="modelValue.State === FundsTransferState.Pending">
          Expected by <Timestamp
            :datetime="estimatedDate"
            format="AbsoluteShortDate"
          />
        </template>
        <Timestamp
          v-else
          :datetime="modelValue.RequestedAt"
          format="AbsoluteShort"
        />
      </UIText>
    </div>
  </li>
</template>

<script lang="ts" setup>
import AccountLabel from "@cosine/components/AccountLabel.vue";
import Icon from "@cosine/components/Icon.vue";
import Timestamp from "@cosine/components/Timestamp.vue";
import UIText from "@cosine/components/UIText.vue";
import formatAmount from "@cosine/lib/utils/financial/formatAmount";
import { FundsTransferState, IEnrichedAccountModel, IFundsTransfer } from "@cosine/types/api-models";
import { addBusinessDays, parseISO } from "date-fns";
import { computed, toRefs } from "vue";

const props = defineProps<{
  modelValue: IFundsTransfer,
  accountMap: Map<string, IEnrichedAccountModel>,
}>();
const {
  modelValue,
  accountMap,
} = toRefs(props);

const formattedAmount = computed(() => formatAmount(modelValue.value.Amount));
const sourceAccount = computed(() => accountMap.value.get(modelValue.value.SourceEraAccountId) || null);
const destinationAccount = computed(() => accountMap.value.get(modelValue.value.DestinationEraAccountId) || null);
const formattedState = computed(() => {
  if (modelValue.value.State === FundsTransferState.Pending) {
    return "In progress";
  }

  return modelValue.value.State;
});

const estimatedDate = computed(() => {
  return addBusinessDays(parseISO(modelValue.value.RequestedAt), 3);
});
</script>

<style lang="scss" scoped>
.TransferListItem {
  --dividerColor: var(--colorSwissGrey100);
  --statusPositiveColor: var(--colorSwissTeal300);
  --statusNegativeColor: var(--colorSwissRed300);

  display: grid;
  grid: auto / min-content auto;
  gap: 2px 16px;
  align-items: center;
  padding: 16px 16px 20px;
  white-space: nowrap;
  cursor: pointer;

  & + & {
    border-top: solid 1px var(--dividerColor);
  }

  .Theme--isColored & {
    --dividerColor: var(--colorSwissAlphaBlack200);
    --statusPositiveColor: var(--colorSwissBlack);
    --statusNegativeColor: var(--colorWhite);
  }
}

.TransferListItem__header {
  display: grid;
  grid: auto / auto min-content;
  gap: 16px;
}

.TransferListItem__status {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 6px;

  &::after {
    display: block;
    margin-top: 2px;
    width: 10px;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: var(--colorSwissAlphaBlack300);
    content: "";

    .TransferListItem--statusCompleted & {
      background-color: var(--statusPositiveColor);
    }

    .TransferListItem--statusCanceled &,
    .TransferListItem--statusFailed &,
    .TransferListItem--statusReversed &,
    .TransferListItem--statusLimitExceeded &,
    .TransferListItem--statusPausedForRisk &,
    .TransferListItem--statusRejectedForRisk &,
    .TransferListItem--statusFailedToStart & {
      background-color: var(--statusNegativeColor);
    }
  }
}

.TransferListItem__footer {
  grid-column-start: 2;
  display: grid;
  grid: auto / auto min-content;
  gap: 4px 16px;
  color: var(--colorSwissAlphaBlack600);
}

.TransferListItem__account--destination {
  grid-column-start: 1;
}

.TransferListItem__timestamp {
  grid-area: 1 / 2;
}
</style>
