<template>
  <TabNav
    class="TabNavView"
    :class="{
      'TabNavView--isSubNav': isSubNav,
    }"
    v-bind="{
      items,
      selectedItem,
      isExact,
    }"
    @update:selectedItem="handleItemSelected"
  />
</template>

<script lang="ts" setup>
import { TabNavItem } from "@cosine/components/TabNav.types";
import TabNav from "@cosine/components/TabNav.vue";
import { ref, toRefs, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const route = useRoute();

const props = withDefaults(defineProps<{
  items: Array<TabNavItem>,
  isSubNav?: boolean,
  isExact?: boolean,
}>(), {
  isSubNav: false,
  isExact: false,
});

const {items} = toRefs(props);
const selectedItem = ref<TabNavItem | null>(findItemByRoute());

function findItemByRoute(): TabNavItem | null {
  const matchedRoutes = route.matched || [];

  return items.value.find((item) => {
    return !!matchedRoutes.find((_route) => item.to.name === _route.name);
  }) || null;
}

watch(route, () => {
  selectedItem.value = findItemByRoute();
});

function handleItemSelected(item: TabNavItem | null) {
  if (item === selectedItem.value) { return; }

  selectedItem.value = item;
  if (selectedItem.value) {
    router.push(selectedItem.value.to);
  }
}
</script>

<style lang="scss" scoped>
.TabNavView--isSubNav {
  position: sticky;
  top: var(--layoutStickyTop);
  z-index: 1;
  background-color: var(--colorSwissGrey50);

  // TODO: consider moving border to app layout slot
  &::after {
    position: absolute;
    inset: 0;
    border-block: 1px solid var(--colorSwissGrey100);
    pointer-events: none;
    content: "";
  }
}
</style>
