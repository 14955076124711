import { RoutePath, UngatedRoutePaths, routes } from "@cosine/routes";
import useAuthStore from "@cosine/stores/useAuthStore";
import { Roles } from "@cosine/types/api-models";
import useRuntimeEnvironment from "@shared/composables/config/useRuntimeEnvironment";
import { storeToRefs } from "pinia";
import { createRouter as createVueRouter, createWebHistory } from "vue-router";

export function createRouter() {
  const router = createVueRouter({ history: createWebHistory(), routes });

  router.beforeEach((to) => {
    const { session } = useAuthStore();

    if (!session && !UngatedRoutePaths.includes(to.path as RoutePath)) {
      return RoutePath.login;
    }
  });

  router.beforeResolve(async (to) => {
    if (to.meta.isEmployeeRequired) {
      const authStore = useAuthStore();
      const fetchCurrentUser = authStore.fetchCurrentUser;
      const { currentUser, isFetchingUser, fetchingUserPromise } = storeToRefs(authStore);

      if (!currentUser.value) {
        await (isFetchingUser.value ? fetchingUserPromise.value : fetchCurrentUser());
      }

      const isEmployee = currentUser.value?.Roles.includes(Roles.Employee) || (to.meta.isAllowedInDevelopment && useRuntimeEnvironment().isDevelopment);
      if (!isEmployee) {
        return false;
      }
    }
  });

  return router;
}
