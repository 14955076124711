import { SpinnerAnimation } from "./SpinnerAnimation";

export class SpinnerLoadingAnimation extends SpinnerAnimation {
  spinDuration = 650;
  segmentDelay = 50;

  animate() {
    const animations = this.segments.flatMap((segment, n) => {
      return segment.spin({
        duration: this.spinDuration,
        delay: n * this.segmentDelay,
        iterations: Infinity,
        direction: "alternate",
      });
    });

    return () => {
      animations.forEach((animation) => animation.cancel());
    };
  }
}
