<template>
  <FormInput
    :value="modelValue"
    @input="handleInput"
    v-bind="{
      disabled,
      required,
      placeholder,
      name,
      readonly,
      type,
    }"
  />
</template>

<script lang="ts" setup>
import FormInput from "@cosine/components/FormInput.vue";

withDefaults(defineProps<{
  disabled?: boolean,
  modelValue?: string,
  name?: string,
  placeholder?: string,
  required?: boolean,
  readonly?: boolean,
  type?: "text" | "password" | "email" | "number",
}>(), {
  required: false,
  type: "text",
});

const emit = defineEmits<{
  "update:modelValue": [string],
}>();

function handleInput(event: Event) {
  const target = event.target as HTMLInputElement;

  emit("update:modelValue", target.value);
}
</script>
