<template>
  <section
    v-if="snaptradeUser"
    class="InvestingListView"
  >
    Investing List View
  </section>
</template>

<script lang="ts" setup>
import useInvestingStore from "@cosine/stores/useInvestingStore";
import { storeToRefs } from "pinia";

const { snaptradeUser } = storeToRefs(useInvestingStore());
</script>

<style lang="scss" scoped>
.InvestingListView {
  width: 100%;
}
</style>
