<template>
  <section
    v-if="routine"
    class="RoutineOverviewView"
  >
    <SheetButtonList
      v-if="routine.Source === RoutineSource.User"
    >
      <SheetButton
        iconName="ArrowRefresh"
        label="Run now"
        :disabled="isRequesting || routine.IsDisabled && !canActivateMoreRoutines"
        @click="handleRunClick"
      />

      <SheetButton
        v-if="routine.IsDisabled"
        iconName="Play"
        label="Resume"
        color="Green"
        :disabled="isRequesting || !canActivateMoreRoutines"
        @click="handleToggleClick"
      />
      <SheetButton
        v-else
        iconName="Pause"
        label="Pause"
        color="Yellow"
        :disabled="isRequesting"
        @click="handleToggleClick"
      />

      <SheetButton
        iconName="Trash"
        label="Delete"
        :disabled="isRequesting"
        @click="handleDeleteClick"
      />
    </SheetButtonList>

    <section
      v-if="routine.Source === RoutineSource.User"
      class="RoutineOverviewView__footer"
    >
      <RoutineStatus
        :routine="routine"
      />
    </section>
  </section>
</template>

<script lang="ts" setup>
import RoutineStatus from "@cosine/components/RoutineStatus.vue";
import SheetButton from "@cosine/components/SheetButton.vue";
import SheetButtonList from "@cosine/components/SheetButtonList.vue";
import useRequest from "@cosine/composables/useRequest";
import { RouteName } from "@cosine/routes";
import useRoutineStore from "@cosine/stores/useRoutineStore";
import { RoutineSource } from "@cosine/types/api-models";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const route = useRoute();

const routineStore = useRoutineStore();
const {
  userRoutines,
  canActivateMoreRoutines,
} = storeToRefs(routineStore);
const {
  runRoutine,
  toggleRoutine,
  deleteRoutine,
} = routineStore;
const {
  handleRequest,
  isRequesting,
} = useRequest();

// TODO: fetch routine if not in store
const routine = computed(() => {
  return userRoutines.value.find((routine) => routine.RoutineId === route.params.id);
});

function handleRunClick() {
  if (!routine.value) return;

  handleRequest(runRoutine(routine.value), {
    showAlerts: true,
  });
}

function handleToggleClick() {
  if (!routine.value) return;

  handleRequest(
    toggleRoutine({
      RoutineId: routine.value.RoutineId,
      Disable: !routine.value.IsDisabled,
    }),
    {
      showAlerts: true,
    },
  );
}

async function handleDeleteClick() {
  if (!routine.value) return;

  const [err] = await handleRequest(
    deleteRoutine(routine.value),
    {
      showAlerts: true,
    },
  );

  if (!err) {
    router.push({name: RouteName.routines});
  }
}
</script>

<style lang="scss" scoped>
.RoutineOverviewView__footer {
  display: grid;
  place-items: center;
  padding: 24px 16px;
}
</style>
