<template>
  <section class="ImportMintTransactionsView">
    <SectionHeader
      v-if="uploadedFiles.length > 0"
      title="Imports"
      :subtitle="`${uploadedFiles.length} imported`"
    />

    <MintFileCard
      v-for="file in uploadedFiles"
      :key="file.Id"
      :modelValue="file"
      :isDeleteDisabled="filesBeingDeleted.includes(file)"
      @delete="handleFileDelete"
    />
    <FeatureCard
      title="Import from Mint"
      body="Easily import your information from Mint for Era to analyze your transactions history and provide a personalized budget based on your data."
      color="MintGreen"
    >
      <section class="ImportMintTransactionsView__importContainer">
        <AlertBanner
          v-if="requestError"
          class="ImportMintTransactionsView__alertBanner"
          iconName="Exclamation"
          title="Error"
          :body="formattedError"
          status="Negative"
        />
        <div v-if="hasFilesForUpload">
          <UIText
            class="ImportMintTransactionsView__selectedFileText"
            size="3XSmall"
            weight="Medium"
            casing="Upper"
          >
            {{ filesSelectedForUpload.length > 1 ? `Selected files (${filesSelectedForUpload.length})` : 'Selected file' }}
          </UIText>

          <ul>
            <li
              class="ImportMintTransactionsView__selectedFile"
              v-for="(file, index) in filesSelectedForUpload"
              :key="`${index}-${file.name}`"
            >
              <UIText
                size="Small"
                weight="Medium"
              >
                {{ file.name }}
              </UIText>

              <button
                class="ImportMintTransactionsView__selectedFileClear"
                @click="removeFile(index)"
              >
                <Icon name="Trash" class="ImportMintTransactionsView__selectedFileClearIcon" />
              </button>
            </li>
          </ul>
        </div>

        <div class="ImportMintTransactionsView__buttonList">
          <label
            class="ImportMintTransactionsView__button"
          >
            <input
              class="ImportMintTransactionsView__hiddenInput"
              type="file"
              accept=".csv"
              @change="handleFileChange"
              ref="fileInput"
            >

            <UIText
              class="ImportMintTransactionsView__buttonLabel"
              size="Medium"
              weight="Medium"
            >
              <template v-if="filesSelectedForUpload.length">
                Add another file
              </template>
              <template v-else>
                <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.99992 0.5C7.09056 1.3664 0.854309 7.46385 0.854309 12.2135C0.854309 15.5949 3.69223 16.7161 7.99992 19.5C12.3078 16.7161 15.1457 15.5949 15.1457 12.2135C15.1457 7.46385 8.90937 1.3664 7.99992 0.5ZM7.84368 17.1246C7.61378 16.9816 7.38536 16.8398 7.16237 16.7019C3.72216 14.5736 2.84902 13.8548 2.84902 12.2135C2.84902 9.59071 5.52743 5.9682 7.84368 3.4439V17.1246Z" fill="#00A38F"/>
                </svg> Import Mint transactions
              </template>
            </UIText>
          </label>

          <button
            v-if="hasFilesForUpload"
            class="ImportMintTransactionsView__uploadButton ImportMintTransactionsView__button ImportMintTransactionsView__button--isPrimary"
            :disabled="isRequesting"
            @click="uploadFiles"
          >
            <UIText
              size="Medium"
              weight="Medium"
            >
              Upload
            </UIText>
          </button>
        </div>
      </section>
    </FeatureCard>

    <Spinner
      :isVisible="isRequesting"
      isCentered
    />
  </section>
</template>

<script lang="ts" setup>
import AlertBanner from "@cosine/components/AlertBanner.vue";
import FeatureCard from "@cosine/components/FeatureCard.vue";
import Icon from "@cosine/components/Icon.vue";
import MintFileCard from "@cosine/components/MintFileCard.vue";
import SectionHeader from "@cosine/components/SectionHeader.vue";
import Spinner from "@cosine/components/Spinner.vue";
import UIText from "@cosine/components/UIText.vue";
import useRequest from "@cosine/composables/useRequest";
import useMintStore from "@cosine/stores/useMintStore";
import { ErrorType, IFileDetails } from "@cosine/types/api-models";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref } from "vue";

// TODO: replace HTML buttons with FormButton

const {
  handleRequest,
  isRequesting,
  requestError,
} = useRequest();
const mintStore = useMintStore();
const { fetchUploadedFiles, deleteFile, uploadSelectedFiles, removeFileFromSelectedFilesAtIndex } = mintStore;
const { uploadedFiles, filesBeingDeleted, filesSelectedForUpload } = storeToRefs(mintStore);

const fileInput = ref<HTMLInputElement>();
const formattedError = computed(() => {
  if (requestError.value?.response?.data.ErrorType === ErrorType.InvalidCsvFile) {
    return "Invalid Mint CSV file. Please upload a valid CSV file.";
  }

  return "Something went wrong. Please try again.";
});

function handleFileChange(event: Event) {
  const target = event.target as HTMLInputElement;
  const file = target.files?.[0];

  if (!file) return;

  filesSelectedForUpload.value.push(file);

  if (fileInput.value) {
    fileInput.value.value = "";
  }
}

function removeFile(index: number) {
  removeFileFromSelectedFilesAtIndex(index);
}

async function handleFileDelete(file: IFileDetails) {
  const [err] = await handleRequest(deleteFile(file));

  if (!err) {
    await handleRequest(fetchUploadedFiles());
  }
}

async function uploadFiles() {
  const [err] = await handleRequest(uploadSelectedFiles());

  if (!err) {
    await handleRequest(fetchUploadedFiles());
  }
}

onMounted(async () => {
  await handleRequest(fetchUploadedFiles());
});

const hasFilesForUpload = computed(() => filesSelectedForUpload.value.length > 0);
</script>

<style lang="scss" scoped>
.ImportMintTransactionsView {
  position: relative;
}

.ImportMintTransactionsView__importContainer {
  display: grid;
  gap: 8px;
}

.ImportMintTransactionsView__alertBanner {
  margin: 4px -16px;
}

.ImportMintTransactionsView__selectedFile {
  display: grid;
  grid: auto / 1fr auto;
  align-items: center;
  gap: 0 8px;
  margin-bottom: 8px;
}

.ImportMintTransactionsView__selectedFileClear {
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  aspect-ratio: 1;
}

.ImportMintTransactionsView__selectedFileClearIcon.Icon {
  --iconColor: var(--colorSwissAlphaBlack600);

  width: 20px;
  height: 20px;
}

.ImportMintTransactionsView__selectedFileText {
  display: inline-block;
  color: var(--colorSwissAlphaBlack600);
}

.ImportMintTransactionsView__button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 58px;
  border: 1px solid var(--colorSwissGrey100);
  background: var(--colorWhite);
  cursor: pointer;

  &.ImportMintTransactionsView__button--isPrimary {
    border: none;
    background: var(--colorSwissBlack);
    color: var(--colorWhite);
  }
}

.ImportMintTransactionsView__buttonLabel {
  display: flex;
  align-items: stretch;
  gap: 8px;
}

.ImportMintTransactionsView__hiddenInput {
  display: none;
}

.ImportMintTransactionsView__buttonList {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 8px;
}
</style>
