import { ISnapTradeUser } from "@cosine/types/api-models";
import { defineStore } from "pinia";
import { ref } from "vue";

export default defineStore("InvestingStore", () => {
  const snaptradeUser = ref<ISnapTradeUser>();

  return {
    snaptradeUser,
  };
});
