<template>
  <TimelineEntry
    v-bind="{
      modelValue,
      isPinned,
    }"
    @click="handleEntryClick"
  >
    <template #buttonList>
      <TimelineBookmarkButtonView
        :entry="modelValue"
      />
    </template>
  </TimelineEntry>
</template>

<script lang="ts" setup>
import TimelineEntry from "@cosine/components/TimelineEntry.vue";
import useThreadStore from "@cosine/stores/useThreadStore";
import useTimelineStore from "@cosine/stores/useTimelineStore";
import { IClientTimelineEntry, TimelineEntrySources } from "@cosine/types/api-models";
import TimelineBookmarkButtonView from "@cosine/views/TimelineBookmarkButtonView.vue";
import { toRefs } from "vue";

const props = withDefaults(defineProps<{
  modelValue: IClientTimelineEntry,
  isPinned?: boolean,
}>(), {
  isPinned: false,
});
const {modelValue} = toRefs(props);

const {
  startViewingArticle,
} = useTimelineStore();
const { openThread } = useThreadStore();

function handleEntryClick() {
  if (!modelValue.value.IdReference) { return; }

  if (modelValue.value.Source === TimelineEntrySources.System_PeriodicBrief) {
    startViewingArticle(modelValue.value);
  } else {
    openThread({ entry: modelValue.value });
  }
}
</script>
