<template>
  <section
    class="TransactionsListView"
  >
    <AlertBanner
      v-if="isSyncing"
      status="Positive"
      title="Sync in progress"
      body="Your transactions are currently being updated"
      iconName="Sync"
    />
    <InfiniteScroll
      v-if="transactions.length > 0"
      class="TransactionsListView__list"
      scrollViewport="Mobile"
      v-bind="{pagination}"
      @scrollEnd="handleScrollEnd"
    >
      <GroupedList>
        <LabeledList
          v-for="list in lists"
          :key="list.title"
          :title="list.title"
        >
          <FinancialTransactionItem
            v-for="transaction in list.items"
            :key="transaction.EraTransactionId"
            :modelValue="transaction"
            v-bind="{ accountMap }"
            @click="handleTransactionClick(transaction)"
          />
        </LabeledList>
      </GroupedList>
    </InfiniteScroll>
    <GetStartedView v-else-if="!isRequesting && accountMap.size === 0" />

    <Teleport to="body">
      <TransactionSheetModalView />
    </Teleport>
  </section>
</template>

<script lang="ts" setup>
import AlertBanner from "@cosine/components/AlertBanner.vue";
import FinancialTransactionItem from "@cosine/components/FinancialTransactionItem.vue";
import GroupedList from "@cosine/components/GroupedList.vue";
import InfiniteScroll from "@cosine/components/InfiniteScroll.vue";
import LabeledList from "@cosine/components/LabeledList.vue";
import useAppSpinner from "@cosine/composables/useAppSpinner";
import useRequest from "@cosine/composables/useRequest";
import groupByDate from "@cosine/lib/utils/collection/groupByDate";
import getDateForTransaction from "@cosine/lib/utils/financial/getDateForTransaction";
import useBackgroundJobStore from "@cosine/stores/useBackgroundJobStore";
import useFinancialStore from "@cosine/stores/useFinancialStore";
import useTransactionStore from "@cosine/stores/useTransactionStore";
import { IEnrichedTransactionModel } from "@cosine/types/api-models";
import GetStartedView from "@cosine/views/GetStartedView.vue";
import TransactionSheetModalView from "@cosine/views/TransactionSheetModalView.vue";
import { storeToRefs } from "pinia";
import { computed, onMounted, watch } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();

const {isSyncing} = storeToRefs(useBackgroundJobStore());
const financialStore = useFinancialStore();
const {fetchAccounts} = financialStore;
const {accountMap} = storeToRefs(financialStore);
const transactionStore = useTransactionStore();
const {
  setFilter,
  fetchTransactions,
  startEditingTransaction,
} = transactionStore;
const {
  transactions,
  filter,
  pagination,
} = storeToRefs(transactionStore);

const {
  isRequesting,
  handleRequest,
} = useRequest();
useAppSpinner(isRequesting);

watch(filter, () => {
  if (isRequesting.value) return;

  handleRequest(fetchTransactions());
});

watch(isSyncing, () => {
  if (!isSyncing.value) {
    handleRequest(fetchTransactions());
  }
});

onMounted(async () => {
  // TODO: consider moving this to top level view since it applies to the filter view as well
  await setFilter({
    accountIds: route.query.account && !Array.isArray(route.query.account)
      ? [route.query.account]
      : undefined,
    budgetInstanceId: route.query.budget && !Array.isArray(route.query.budget)
      ? route.query.budget
      : undefined,
  });

  if (accountMap.value.size === 0) {
    fetchAccounts();
  }

  handleRequest(fetchTransactions());
});

const lists = computed(() => {
  return groupByDate(transactions.value, (transaction) => getDateForTransaction(transaction));
});

async function handleScrollEnd() {
  if (isRequesting.value) return;

  await handleRequest(fetchTransactions({ pageIndex: pagination.value.pageIndex + 1 }));
}

function handleTransactionClick(transaction: IEnrichedTransactionModel) {
  startEditingTransaction(transaction);
}
</script>

<style lang="scss" scoped>
.TransactionsListView {
  position: relative;
  display: grid;
  align-content: flex-start;
}

.TransactionsListView__list {
  @media (max-width: 599px) {
    position: absolute;
    inset: 0;
  }
}

.TransactionsListView__connectAccountsCard {
  align-self: flex-start;
}
</style>
