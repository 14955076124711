<template>
  <PromptPane
    iconName="ArrowUpRightHexagon"
    iconColor="Teal"
    backgroundColor="Teal"
    title="Make money moves"
    body="Coming soon: Fee-free transfers between any of your financial accounts, one-time or on a recurring basis using automated Routines."
  >
    <template #button>
      <FormButton
        :disabled="isRequesting || lists.includes('Transfers')"
        @click="handleButtonClick"
      >
        <span v-if="!lists.includes('Transfers')">
          Join the waitlist
        </span>
        <span v-else>
          You’re on the waitlist!
        </span>
      </FormButton>
    </template>
  </PromptPane>
</template>

<script lang="ts" setup>
import FormButton from "@cosine/components/FormButton.vue";
import PromptPane from "@cosine/components/PromptPane.vue";
import useAppSpinner from "@cosine/composables/useAppSpinner";
import useRequest from "@cosine/composables/useRequest";
import useListStore from "@cosine/stores/useListStore";
import { storeToRefs } from "pinia";
import { onMounted } from "vue";

const listStore = useListStore();
const { lists } = storeToRefs(listStore);
const {
  fetchLists,
  subscribeToList,
} = listStore;

const {
  handleRequest,
  isRequesting,
} = useRequest();
useAppSpinner(isRequesting);

onMounted(() => {
  handleRequest(fetchLists());
});

function handleButtonClick() {
  handleRequest(subscribeToList("Transfers"));
}
</script>
