<template>
  <SheetScreen
    class="AccountSelectView"
    :zIndex="1"
    v-bind="{
      title,
    }"
    @click:back="$emit('click:back')"
  >
    <FinancialAccountList
      title="Select an account…"
      :accounts="enabledTransferAccounts"
      @click:account="$emit('update:modelValue', $event.EraAccountId)"
    />
    <FinancialAccountList
      v-if="disabledTransferAccounts.length > 0"
      title="Not yet enabled"
      subtitle="You can enable any of these accounts for transfers"
      :accounts="disabledTransferAccounts"
      includeEnableButton
      @click:enable="$router.push({name: RouteName.accounts, query: { connect: '', mode: MxWidgetMode.Verification }})"
    />
    <UIText
      v-if="unsupportedAccountsForTransfer.length > 0"
      class="AccountSelectView__unsupportedAccountsLabel"
      tag="p"
      size="2XSmall"
      weight="Medium"
      isMultiline
    >
      <Icon
        class="AccountSelectView__unsupportedAccountsIcon"
        name="Info"
      />
      {{unsupportedAccountsForTransfer.length}} of your accounts are hidden because they don’t support money transfers yet
    </UIText>
  </SheetScreen>
</template>

<script lang="ts" setup>
import FinancialAccountList from "@cosine/components/FinancialAccountList.vue";
import Icon from "@cosine/components/Icon.vue";
import SheetScreen from "@cosine/components/SheetScreen.vue";
import UIText from "@cosine/components/UIText.vue";
import { RouteName } from "@cosine/routes";
import useTransferStore from "@cosine/stores/useTransferStore";
import { MxWidgetMode } from "@cosine/types/api-models";
import { storeToRefs } from "pinia";

const {
  enabledTransferAccounts,
  disabledTransferAccounts,
  unsupportedAccountsForTransfer,
} = storeToRefs(useTransferStore());

defineEmits<{
  "update:modelValue": [accountId: string],
  "click:back": [],
}>();

defineProps<{
  title: string,
  modelValue: string | null,
}>();
</script>

<style lang="scss" scoped>
.AccountSelectView__unsupportedAccountsLabel {
  display: flex;
  gap: 6px;
  border-top: 1px solid var(--colorSwissGrey100);
  padding: 16px var(--layoutMargin);
  color: var(--colorSwissAlphaBlack700);
}

.AccountSelectView__unsupportedAccountsIcon {
  --iconColor: currentColor;

  position: relative;
  top: 2px;
  width: 14px;
  height: 14px;
}
</style>
