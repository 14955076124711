import {
  IRoutineModel,
  MessageChannel,
  RoutineSource,
  RoutineStatus,
} from "@cosine/types/api-models";
import { faker } from "@faker-js/faker";
import { addMinutes, subMinutes } from "date-fns";

// TODO: improve
export function createRoutine(
  overrides: Partial<IRoutineModel> = {},
): IRoutineModel {
  return {
    CronExpression: "0 0 8 ? * WED",
    Interval: { CronExpression: "0 0 8 ? * WED" },
    RoutineId: faker.string.uuid(),
    ExecutionCount: 0,
    Type: "UserMessage",
    IsDisabled: false,
    NextFireTime: addMinutes(new Date(), 5).toISOString(),
    LastFireTime: subMinutes(new Date(), 5).toISOString(),
    Name: "My routine",
    Description: "A description of my routine",
    DateCreated: new Date().toISOString(),
    Source: RoutineSource.User,
    Status: RoutineStatus.Idle,
    Args: { IsPinned: false },
    SupportedChannels: [MessageChannel.Timeline],
    ...overrides,
  };
}
