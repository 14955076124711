<template>
  <button
    class="TimelineBookmarkButtonView"
    :class="{
      [`TimelineBookmarkButtonView--variant${variant}`]: !!variant
    }"
    :disabled="isRequesting"
    @click.stop="handleBookmarkClick"
  >
    <Icon
      class="TimelineBookmarkButtonView__icon"
      :name="!isBookmarked ? 'BookmarkAdd' : 'BookmarkRemove'"
    />
  </button>
</template>

<script lang="ts" setup>
import Icon from "@cosine/components/Icon.vue";
import useRequest from "@cosine/composables/useRequest";
import useTimelineStore from "@cosine/stores/useTimelineStore";
import { IClientTimelineEntry, TimelineEntryFlag } from "@cosine/types/api-models";
import { computed, toRefs } from "vue";

const props = defineProps<{
  entry: IClientTimelineEntry,
  variant?: "Black" | "White",
}>();
const {entry} = toRefs(props);

const {
  bookmarkEntry,
} = useTimelineStore();
const {
  isRequesting,
  handleRequest,
} = useRequest();

const isBookmarked = computed(() => entry.value.Flags.includes(TimelineEntryFlag.Pinned));

async function handleBookmarkClick() {
  if (!entry.value.IdReference) return;

  await handleRequest(bookmarkEntry(entry.value.IdReference, !isBookmarked.value),
    {
      showOnlyErrors: true,
    },
  );
}
</script>

<style lang="scss" scoped>
.TimelineBookmarkButtonView {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  aspect-ratio: 1;
}

.TimelineBookmarkButtonView--variantBlack {
  --iconColor: var(--colorWhite);

  background-color: var(--colorSwissBlack);
}

.TimelineBookmarkButtonView--variantWhite {
  --iconColor: var(--colorSwissBlack);

  background-color: var(--colorWhite);
}

.TimelineBookmarkButtonView__icon {
  width: 20px;
  height: 20px;
  transition: opacity var(--defaultMobileTransition);

  .TimelineBookmarkButtonView:disabled & {
    opacity: 0.4;
  }
}
</style>
