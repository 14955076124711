<template>
  <FormInput
    :value="maskedValue"
    @input="handleInput"
    type="tel"
    v-bind="{
      disabled,
      required,
      placeholder,
      name,
    }"
  />
</template>

<script lang="ts" setup>
import FormInput from "@cosine/components/FormInput.vue";
import { Mask } from "maska";
import { computed, toRefs } from "vue";

const props = withDefaults(defineProps<{
  disabled?: boolean,
  modelValue?: string,
  name?: string,
  placeholder?: string,
  required?: boolean,
}>(), {
  required: false,
  placeholder: "+1 (555) 555-5555",
});

const { modelValue } = toRefs(props);

const emit = defineEmits<{
  "update:modelValue": [string],
}>();

// TODO: support more than US phone numbers
const maskedValue = computed(() => {
  return new Mask({ mask: "+1 (###) ###-####" }).masked(modelValue.value ?? "");
});

function unmaskValue(value: string): string {
  return `+1${new Mask({ mask: "+1 (###) ###-####" }).unmasked(value)}`;
}

function handleInput(event: Event) {
  const target = event.target as HTMLInputElement;
  emit("update:modelValue", unmaskValue(target.value));
}
</script>
