<template>
  <label class="CheckboxField">
    <input
      class="CheckboxField__input"
      type="checkbox"
      v-model="localModelValue"
      :disabled="isDisabled"
    >
    <div class="CheckboxField__checkbox">
      <Icon
        class="CheckboxField__icon"
        name="Check"
      />
    </div>

    <div class="CheckboxField__titleContainer">
      <UIText
        class="CheckboxField__title"
        size="Medium"
        weight="Medium"
      >{{ title }}</UIText>

      <UIText
        v-if="subtitle"
        class="CheckboxField__subtitle"
        size="2XSmall"
        weight="Medium"
      >{{ subtitle }}</UIText>
    </div>
  </label>
</template>

<script lang="ts" setup>
import Icon from "@cosine/components/Icon.vue";
import UIText from "@cosine/components/UIText.vue";
import { computed, toRefs } from "vue";

const emit = defineEmits<{
  "update:modelValue": [isChecked: boolean],
}>();

const props = withDefaults(defineProps<{
  title: string,
  modelValue: boolean,
  subtitle?: string,
  isDisabled?: boolean,
}>(), {
  isDisabled: false,
});

const {modelValue} = toRefs(props);

const localModelValue = computed({
  get() {
    return modelValue.value;
  },
  set(value: boolean) {
    emit("update:modelValue", value);
  },
});
</script>

<style lang="scss" scoped>
.CheckboxField {
  --subtitleColor: var(--colorSwissAlphaBlack600);

  display: flex;
  gap: 12px;
  border: 1px solid var(--colorSwissAlphaBlack100);
  padding: 16px;
  color: var(--colorSwissBlack);
  background-color: var(--colorWhite);
  transition: var(--defaultMobileTransition);
  transition-property: border-color, color, background-color;

  &:focus-within {
    outline: none;
    border-color: var(--colorSwissAlphaBlack200);
  }

  &:has(:disabled) {
    --subtitleColor: var(--colorSwissAlphaBlack300);

    background-color: var(--colorSwissGrey50);
    color: var(--colorSwissAlphaBlack300);
  }

  &:not(:has(:disabled)) {
    cursor: pointer;
  }
}

.CheckboxField__input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.CheckboxField__checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 21px;
  border: 1px solid var(--colorSwissBlack);
  transition: var(--defaultMobileTransition);
  transition-property: opacity;

  .CheckboxField__input:disabled + & {
    opacity: 0.2;
  }
}

.CheckboxField__icon {
  width: 16px;
  height: 16px;
  transition: 200ms var(--easeOutBack);
  transition-property: opacity, transform;

  .CheckboxField__input:not(:checked) + .CheckboxField__checkbox & {
    transform: scale(0.5);
    opacity: 0;
  }
}

.CheckboxField__titleContainer {
  display: grid;
  gap: 2px;
  margin-top: 1px;
}

.CheckboxField__subtitle {
  color: var(--subtitleColor);
  transition: var(--defaultMobileTransition);
  transition-property: color;
}
</style>
