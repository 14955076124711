import { onBeforeUnmount, onMounted } from "vue";

export default function useMobileKeyboardViewport() {
  const mobileKeyboardHeight = 300;

  onMounted(() => {
    if (!window.visualViewport) { return; }
    handleResize();

    window.visualViewport.addEventListener("resize", handleResize);
    window.visualViewport.addEventListener("scroll", handleScroll);
  });

  onBeforeUnmount(() => {
    if (!window.visualViewport) { return; }

    window.visualViewport.removeEventListener("resize", handleResize);
    window.visualViewport.removeEventListener("scroll", handleScroll);
  });

  function detectMobileKeyboard() {
    // TODO: also detect mobile since this will result in false positives on desktop
    document.documentElement.setAttribute(
      "data-js-mobile-keyboard-state",
      window.screen.height - (window.visualViewport?.height || window.innerHeight) > mobileKeyboardHeight
        ? "open"
        : "closed",
    );
  }

  function handleResize() {
    document.documentElement.style.setProperty("--visualViewportHeight", `${window.visualViewport?.height}px`);
    detectMobileKeyboard();
  }

  function handleScroll() {
    window.scrollTo(0, 0);
  }
}
