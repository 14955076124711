<template>
  <div
    class="BoxIcon"
  >
    <svg
      class="BoxIcon__background"
    >
      <defs>
        <pattern
          :id="patternId"
          class="BoxIcon__backgroundPattern"
          patternUnits="userSpaceOnUse"
          width="2"
          height="2"
          patternTransform="rotate(115)"
        >
          <path d="M0,1 L2,1" stroke="var(--colorSwissAlphaBlack700)"  />
        </pattern>
      </defs>

      <rect
        width="40"
        height="40"
        :fill="`url(#${patternId})`"
      />
    </svg>

    <div class="BoxIcon__iconContainer">
      <svg v-if="name === 'ArrowUpHexagon'" width="22" height="25" viewBox="0 0 22 25">
        <path d="M11 0L22 6.02381V18.0714L11 24.0952L0 18.0714V6.02381L11 0Z" fill="var(--colorSwissBlack)"/>
        <path d="M4.24414 8.8976L10.9994 4.93427L17.7 8.80286 M11 5V17.5" stroke="var(--boxColor)" />
      </svg>
      <svg v-else-if="name === 'ArrowUpRightHexagon'" width="23" height="25" viewBox="0 0 23 25">
        <path d="M11.3784 0.0810547L22.3784 6.10486V18.1525L11.3784 24.1763L0.378418 18.1525V6.10486L11.3784 0.0810547Z" fill="var(--colorSwissBlack)"/>
        <path d="M4.11035 16.1664L19.4536 8.09096M19.4536 8.09096L12.5895 4.45703M19.4536 8.09096L19.4536 16.1664" stroke="var(--boxColor)"/>
      </svg>
      <svg v-else-if="name === 'ArrowCycleHexagon'" width="22" height="25" viewBox="0 0 22 25">
        <path d="M11 0L22 6.02381V18.0714L11 24.0952L0 18.0714V6.02381L11 0Z" fill="var(--colorSwissBlack)"/>
        <path d="M3.96353 9.39762L10.7188 5.43429L17.4194 9.30288 M18.1052 14.3029L11.3499 18.2662L4.64935 14.3976 M17.1062 6.34158L17.287 9.3703L14.9351 10.7281 M4.70667 17.5066L4.69652 14.3794L7.38955 12.8246" stroke="var(--boxColor)"/>
      </svg>
      <svg v-else-if="name === 'Hexagon'" class="BoxIcon__icon" width="22" height="25" viewBox="0 0 22 25">
        <path d="M11 0L22 6.02381V18.0714L11 24.0952L0 18.0714V6.02381L11 0Z" fill="var(--colorSwissBlack)"/>
      </svg>
      <svg v-else-if="name === 'HexagonChart'" width="28" height="32" viewBox="0 0 28 32" fill="none">
        <g clip-path="url(#clip0_419_1756)">
          <path d="M28 8V24L14 32L0 24V8L14 0L28 8Z" fill="var(--colorSwissBlack)"/>
          <path d="M20.3 18.9317V28.4017L19.3 28.9717V19.5017L20.3 18.9317Z" fill="var(--boxColor)"/>
          <path d="M22.5 15.6717V27.1417L21.5 27.7117V16.2917L22.5 15.6717Z" fill="var(--boxColor)"/>
          <path d="M24.64 12.5617V25.9217L23.64 26.4917V13.1517L24.64 12.5617Z" fill="var(--boxColor)"/>
          <path d="M26.82 9.26172V24.6717L25.82 25.2517V9.83172L26.82 9.26172Z" fill="var(--boxColor)"/>
          <path d="M18.13 22.2717V29.6417L17.13 30.2117V22.8417L18.13 22.2717Z" fill="var(--boxColor)"/>
          <path d="M16 25.4517V30.8617L15 31.4317V26.0017L16 25.4517Z" fill="var(--boxColor)"/>
        </g>
        <defs>
          <clipPath id="clip0_419_1756">
            <rect width="28" height="32" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { BoxIconColor, BoxIconName } from "@cosine/components/BoxIcon.types";
import { computed, toRefs } from "vue";

const props = defineProps<{
  name: keyof typeof BoxIconName,
  color: keyof typeof BoxIconColor,
}>();

const {
  name,
  color,
} = toRefs(props);

const patternId = computed(() => `boxIconPattern--${name.value}${color.value}`);
const formattedColor = computed((): string => {
  switch(color.value) {
    case "Teal":
      return "var(--colorSwissTeal300)";
    case "Yellow":
      return "var(--colorSwissYellow300)";
    case "Grey":
      return "var(--colorSwissGrey100)";
    case "Green":
      return "var(--colorSwissGreen300)";
    default:
      return "var(--colorWhite)";
  }
});
</script>

<style lang="scss" scoped>
.BoxIcon {
  --boxColor: v-bind(formattedColor);

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  aspect-ratio: 1;
  border: 1px solid var(--colorSwissBlack);
  background-color: var(--boxColor);

  .Theme--isColored & {
    --boxColor: var(--appBackgroundColor);

    background-color: transparent;
  }
}

.BoxIcon__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.BoxIcon__iconContainer {
  position: relative;

  :deep(svg) {
    display: block;
  }
}
</style>
