<template>
  <section
    class="GroupedList"
  >
    <slot />
  </section>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
.GroupedList {
  display: grid;
  gap: 8px;
}
</style>
