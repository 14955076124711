<template>
  <section class="TransferListView">
    <InfiniteScroll
      class="TransferListView__scrollContainer"
      scrollViewport="Mobile"
      v-bind="{pagination}"
    >
      <GroupedList>
        <LabeledList
          v-for="list in transferLists"
          :key="list.title"
          :title="list.title"
        >
          <TransferListItem
            v-for="item in list.items"
            :key="item.EraFundsTransferId"
            :modelValue="item"
            v-bind="{accountMap}"
            @click="handleTransferItemClick(item)"
          />
        </LabeledList>
      </GroupedList>

      <TransferOnboardingView
        v-if="
          !isRequesting &&
            (!hasEnoughAccountsToTransfer || transfers.length < 5)
        "
      />
    </InfiniteScroll>
    <section
      v-if="hasEnoughAccountsToTransfer"
      class="TransferListView__buttonContainer"
    >
      <FormButton
        class="TransferListView__transferButton"
        variant="Primary"
        @click="$router.push({name: RouteName.transfersNew})"
      >Transfer money</FormButton>
    </section>

    <Teleport to="body">
      <InitiateTransferSheetModalView />
      <TransferSheetModalView />
    </Teleport>
  </section>
</template>

<script lang="ts" setup>
import FormButton from "@cosine/components/FormButton.vue";
import GroupedList from "@cosine/components/GroupedList.vue";
import InfiniteScroll from "@cosine/components/InfiniteScroll.vue";
import LabeledList from "@cosine/components/LabeledList.vue";
import TransferListItem from "@cosine/components/TransferListItem.vue";
import useAppSpinner from "@cosine/composables/useAppSpinner";
import useRequest from "@cosine/composables/useRequest";
import groupByDate from "@cosine/lib/utils/collection/groupByDate";
import { RouteName } from "@cosine/routes";
import useCapabilityStore from "@cosine/stores/useCapabilityStore";
import useFinancialStore from "@cosine/stores/useFinancialStore";
import useTransferStore from "@cosine/stores/useTransferStore";
import { FundsTransferState, IFundsTransfer } from "@cosine/types/api-models";
import InitiateTransferSheetModalView from "@cosine/views/InitiateTransferSheetModalView.vue";
import TransferOnboardingView from "@cosine/views/TransferOnboardingView.vue";
import TransferSheetModalView from "@cosine/views/TransferSheetModalView.vue";
import { storeToRefs } from "pinia";
import { computed, onMounted } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const transferStore = useTransferStore();
const {
  transfers,
  pagination,
  hasEnoughAccountsToTransfer,
} = storeToRefs(transferStore);
const { fetchTransfers } = transferStore;
const financialStore = useFinancialStore();
const { accountMap } = storeToRefs(financialStore);
const { fetchAccounts } = financialStore;
const {
  handleRequest,
  isRequesting,
} = useRequest();
const {fetchCapabilities} = useCapabilityStore();

useAppSpinner(isRequesting);

const transferLists = computed(() => {
  return groupByDate(transfers.value, (transfer) => new Date(transfer.RequestedAt));
});

onMounted(() => {
  handleRequest(Promise.all([
    accountMap.value.size === 0 ? fetchAccounts() : null,
    fetchCapabilities(),
    fetchTransfers(),
  ].filter(Boolean)));
});

function handleTransferItemClick(transfer: IFundsTransfer) {
  const routeName = transfer.State === FundsTransferState.Requested
    ? RouteName.transfersReview
    : RouteName.transfersView;

  router.push({name: routeName, params: {id: transfer.EraFundsTransferId}});
}
</script>

<style lang="scss" scoped>
.TransferListView {
  display: grid;
  grid: auto min-content / auto;
  height: 100%;
}

.TransferListView__buttonContainer {
  padding: 16px;
  background-color: var(--colorSwissGrey50);

  @media (min-width: 600px) {
    position: sticky;
    top: var(--layoutStickyTop);
    order: -1;
  }
}

.TransferListView__scrollContainer,
.TransferListView__buttonContainer {
  // TODO: extract
  @media (display-mode: standalone) and (max-width: 599px) {
    padding-bottom: calc(24px + var(--viewportPaddingBottom));
  }
}
</style>
