<template>
  <TabNavView
    v-bind="{ items }"
  />
</template>

<script lang="ts" setup>
import { TabNavItem } from "@cosine/components/TabNav.types";
import useFlag from "@cosine/composables/useFlag";
import { FlagKey } from "@cosine/composables/useFlag.types";
import { RouteName } from "@cosine/routes";
import TabNavView from "@cosine/views/TabNavView.vue";
import { computed } from "vue";

const {
  flag: routinesFlag,
  isFlagReady: isFlagReady,
} = useFlag(FlagKey.HasAccessToRoutines, false);

const {
  flag: investingFlag,
} = useFlag(FlagKey.HasAccessToInvesting, false);

const items = computed((): Array<TabNavItem> => {
  if (!isFlagReady.value) return [];

  return [
    { label: "Timeline", to: { name: RouteName.timeline } },
    { label: "Budgets", to: { name: RouteName.budgets } },
    routinesFlag.value ? { label: "Routines", to: { name: RouteName.routines } } : [],
    { label: "Transactions", to: { name: RouteName.transactions } },
    investingFlag.value ? { label: "Investing", to: { name: RouteName.investing } } : [],
    { label: "Transfers", to: { name: RouteName.transfers } },
    { label: "Accounts", to: { name: RouteName.accounts } },
    { label: "Settings", to: { name: RouteName.settings } },
  ].flatMap((item) => item);
});
</script>
