<template>
  <section class="SheetButtonList">
    <slot />
  </section>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>
.SheetButtonList {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}
</style>
