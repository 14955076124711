import "@cosine/assets/css/Global.scss";
import { createRouter } from "@cosine/lib/router";
import { initSentry } from "@cosine/lib/sentry";
import { createHead } from "@unhead/vue";
import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "./ViteApp.vue";

const pinia = createPinia();
const router = createRouter();
const head = createHead();
const app = createApp(App);

initSentry(app);

app.use(router);
app.use(pinia);
app.use(head);

app.mount("body");
