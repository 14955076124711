<template>
  <GlobalBannerView />
  <RouterView />
</template>

<script lang="ts" setup>
import useAppVisibility from "@cosine/composables/useAppVisibility";
import useIntroRedirect from "@cosine/composables/useIntroRedirect";
import useLiveTime from "@cosine/composables/useLiveTime";
import useMobileKeyboardViewport from "@cosine/composables/useMobileKeyboardViewport";
import useOSDetection from "@cosine/composables/useOSDetection";
import usePreventPinchToZoom from "@cosine/composables/usePreventPinchToZoom";
import useSessionSync from "@cosine/composables/useSessionSync";
import useSignalRConnection from "@cosine/composables/useSignalRConnection";
import useAnalyticsStore from "@cosine/stores/useAnalyticsStore";
import GlobalBannerView from "@cosine/views/GlobalBannerView.vue";
import { useSeoMeta } from "@unhead/vue";

useSeoMeta({
  titleTemplate: (title) => {
    return title ? `${title} | Era` : "Era";
  },
});

useSessionSync();
useAnalyticsStore();
useIntroRedirect();
useSignalRConnection();

useLiveTime();
useOSDetection();
useAppVisibility();
usePreventPinchToZoom();
useMobileKeyboardViewport();
</script>
