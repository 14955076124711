import { Interval, endOfDay, format, isWithinInterval, startOfDay, subDays } from "date-fns";

interface ItemGroup<T> {
  title: string,
  items: Array<T>,
  interval: Interval,
}

export default function groupByDate<T>(items: Array<T>, getDate: (item: T) => Date) {
  return items.reduce<Array<ItemGroup<T>>>((acc, item) => {
    const date = getDate(item);

    if (acc.length === 0 || !isWithinInterval(date, acc[acc.length - 1].interval)) {
      acc.push(createGroupForItem(item, date));
    } else {
      acc[acc.length - 1].items.push(item);
    }

    return acc;
  }, []);
}

function createGroupForItem<T>(item: T, date: Date): ItemGroup<T> {
  const today = new Date();

  const todayInterval = { start: startOfDay(today), end: endOfDay(today) };
  if (isWithinInterval(date, todayInterval)) {
    return {
      title: "Today",
      interval: todayInterval,
      items: [item],
    };
  }

  const yesterdayInterval = { start: subDays(todayInterval.start, 1), end: subDays(todayInterval.end, 1) };
  if (isWithinInterval(date, yesterdayInterval)) {
    return {
      title: "Yesterday",
      interval: yesterdayInterval,
      items: [item],
    };
  }

  return {
    title: format(date, "EEE, MMM d"),
    interval: {
      start: startOfDay(date),
      end: endOfDay(date),
    },
    items: [item],
  };
}
