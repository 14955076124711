<template>
  <section class="TransferOnboardingView">
    <PromptPane
      v-if="!hasEnabledTransfers || !hasEnoughAccountsToTransfer"
      iconName="ArrowUpRightHexagon"
      iconColor="Teal"
      backgroundColor="Teal"
      title="Make money moves"
      body="Easy, fee-free transfers between linked financial accounts. Coming soon: automated transfers using Routines."
    >
      <template #button>
        <FormButton
          v-if="!hasEnabledTransfers"
          variant="Primary"
          :disabled="isRequesting"
          @click="handleEnableTransfersClick"
        >
          <span v-if="isRequesting">
            Enabling transfers…
          </span>
          <span v-else>
            Enable transfers
          </span>
        </FormButton>

        <FormButton
          v-else
          variant="Primary"
          @click="router.push({ name: RouteName.accounts, query: { connect: '' } })"
        >
          <template v-if="transferAccounts.length === 0">
            Link accounts
          </template>
          <template v-else>
            Link another account
          </template>
        </FormButton>
      </template>

      <template
        v-if="!hasEnabledTransfers"
        #disclaimer
      >
        By enabling transfers, you agree to our <a href="https://era.app/legal/privacy-policy" target="_blank">privacy policy</a> and <a href="https://era.app/legal/terms-of-service" target="_blank">terms of service</a>.
      </template>
    </PromptPane>

    <template v-if="hasEnabledTransfers">
      <SectionHeader
        v-if="transferAccounts.length > 0"
        title="Linked accounts"
      />
      <FinancialAccountList
        v-if="enabledTransferAccounts.length > 0"
        title="Transfer-ready"
        :subtitle="!hasEnoughAccountsToTransfer ? '1 more needed to initiate a transfer' : undefined"
        iconName="CheckSmall"
        iconColor="Teal"
        :accounts="enabledTransferAccounts"
      />
      <FinancialAccountList
        v-if="disabledTransferAccounts.length > 0"
        title="Needs instant verification"
        subtitle="Confirm these accounts to enable them for transfers"
        iconName="CheckSmall"
        :accounts="disabledTransferAccounts"
        includeEnableButton
        @click:enable="handleDisableAccountClick"
      />
      <FinancialAccountList
        v-if="unsupportedAccountsForTransfer.length > 0"
        title="Unsupported"
        subtitle="These accounts don’t support money transfers yet"
        iconName="X"
        :accounts="unsupportedAccountsForTransfer"
      />
    </template>
  </section>
</template>

<script lang="ts" setup>
import FinancialAccountList from "@cosine/components/FinancialAccountList.vue";
import FormButton from "@cosine/components/FormButton.vue";
import PromptPane from "@cosine/components/PromptPane.vue";
import SectionHeader from "@cosine/components/SectionHeader.vue";
import useRequest from "@cosine/composables/useRequest";
import { RouteName } from "@cosine/routes";
import useTransferStore from "@cosine/stores/useTransferStore";
import { MxWidgetMode } from "@cosine/types/api-models";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";

const router = useRouter();

const transferStore = useTransferStore();
const {
  transferAccounts,
  enabledTransferAccounts,
  disabledTransferAccounts,
  unsupportedAccountsForTransfer,
  hasEnabledTransfers,
  hasEnoughAccountsToTransfer,
} = storeToRefs(transferStore);
const {
  enableTransfers,
} = transferStore;

const {
  isRequesting,
  handleRequest,
} = useRequest();

function handleEnableTransfersClick() {
  handleRequest(enableTransfers(), {
    showOnlyErrors: true,
  });
}

function handleDisableAccountClick() {
  router.push({
    name: RouteName.accounts,
    query: {
      connect: "",
      mode: MxWidgetMode.Verification,
    },
  });
}
</script>

