<template>
  <TransitionGroup
    class="CardCarousel"
    tag="section"
  >
    <slot />
  </TransitionGroup>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>
.CardCarousel {
  display: flex;
  height: 184px;
  border-block: 1px solid var(--colorSwissGrey100);
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  max-width: var(--layoutMaxWidth);

  &::-webkit-scrollbar {
    display: none;
  }

  > :deep(.TimelineEntry) {
    --cardPeekWidth: 40px;

    scroll-snap-align: center;
    min-width: calc(100% - var(--cardPeekWidth));

    &:first-of-type {
      scroll-snap-align: start;
    }

    &:last-of-type {
      scroll-snap-align: end;
    }

    &:first-of-type:last-of-type {
      width: 100%;
    }
  }
}
</style>
