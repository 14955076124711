import { Ref, onBeforeUnmount, onMounted } from "vue";

export default function useTapToScrollToTop(scrollRef?: Ref<HTMLElement | undefined>) {
  const scrollToTopEventName = "TapToScrollToTop";

  onMounted(() => {
    document.documentElement.addEventListener(scrollToTopEventName, handleScrollToTop);
  });

  onBeforeUnmount(() => {
    document.documentElement.removeEventListener(scrollToTopEventName, handleScrollToTop);
  });

  function dispatchScrollToTop() {
    document.documentElement.dispatchEvent(new CustomEvent(scrollToTopEventName));
  }

  function handleScrollToTop() {
    scrollRef?.value?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return {
    scrollToTop: dispatchScrollToTop,
  };
}
