<template>
  <ModalPage class="AuthenticatePage">
    <AlertBanner
      v-if="requestError"
      iconName="Exclamation"
      title="Error"
      body="Something went wrong. Try again."
      status="Negative"
    />
    <Spinner :isVisible="!requestError" />
  </ModalPage>
</template>

<script lang="ts" setup>
import AlertBanner from "@cosine/components/AlertBanner.vue";
import Spinner from "@cosine/components/Spinner.vue";
import useApiClient from "@cosine/composables/useApiClient";
import useRequest from "@cosine/composables/useRequest";
import { currentTimezone } from "@cosine/lib/constants/timezones";
import { RoutePath } from "@cosine/routes";
import useAuthStore from "@cosine/stores/useAuthStore";
import useStytchStore from "@cosine/stores/useStytchStore";
import { FullStackConstants, IApiResponse, IUserModel } from "@cosine/types/api-models";
import ModalPage from "@shared/components/ModalPage.vue";
import { MagicLinksAuthenticateResponse, OAuthAuthenticateResponse } from "@stytch/vanilla-js";
import { storeToRefs } from "pinia";
import { computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const route = useRoute();
const {
  requestError,
  handleRequest,
} = useRequest();
const { apiClient } = useApiClient();
const { stytchClient } = useStytchStore();
const { currentUser } = storeToRefs(useAuthStore());

const { token } = route.query;
if (!token) { router.push(RoutePath.login); }

onMounted(async () => {
  const { token } = route.query;

  await handleRequest<OAuthAuthenticateResponse | MagicLinksAuthenticateResponse>(authClient.value.authenticate(String(token), {
    session_duration_minutes: FullStackConstants.StytchSessionDurationMinutes,
  }));

  const [, res] = await handleRequest(apiClient.value.post<IApiResponse<IUserModel>>(`/users/me`, {TimeZoneId: currentTimezone}));
  const user = res?.data.Result;

  if (user) {
    currentUser.value = user;

    router.push(
      user.Status === "Active"
        ? RoutePath.index
        : RoutePath.waitlist,
    );
  }
});

const authClient = computed(() => {
  switch (route.query.stytch_token_type) {
    case "magic_links":
      return stytchClient.magicLinks;
    default:
      return stytchClient.oauth;
  }
});
</script>
