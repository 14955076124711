<template>
  <SheetScreen
    class="ReviewTransferView"
    :zIndex="1"
    title="Review transfer"
    @click:back="handleBackClick"
  >
    <DescriptionList>
      <DescriptionListItem
        label="Amount"
      >{{ formatAmount(transfer.Amount) }}</DescriptionListItem>

      <DescriptionListItem
        label="From"
      >
        <AccountLabel
          :modelValue="sourceAccount"
          size="Small"
          justify="Right"
        />
      </DescriptionListItem>

      <DescriptionListItem
        label="To"
      >
        <AccountLabel
          :modelValue="destinationAccount"
          size="Small"
          justify="Right"
        />
      </DescriptionListItem>

      <DescriptionListItem
        v-if="transfer.Description"
        label="Memo"
        :value="transfer.Description"
      />
    </DescriptionList>

    <Form
      class="ReviewTransferView__form"
      :isDisabled="isRequesting"
    >
      <FormButton
        class="ReviewTransferView__formButton"
        variant="Primary"
        @click="handleConfirmClick"
      >Transfer {{ formatAmount(transfer.Amount) }}</FormButton>
    </Form>
  </SheetScreen>
</template>

<script lang="ts" setup>
import AccountLabel from "@cosine/components/AccountLabel.vue";
import DescriptionList from "@cosine/components/DescriptionList.vue";
import DescriptionListItem from "@cosine/components/DescriptionListItem.vue";
import Form from "@cosine/components/Form.vue";
import FormButton from "@cosine/components/FormButton.vue";
import SheetScreen from "@cosine/components/SheetScreen.vue";
import useRequest from "@cosine/composables/useRequest";
import formatAmount from "@cosine/lib/utils/financial/formatAmount";
import { RouteName } from "@cosine/routes";
import useTransferStore from "@cosine/stores/useTransferStore";
import { IEnrichedAccountModel, IFundsTransfer } from "@cosine/types/api-models";
import { storeToRefs } from "pinia";
import { computed, toRefs } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const transferStore = useTransferStore();
const {
  confirmTransfer,
} = transferStore;
const {
  transferAccounts,
} = storeToRefs(transferStore);
const {
  handleRequest,
  isRequesting,
} = useRequest();

const props = defineProps<{
  transfer: IFundsTransfer
}>();

const {transfer} = toRefs(props);

const sourceAccount = computed((): IEnrichedAccountModel | null => {
  return transferAccounts.value.find((account) => account.EraAccountId === transfer.value?.SourceEraAccountId) || null;
});

const destinationAccount = computed((): IEnrichedAccountModel | null => {
  return transferAccounts.value.find((account) => account.EraAccountId === transfer.value?.DestinationEraAccountId) || null;
});

function handleBackClick() {
  router.push({name: RouteName.transfersEdit, params: {id: transfer.value?.EraFundsTransferId}});
}

async function handleConfirmClick() {
  if (!transfer.value) return;

  const [err] = await handleRequest(confirmTransfer(transfer.value.EraFundsTransferId), {
    // TODO: show success when we can customize the wording
    showOnlyErrors: true,
  });

  if (!err) {
    router.push({name: RouteName.transfers});
  }
}
</script>

<style lang="scss" scoped>
.ReviewTransferView__form {
  padding: 16px;
}
</style>
