<template>
  <UIText
    ref="elRef"
    class="TimelineCalloutTitle"
    tag="h3"
    weight="Medium"
    v-bind="{size}"
    isMultiline
  >
    {{ modelValue.Title }}

    <span
      v-for="(rect, i) in calloutRects"
      :key="i"
      class="TimelineCalloutTitle__calloutRect"
      :style="{
        left: `${rect.x}px`,
        top: `${rect.y}px`,
        width: `${rect.width}px`,
        height: `${rect.height}px`
      }"
    />

    <span
      v-if="calloutRects.length > 0"
      class="TimelineCalloutTitle__title--callout TimelineCalloutTitle__title"
      aria-hidden="true"
      :style="`clip-path: path('${calloutClipPath}')`"
    >{{ modelValue.Title }}</span>
  </UIText>
</template>

<script lang="ts" setup>
import UIText from "@cosine/components/UIText.vue";
import { IClientTimelineEntry } from "@cosine/types/api-models";
import { TextSize } from "@shared/components/Text.types";
import { computed, nextTick, onBeforeUnmount, onMounted, ref, toRefs, watch } from "vue";

const props = withDefaults(defineProps<{
  modelValue: IClientTimelineEntry,
  size?: keyof typeof TextSize,
}>(), {
  size: "2XLarge",
});

const {
  modelValue,
} = toRefs(props);

const resizeObserver = new ResizeObserver(updateCalloutRects);
const elRef = ref<typeof UIText>();
const calloutRanges = ref<Array<Range>>([]);
const calloutRects = ref<Array<Pick<DOMRect, "x" | "y" | "width" | "height">>>([]);
const calloutPadding = 4;
const layoutMargin = 16;
const puncts = ".,;:!?".split("");

onMounted(() => {
  if (!elRef.value) return;

  updateCalloutRanges();
  resizeObserver.observe(elRef.value.$el as HTMLElement);
});

onBeforeUnmount(() => {
  resizeObserver.disconnect();
});

watch([
  () => modelValue.value.Title,
  () => modelValue.value.TitleCallouts,
], () => {
  nextTick(updateCalloutRanges);
});

const textNode = computed(() => {
  if (!elRef.value) return;

  return Array.from((elRef.value.$el as HTMLElement).childNodes).find((node) => node.nodeValue?.includes(modelValue.value.Title));
});

const calloutClipPath = computed((): string => {
  return calloutRects.value.reduce((acc, {x, y, width, height}) => {
    return acc + ` M${x},${y} L${x + width},${y} L${x + width},${y + height} L${x},${y + height} L${x},${y}`;
  }, "");
});

function updateCalloutRanges() {
  calloutRanges.value = modelValue.value.TitleCallouts.flatMap((callout) => {
    const startIndex = modelValue.value.Title.indexOf(callout);

    if (!textNode.value || startIndex === -1) return [];
    const endIndex = startIndex + callout.length;
    const punctOffset = puncts.includes(modelValue.value.Title.charAt(endIndex)) ? 1 : 0;

    const range = new Range();
    range.setStart(textNode.value, startIndex);
    range.setEnd(textNode.value, endIndex + punctOffset);
    
    return range;
  });

  updateCalloutRects();
}

function updateCalloutRects() {
  if (!elRef.value || !textNode.value) return;

  const parentRect = (elRef.value.$el as HTMLElement).getBoundingClientRect();

  calloutRects.value = calloutRanges.value.flatMap((range) => {
    let hasAttachedToEdge = false;
    if (!range.getClientRects) return []; // `getClientRects` is undefined in the test environment

    return Array.from(range.getClientRects()).reverse().map(({x, y, width, height}) => {
      const shouldAttachToEdge = !hasAttachedToEdge && x === parentRect.x;
      const leftPadding = shouldAttachToEdge ? layoutMargin : calloutPadding;
      if (shouldAttachToEdge) hasAttachedToEdge = true;

      return {
        x: x - (parentRect.x + leftPadding),
        y: y - parentRect.y + 1,
        width: width + leftPadding + calloutPadding,
        height,
      };
    });
  });
}
</script>

<style lang="scss" scoped>
.TimelineCalloutTitle {
  position: relative;
  z-index: 0;
  line-height: 1.175 !important; // Allowed only because we need a very specific line-height that’s solely to create enough spacing for the callout rects
}

.TimelineCalloutTitle__calloutRect {
  position: absolute;
  background-color: var(--colorSwissTeal300);
}

.TimelineCalloutTitle__title--callout {
  position: absolute;
  inset: 0;
  color: var(--colorWhite);
  pointer-events: none;
}
</style>
