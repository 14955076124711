<template>
  <AccountSelectButton
    label="From"
    :modelValue="sourceAccountId"
    :accounts="enabledTransferAccounts"
    @click="$emit('update:viewState', TransferAccountSelectViewState.SourceSelect)"
  />
  <AccountSelectButton
    label="To"
    :modelValue="destinationAccountId"
    :accounts="enabledTransferAccounts"
    @click="$emit('update:viewState', TransferAccountSelectViewState.DestinationSelect)"
  />

  <Teleport :to="portal">
    <Transition>
      <AccountSelectView
        v-if="viewState === TransferAccountSelectViewState.SourceSelect"
        title="Transfer from"
        :modelValue="sourceAccountId"
        @update:modelValue="handleSourceSelect"
        @click:back="handleBack"
      />
      <AccountSelectView
        v-else-if="viewState === TransferAccountSelectViewState.DestinationSelect"
        title="Transfer to"
        :modelValue="destinationAccountId"
        @update:modelValue="handleDestinationSelect"
        @click:back="handleBack"
      />
    </Transition>
  </Teleport>
</template>

<script lang="ts" setup>
import AccountSelectButton from "@cosine/components/AccountSelectButton.vue";
import useTransferStore from "@cosine/stores/useTransferStore";
import AccountSelectView from "@cosine/views/AccountSelectView.vue";
import { TransferAccountSelectViewState } from "@cosine/views/TransferAccountSelectView.types";
import { storeToRefs } from "pinia";
import { toRefs } from "vue";

const {enabledTransferAccounts} = storeToRefs(useTransferStore());

const emit = defineEmits<{
  "update:sourceAccountId": [string | null],
  "update:destinationAccountId": [string | null],
  "update:viewState": [TransferAccountSelectViewState | null],
}>();

const props = defineProps<{
  portal: string,
  sourceAccountId: string | null,
  destinationAccountId: string | null,
  viewState: string | null,
}>();

const {
  sourceAccountId,
  destinationAccountId,
} = toRefs(props);

function handleSourceSelect(id: string) {
  emit("update:sourceAccountId", id);

  if (id === destinationAccountId.value) {
    emit("update:destinationAccountId", null);
  }

  handleBack();
}

function handleDestinationSelect(id: string) {
  emit("update:destinationAccountId", id);

  if (id === sourceAccountId.value) {
    emit("update:sourceAccountId", null);
  }

  handleBack();
}

function handleBack() {
  emit("update:viewState", null);
}
</script>
