<template>
  <GlobalBanner
    :type="flag.type"
    :message="flag.message"
  />
</template>

<script lang="ts" setup>
import GlobalBanner from "@cosine/components/GlobalBanner.vue";
import useFlag from "@cosine/composables/useFlag";
import { FlagKey } from "@cosine/composables/useFlag.types";

const {flag} = useFlag<{type?: string, message?: string}>(FlagKey.GlobalBanner, {});
</script>
