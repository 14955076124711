<template>
  <fieldset class="RoutineScheduleFieldset">
    <RoutineFrequencyField
      v-if="form.frequency"
      v-model="form.frequency"
    />

    <DayOfWeekField
      v-if="form.frequency === 'Weekly'"
      v-model="form.dayOfWeek"
    />

    <DayOfMonthField
      v-else-if="form.frequency === 'Monthly'"
      v-model="form.dayOfMonth"
    />

    <TimeOfDayField
      v-model="form.hour"
    />
  </fieldset>
</template>

<script lang="ts" setup>
import DayOfMonthField from "@cosine/components/DayOfMonthField.vue";
import { DayOfWeek } from "@cosine/components/DayOfWeekField.types";
import DayOfWeekField from "@cosine/components/DayOfWeekField.vue";
import { RoutineFrequency } from "@cosine/components/RoutineFrequencyField.types";
import RoutineFrequencyField from "@cosine/components/RoutineFrequencyField.vue";
import TimeOfDayField from "@cosine/components/TimeOfDayField.vue";
import { DayOfTheMonthRange, parseExpression } from "cron-parser";
import { computed, ref, toRefs, watch } from "vue";

const emit = defineEmits<{
  "update:modelValue": [string]
}>();

const props = defineProps<{
  modelValue: string,
}>();

const {modelValue} = toRefs(props);

const form = ref<{
  frequency: RoutineFrequency | null,
  dayOfWeek: DayOfWeek | null,
  dayOfMonth: DayOfTheMonthRange | null,
  hour: number,
}>({
  frequency: RoutineFrequency.Weekly,
  dayOfWeek: DayOfWeek.Monday,
  dayOfMonth: null,
  hour: 8,
});

const parsedCronExpression = computed(() => parseExpression(modelValue.value));

const frequency = computed(() => {
  const {
    dayOfWeek,
    dayOfMonth,
  } = parseExpression(modelValue.value).fields;

  if (dayOfWeek.length === 1) return RoutineFrequency.Weekly;
  if (dayOfMonth.length === 1) return RoutineFrequency.Monthly;

  return null;
});

const existingDayOfWeek = computed(() => {
  // TODO: support multiple days of the week
  const dayOfWeekIndex = parsedCronExpression.value.fields.dayOfWeek[0];
  return Object.values(DayOfWeek)[dayOfWeekIndex];
});

const existingDayOfMonth = computed(() => {
  // TODO: support multiple days of the month
  return parsedCronExpression.value.fields.dayOfMonth[0];
});

const existingHour = computed(() => {
  return parsedCronExpression.value.fields.hour[0];
});

watch(modelValue, () => {
  form.value.frequency = frequency.value;
  form.value.dayOfWeek = existingDayOfWeek.value ?? null;
  form.value.dayOfMonth = existingDayOfMonth.value ?? null;
  form.value.hour = existingHour.value ?? 8;
}, {
  immediate: true,
});

const formattedCronExpression = computed(() => {
  switch (form.value.frequency) {
    case RoutineFrequency.Weekly:
      return `0 0 ${form.value.hour} ? * ${form.value.dayOfWeek}`;
    case RoutineFrequency.Monthly:
      return `0 0 ${form.value.hour} ${form.value.dayOfMonth} * ?`;
  }

  return modelValue.value;
});

watch(formattedCronExpression, () => {
  emit("update:modelValue", formattedCronExpression.value);
});
</script>

<style lang="scss" scoped>
.RoutineScheduleFieldset {
  display: grid;
  gap: 24px 0px;
  padding: 24px var(--layoutMargin);
  outline: 1px solid var(--colorSwissGrey100);
}
</style>
