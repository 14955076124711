import useRuntimeEnvironment from "@shared/composables/config/useRuntimeEnvironment";
import useLogger from "@shared/composables/useLogger";
import { StytchUIClient } from "@stytch/vanilla-js";
import { defineStore } from "pinia";

export default defineStore("StytchStore", () => {
  const { isTest } = useRuntimeEnvironment();
  const logger = useLogger();
  const token = import.meta.env.VITE_STYTCH_PUBLIC_TOKEN;

  if (!token && !isTest) {
    logger.error("Missing STYTCH_PUBLIC_TOKEN. Login will not work.");
  }

  const stytchClient = new StytchUIClient(token);

  return {
    stytchClient,
  };
});
