<template>
  <Transition>
    <section
      v-show="isVisible"
      class="ToastAlert"
    >
      <div
        class="ToastAlert__track"
        :style="{
          pointerEvents: isVisible ? 'auto' : 'none'
        }"
        @click="handleClick"
      >
        <div
          ref="alertContainerRef"
          class="ToastAlert__alertContainer"
        >
          <AlertBanner
            variant="Toast"
            v-bind="{
              title,
              body,
              status,
            }"
          />
        </div>
      </div>
    </section>
  </Transition>
</template>

<script lang="ts" setup>
import AlertBanner from "@cosine/components/AlertBanner.vue";

const emit = defineEmits<{
  cancel: [],
  close: [],
}>();

defineProps<{
  title?: string | undefined,
  body?: string | undefined,
  isVisible: boolean,
  status: "Positive" | "Negative",
}>();

function handleClick() {
  emit("close");
}
</script>

<style lang="scss" scoped>
.ToastAlert {
  --paddingTop: calc(8px + var(--viewportPaddingTop));
  --transitionDuration: 250ms;

  position: fixed;
  top: 0;
  left: 0;
  width: var(--viewportWidth, 100%);
  z-index: 9999999;
  pointer-events: none;
  user-select: none;
  transition: none var(--transitionDuration);
}

.ToastAlert__track {
  width: 100%;
  max-width: var(--layoutMaxWidth);
  height: var(--appHeaderHeight);
  margin-inline: auto;
  padding: 0 16px 16px;
  overflow: hidden;
  mask-image: linear-gradient(to bottom, transparent 0%, black var(--paddingTop));
  backdrop-filter: blur(6px);
  cursor: pointer;

  .ToastAlert.v-enter-active & {
    transition: backdrop-filter var(--transitionDuration) var(--easeOutCubic);
  }

  .ToastAlert.v-leave-active & {
    transition: backdrop-filter var(--transitionDuration) var(--easeInCubic);
  }

  .ToastAlert.v-enter-from &,
  .ToastAlert.v-leave-to & {
    backdrop-filter: blur(0px);
  }
}

.ToastAlert__alertContainer {
  padding-top: var(--paddingTop);

  .ToastAlert.v-enter-active & {
    transition: transform var(--transitionDuration) var(--easeOutCubic);
  }

  .ToastAlert.v-leave-active & {
    transition: transform var(--transitionDuration) var(--easeInCubic);
  }

  .ToastAlert.v-enter-from &,
  .ToastAlert.v-leave-to & {
    transform: translateY(-100%);
  }
}
</style>
