<template>
  <SheetSelectButton
    class="AccountSelectButton"
    :iconName="modelValue ? 'Pencil' : 'ArrowRight'"
    v-bind="{label}"
  >
    <AccountLabel
      v-if="modelValue"
      :modelValue="account"
    />
    <span v-else>Select</span>
  </SheetSelectButton>
</template>

<script lang="ts" setup>
import AccountLabel from "@cosine/components/AccountLabel.vue";
import SheetSelectButton from "@cosine/components/SheetSelectButton.vue";
import { IEnrichedAccountModel } from "@cosine/types/api-models";
import { computed, toRefs } from "vue";

const props = defineProps<{
  label: string,
  modelValue: string | null,
  accounts: Array<IEnrichedAccountModel>
}>();
const {
  modelValue,
  accounts,
} = toRefs(props);

const account = computed(() => {
  if (!modelValue.value) return null;

  return accounts.value.find((account) => {
    return account.EraAccountId === modelValue.value;
  }) || null;
});
</script>
