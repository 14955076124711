<template>
  <SheetScreen class="PrepareTransferView">
    <Transition>
      <SheetScreen
        v-show="!viewState"
        class="PrepareTransferView__formView"
      >
        <UIText
          class="PrepareTransferView__title"
          tag="h1"
          size="XLarge"
          weight="Medium"
        >Transfer</UIText>

        <Form
          class="PrepareTransferView__form"
          :isDisabled="isRequesting"
        >
          <CurrencyInput
            class="PrepareTransferView__currencyInput"
            v-model="modelValue.amount"
            variant="Inline"
            :autofocus="!modelValue.amount"
          />

          <UIText
            v-if="formattedDailyLimit"
            class="PrepareTransferView__limit"
            tag="p"
            size="XSmall"
            weight="Medium"
          >{{ formattedDailyLimit }} daily limit</UIText>

          <TransferAccountSelectView
            v-if="portalRef"
            :portal="`#${portalRef.id}`"
            v-model:sourceAccountId="modelValue.sourceAccountId"
            v-model:destinationAccountId="modelValue.destinationAccountId"
            v-model:viewState="viewState"
          />

          <FormButton
            class="PrepareTransferView__formButton"
            :disabled="!validatedModelValue"
            variant="Primary"
            type="submit"
            @click="handleReviewClick"
          >Review transfer</FormButton>
        </Form>
      </SheetScreen>
    </Transition>

    <div
      ref="portalRef"
      id="PrepareTransferViewPortal"
    />
  </SheetScreen>
</template>

<script lang="ts" setup>
import CurrencyInput from "@cosine/components/CurrencyInput.vue";
import Form from "@cosine/components/Form.vue";
import FormButton from "@cosine/components/FormButton.vue";
import SheetScreen from "@cosine/components/SheetScreen.vue";
import UIText from "@cosine/components/UIText.vue";
import useRequest from "@cosine/composables/useRequest";
import formatAmount from "@cosine/lib/utils/financial/formatAmount";
import { RouteName } from "@cosine/routes";
import useFeatureLimitStore from "@cosine/stores/useFeatureLimitStore";
import useTransferStore from "@cosine/stores/useTransferStore";
import { PrepareTransferOptions } from "@cosine/stores/useTransferStore.types";
import { NullableValues } from "@cosine/types";
import { FeatureLimitTimePeriod, FeatureLimitType, IFundsTransfer } from "@cosine/types/api-models";
import { TransferAccountSelectViewState } from "@cosine/views/TransferAccountSelectView.types";
import TransferAccountSelectView from "@cosine/views/TransferAccountSelectView.vue";
import { computed, ref, toRefs } from "vue";
import { useRouter } from "vue-router";

// TODO: combine AccountSelectButton with AccountSelectView, so we can test all its logic
// within that view vs needing to duplicate testing in this view

const router = useRouter();
const {
  getFeatureLimit,
} = useFeatureLimitStore();
const transferStore = useTransferStore();
const {
  prepareTransfer,
  updateTransfer,
} = transferStore;
const {
  handleRequest,
  isRequesting,
} = useRequest();

const props = defineProps<{
  transfer?: IFundsTransfer,
}>();
const {transfer} = toRefs(props);

const portalRef = ref<HTMLElement>();
const viewState = ref<null | TransferAccountSelectViewState>(null);

const modelValue = ref<NullableValues<PrepareTransferOptions>>({
  amount: transfer.value?.Amount || null,
  sourceAccountId: transfer.value?.SourceEraAccountId || null,
  destinationAccountId: transfer.value?.DestinationEraAccountId || null,
  description: transfer.value?.Description || undefined,
});

defineExpose({
  modelValue,
});

const validatedModelValue = computed((): PrepareTransferOptions | void => {
  if (
    !modelValue.value.amount ||
    !modelValue.value.sourceAccountId ||
    !modelValue.value.destinationAccountId
  ) return;

  return {
    amount: modelValue.value.amount,
    sourceAccountId: modelValue.value.sourceAccountId,
    destinationAccountId: modelValue.value.destinationAccountId,
    description: modelValue.value.description || undefined,
  };
});

const formattedDailyLimit = computed(() => {
  const featureLimit = getFeatureLimit(
    FeatureLimitType.FundsTransferAmount,
    FeatureLimitTimePeriod.Daily,
  );

  return featureLimit ? formatAmount(featureLimit.Limit) : null;
});

async function handleReviewClick() {
  if (!validatedModelValue.value) return;

  const request = !transfer.value?.EraFundsTransferId
    ? prepareTransfer(validatedModelValue.value)
    : updateTransfer(transfer.value.EraFundsTransferId, validatedModelValue.value);

  const [, updatedTransfer] = await handleRequest(request, {
    showOnlyErrors: true,
  });

  if (updatedTransfer) {
    router.push({
      name: RouteName.transfersReview,
      params: {
        id: updatedTransfer.EraFundsTransferId,
      },
    });
  }
}
</script>

<style lang="scss" scoped>
.PrepareTransferView {
  position: relative;
  z-index: 0;
  overflow-x: hidden;
  mask-image: linear-gradient(to bottom, transparent 0px, black 24px);
}

.PrepareTransferView__form {
  :deep(fieldset) {
    display: grid;
    gap: 1px;
  }
}

.PrepareTransferView__formView {
  display: grid;
  gap: 16px;
  align-content: flex-start;
  padding: 24px 0;
}

.PrepareTransferView__title {
  padding: 0 var(--layoutMargin);
}

.PrepareTransferView__currencyInput {
  margin-bottom: 24px;
}

.PrepareTransferView__limit {
  padding: 0 var(--layoutMargin) 24px;
  color: var(--colorSwissAlphaBlack600);
}

.PrepareTransferView__formButton {
  margin: 24px var(--layoutMargin);
  width: auto;
}
</style>
