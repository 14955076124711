<template>
  <Transition>
    <div
      v-if="isVisible"
      ref="elRef"
      class="Spinner"
      :class="{
        'Spinner--isCentered': isCentered,
      }"
    >
      <div class="Spinner__cube">
        <div
          v-for="n in segmentCount"
          :key="n"
          class="Spinner__segment"
          :style="`--index: ${segmentCount - n}`"
        >
          <div class="Spinner__face--frontLeft Spinner__face--side Spinner__face" />
          <div class="Spinner__face--frontRight Spinner__face--side Spinner__face" />
          <div class="Spinner__face--overlay Spinner__face--frontRight Spinner__face--side Spinner__face" />
          <div class="Spinner__face--backLeft Spinner__face--side Spinner__face" />
          <div class="Spinner__face--backRight Spinner__face--side Spinner__face" />
          <div class="Spinner__face--top Spinner__face" />
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import { SpinnerType } from "@cosine/components/Spinner.types";
import { SpinnerLoadingAnimation } from "@cosine/lib/animations/Spinner/SpinnerLoadingAnimation";
import { SpinnerThinkingAnimation } from "@cosine/lib/animations/Spinner/SpinnerThinkingAnimation";
import { ref, toRefs, watch } from "vue";

const props = withDefaults(defineProps<{
  type?: keyof typeof SpinnerType,
  isCentered?: boolean,
  isVisible?: boolean,
}>(), {
  type: "Loading",
  isCentered: false,
  isVisible: true,
});

const {
  type,
  isVisible,
} = toRefs(props);

const elRef = ref<HTMLElement>();
const segmentCount = 4;
let stopAnimation = () => {};

watch(type, restartAnimation);
watch(elRef, restartAnimation);

function restartAnimation() {
  if (!isVisible.value) { return; }

  stopAnimation();
  stopAnimation = startAnimation();
}

function startAnimation() {
  const el = elRef.value;
  if (!el) { return () => {}; }

  switch (type.value) {
    case SpinnerType.Thinking:
      return new SpinnerThinkingAnimation(el).animate();
  }

  return new SpinnerLoadingAnimation(el).animate();
}
</script>

<style lang="scss" scoped>
.Spinner {
  --radius: 12px;
  --size: calc(2 * var(--radius));

  // TEMP: trying out the animating cube with *all* black, but don’t want to remove the shading code yet.
  --faceColor: var(--colorSwissBlack);
  --faceColorLight: var(--colorSwissBlack);
  --faceColorDark: var(--colorSwissBlack);

  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  aspect-ratio: 1;
  transition: none 350ms; // set a duration so <Transition> knows when to attach classes. without “none”, the default is “all”, which we don’t want.
}

.Spinner--isCentered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.Spinner__cube {
  width: var(--size);
  aspect-ratio: 1;
  transition-property: transform, opacity;
  transition-duration: inherit;

  .v-enter-active & {
    transition-timing-function: var(--easeOutCubic);
  }

  .v-leave-active & {
    transition-timing-function: var(--easeInCubic);
  }

  .v-enter-from &,
  .v-leave-to & {
    transform: scale(0);
    opacity: 0;
  }
}

.Spinner__segment {
  --segmentHeight: calc(var(--radius) / 2);
  --index: 0;

  position: relative;
  transform-style: preserve-3d;
  transform: rotateX(-35deg) rotateY(45deg) translateY(calc(var(--index) * (var(--radius) * 0.5 - 0.5px)));
}

.Spinner__face {
  position: absolute;
  width: var(--size);
  aspect-ratio: 1;
  background: var(--faceColorDark);
}

.Spinner__face--overlay {
  background: var(--faceColor);
  opacity: 0;
}

.Spinner__face--side {
  height: var(--segmentHeight);
  backface-visibility: hidden;
  transform: translateY(calc(0.5 * var(--segmentHeight))) rotateY(var(--rotateY)) translateZ(calc(var(--radius)));
}

.Spinner__face--top {
  transform: translateY(calc(-2 * var(--segmentHeight))) rotateX(-90deg) translateZ(calc(var(--segmentHeight) * 0.5));
  background: var(--faceColorLight);
}

.Spinner__face--frontRight {
  --rotateY: 0deg;
}

.Spinner__face--backRight {
  --rotateY: 180deg;
}

.Spinner__face--backLeft {
  --rotateY: 90deg;
}

.Spinner__face--frontLeft {
  --rotateY: 270deg;

  background: var(--faceColor);
}
</style>
