<template>
  <ScrollContainer
    class="AccountsPage"
    viewport="Mobile"
  >
    <section class="AccountsPage__cardList">
      <FinancialAccountsView />
      <ImportMintTransactionsView />
      <FeatureCard
        title="Payroll"
        badge="Coming soon"
        body="Connect your employer’s payroll processor and get up-to-date payroll breakdowns, withholdings assistance, and tax estimates."
        color="Yellow"
      />
      <FeatureCard
        title="Brokerages"
        badge="Coming soon"
        body="Allow Era to trade, rebalance, and manage your portfolios by simply describing what you want or following your favorite blueprints."
        color="Blue"
      />
    </section>
  </ScrollContainer>

  <Teleport to="body">
    <AccountSheetModalView />
  </Teleport>
</template>

<script lang="ts" setup>
import FeatureCard from "@cosine/components/FeatureCard.vue";
import ScrollContainer from "@cosine/components/ScrollContainer.vue";
import AccountSheetModalView from "@cosine/views/AccountSheetModalView.vue";
import FinancialAccountsView from "@cosine/views/FinancialAccountsView.vue";
import ImportMintTransactionsView from "@cosine/views/ImportMintTransactionsView.vue";
import { useSeoMeta } from "@unhead/vue";

useSeoMeta({
  title: "Accounts",
});
</script>

<style lang="scss" scoped>
.AccountsPage {
  position: relative;
  z-index: 0;
  height: 100%;
  background-color: var(--colorSwissGrey50);
}

.AccountsPage__cardList {
  display: grid;
  gap: 1px;

  // TODO: extract
  @media (display-mode: standalone) {
    padding-bottom: calc(24px + var(--viewportPaddingBottom));
  }
}
</style>
