<template>
  <Transition>
    <SheetModal
      v-if="isTransferModalOpen && !!transfer"
      class="TransferSheetModalView"
      theme="Light"
      @close="$router.push({name: RouteName.transfers})"
    >
      <header class="TransferSheetModalView__header">
        <UIText
          class="TransferSheetModalView__title"
          tag="h1"
          size="XLarge"
          weight="Medium"
        >Transfer</UIText>

        <UIText
          class="TransferSheetModalView__subtitle"
          tag="h2"
          size="XLarge"
          weight="Medium"
        >{{ formatAmount(transfer.Amount) }}</UIText>

        <UIText
          class="TransferSheetModalView__timestamp"
          tag="p"
          size="2XSmall"
          weight="Medium"
        >
          <Timestamp
            :datetime="transfer.RequestedAt"
            format="AbsoluteShort"
          />
        </UIText>
      </header>

      <DescriptionList>
        <DescriptionListItem
          label="From"
        >
          <AccountLabel
            :modelValue="sourceAccount"
            size="Small"
            justify="Right"
          />
        </DescriptionListItem>

        <DescriptionListItem
          label="To"
        >
          <AccountLabel
            :modelValue="destinationAccount"
            size="Small"
            justify="Right"
          />
        </DescriptionListItem>

        <DescriptionListItem
          v-if="transfer.Description"
          class="TransferSheetModalView__description"
          label="Memo"
          :value="transfer.Description"
        />
      </DescriptionList>
    </SheetModal>
  </Transition>
</template>

<script lang="ts" setup>
import AccountLabel from "@cosine/components/AccountLabel.vue";
import DescriptionList from "@cosine/components/DescriptionList.vue";
import DescriptionListItem from "@cosine/components/DescriptionListItem.vue";
import SheetModal from "@cosine/components/SheetModal.vue";
import Timestamp from "@cosine/components/Timestamp.vue";
import UIText from "@cosine/components/UIText.vue";
import formatAmount from "@cosine/lib/utils/financial/formatAmount";
import { RouteName } from "@cosine/routes";
import useTransferStore from "@cosine/stores/useTransferStore";
import { IEnrichedAccountModel } from "@cosine/types/api-models";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();

const transferStore = useTransferStore();
const {
  transferAccounts,
  transfers,
} = storeToRefs(transferStore);

const isTransferModalOpen = computed(() => {
  return [
    RouteName.transfersView,
  ].includes(route.name as RouteName);
});

// TODO: fetch transfer if not in store
const transfer = computed(() => {
  return transfers.value.find((transfer) => transfer.EraFundsTransferId === route.params.id);
});

const sourceAccount = computed((): IEnrichedAccountModel | null => {
  return transferAccounts.value.find((account) => account.EraAccountId === transfer.value?.SourceEraAccountId) || null;
});

const destinationAccount = computed((): IEnrichedAccountModel | null => {
  return transferAccounts.value.find((account) => account.EraAccountId === transfer.value?.DestinationEraAccountId) || null;
});
</script>

<style lang="scss" scoped>
.TransferSheetModalView__header {
  display: grid;
  gap: 4px;
  padding: 24px var(--layoutMargin);
  border-bottom: 1px solid var(--colorSwissGrey100);
}

.TransferSheetModalView__timestamp {
  margin-top: 4px;
  color: var(--colorSwissAlphaBlack600);
}

// TODO: figure this out
.TransferSheetModalView :deep(.DescriptionListItem:last-of-type) {
  border-bottom: none;
}
</style>
