<template>
  <section
    v-if="isUserActive"
    class="AppLayout"
    ref="elRef"
  >
    <div class="AppLayout__container">
      <div class="AppLayout__header">
        <AppHeaderView />
        <AppNavView />
        <LineSpinner
          class="AppLayout__lineSpinner"
          :isVisible="isLoading"
        />
      </div>

      <div class="AppLayout__contentContainer">
        <div class="AppLayout__content">
          <RouterView />
        </div>
      </div>
    </div>
  </section>

  <Teleport to="body">
    <ThreadModalView />
    <UpgradeSheetModalView />
    <AlertView />
  </Teleport>
</template>

<script lang="ts" setup>
import LineSpinner from "@cosine/components/LineSpinner.vue";
import useCurrentUserFetch from "@cosine/composables/useCurrentUserFetch";
import useViewportWidthWithoutScrollbar from "@cosine/composables/useViewportWidthWithoutScrollbar";
import useAppStore from "@cosine/stores/useAppStore";
import useAuthStore from "@cosine/stores/useAuthStore";
import { UserStatus } from "@cosine/types/api-models";
import AlertView from "@cosine/views/AlertView.vue";
import AppHeaderView from "@cosine/views/AppHeaderView.vue";
import AppNavView from "@cosine/views/AppNavView.vue";
import ThreadModalView from "@cosine/views/ThreadModalView.vue";
import UpgradeSheetModalView from "@cosine/views/UpgradeSheetModalView.vue";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";

useCurrentUserFetch();

const { currentUser } = storeToRefs(useAuthStore());
const {isLoading} = storeToRefs(useAppStore());
const elRef = ref<HTMLElement>();
useViewportWidthWithoutScrollbar(elRef);

const isUserActive = computed(() => currentUser.value?.Status === UserStatus.Active);
</script>

<style lang="scss" scoped>
.AppLayout {
  height: 100%;

  @media (min-width: 600px) {
    overflow-y: scroll;
  }
}

.AppLayout__container {
  display: grid;
  grid: min-content auto / auto;
  min-height: 100%;
  max-width: var(--layoutMaxWidth);
  margin: 0 auto;
  position: relative;
  z-index: 0;
  outline: 1px solid var(--colorSwissGrey100);
}

.AppLayout__header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--colorSwissGrey50);
}

.AppLayout__lineSpinner {
  position: absolute;
  inset-inline: 0px;
  bottom: -1px;
}

.AppLayout__contentContainer {
  position: relative;
}

.AppLayout__content {
  @media (max-width: 599px) {
    position: absolute;
    inset: 0;
    overflow: hidden;
  }
}
</style>
