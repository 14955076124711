import useApiClient from "@cosine/composables/useApiClient";
import useFlag from "@cosine/composables/useFlag";
import useConnectionStore from "@cosine/stores/useConnectionStore";
import { ClientConstants, IApiResponse, ICreateRoutineRequest, IFixedAmountFundsTransferRoutineArgs, IPaginatedList, IRoundUpSavingsFundsTransferRoutineArgs, IRoutineModel, IThresholdBasedFundsTransferRoutineArgs, IUpdateRoutineRequest, MessageChannel, RoutineSource, RoutineStatus, UserFeatureFlags } from "@cosine/types/api-models";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export default defineStore("RoutineStore", () => {
  const { apiClient } = useApiClient();
  const { signalRClient } = useConnectionStore();

  const { flag: maxActiveRoutinesFlag } = useFlag(UserFeatureFlags.MaxActiveRoutines, -1);
  const routineMap = ref(new Map<string, IRoutineModel>());
  const routines = ref<Array<IRoutineModel>>([]);
  const pagination = ref({
    pageIndex: 1,
    pageCount: 1,
  });

  const sortedRoutines = computed(() => {
    return routines.value.sort((a, b) => {
      const aIsDisabled = a.Status === RoutineStatus.Disabled;
      const bIsDisabled = b.Status === RoutineStatus.Disabled;

      if (aIsDisabled && !bIsDisabled) { return 1; }
      if (!aIsDisabled && bIsDisabled) { return -1; }
      if (a.NextFireTime < b.NextFireTime) { return -1; }
      if (a.NextFireTime > b.NextFireTime) { return 1; }
      return 0;
    });
  });

  const userRoutines = computed(() => {
    return sortedRoutines.value.filter(({ Source }) => Source === RoutineSource.User);
  });

  const activeUserRoutines = computed(() => {
    return userRoutines.value.filter((routine) => !routine.IsDisabled);
  });

  const canActivateMoreRoutines = computed((): boolean => {
    if (maxActiveRoutinesFlag.value === -1) return true;

    return activeUserRoutines.value.length < maxActiveRoutinesFlag.value;
  });

  function connectRoutines() {
    signalRClient.connection.on(ClientConstants.ReceiveScheduledTaskFiredFromServer, handleRoutineFiredFromServer);
    signalRClient.connection.on(ClientConstants.ReceiveRoutineCreatedFromServer, handleRoutineCreatedFromServer);
    signalRClient.connection.on(ClientConstants.ReceiveRoutineStatusChangedFromServer, handleRoutineStatusChangedFromServer);
  }

  function disconnectRoutines() {
    signalRClient.connection.off(ClientConstants.ReceiveScheduledTaskFiredFromServer, handleRoutineFiredFromServer);
    signalRClient.connection.off(ClientConstants.ReceiveRoutineCreatedFromServer, handleRoutineCreatedFromServer);
    signalRClient.connection.off(ClientConstants.ReceiveRoutineStatusChangedFromServer, handleRoutineStatusChangedFromServer);
  }

  async function fetchRoutines({ pageIndex }: { pageIndex: number; } = { pageIndex: 1 }) {
    const { data: { Result: pagindatedList } } = await apiClient.value.get<IApiResponse<IPaginatedList<IRoutineModel>>>(`/routines/list`, {
      params: {
        RecordsPerPage: 100,
        Page: pageIndex,
      },
    });

    if (pagindatedList) {
      pagindatedList.Items.forEach((routine) => routineMap.value.set(routine.RoutineId, routine));

      routines.value = Array.from(routineMap.value.values());
      pagination.value.pageIndex = pageIndex;
      pagination.value.pageCount = pagindatedList.Pagination.TotalPages;
    }
  }

  async function createRoutine(
    params: Omit<ICreateRoutineRequest, "JsonArgs">,
    args: IFixedAmountFundsTransferRoutineArgs
      | IRoundUpSavingsFundsTransferRoutineArgs
      | IThresholdBasedFundsTransferRoutineArgs,
  ) {
    const paramsWithArgs: ICreateRoutineRequest = {
      ...params,
      JsonArgs: JSON.stringify(args),
    };

    const { data: { Result: createdRoutine } } = await apiClient.value.post<IApiResponse<IRoutineModel>>("/routines", paramsWithArgs);

    if (createdRoutine) {
      setRoutine(createdRoutine);

      return createdRoutine;
    }
  }

  async function updateRoutine(params: IUpdateRoutineRequest, args?: Partial<IFixedAmountFundsTransferRoutineArgs>) {
    const existingRoutine = routineMap.value.get(params.RoutineId);
    const mergedArgs = existingRoutine && args
      ? { JsonArgs: JSON.stringify({ ...existingRoutine.Args, ...args }) }
      : {};

    const { data: { Result: updatedRoutine } } = await apiClient.value.put<IApiResponse<IRoutineModel>>(
      `/routines`,
      <IUpdateRoutineRequest>{
        ...params,
        ...mergedArgs,
      });

    if (existingRoutine && updatedRoutine) {
      return Object.assign(existingRoutine, updatedRoutine);
    }
  }

  async function toggleRoutine(params: Required<Pick<IUpdateRoutineRequest, "RoutineId" | "Disable">>) {
    const { data: { Result: updatedRoutine } } = await apiClient.value.put<IApiResponse<{}>>((params.Disable ? "/routines/disable" : "/routines/enable") + `?RoutineId=${params.RoutineId}`);
    const existingRoutine = routineMap.value.get(params.RoutineId);

    if (existingRoutine && updatedRoutine) {
      return Object.assign(existingRoutine, updatedRoutine);
    }
  }

  async function updateRoutineChannels(params: { RoutineId: string, Channels: Array<MessageChannel>; }) {
    // TODO: in the very rare / almost impossible case of updating a routine that doesn’t exist locally,
    // we will only send the Channels as the JsonArgs, which might overwrite existing Args,
    // so we probably want to handle this in the backend vs relying on the frontend,
    // but again, this feels like an impossible situation
    const existingRoutine = routineMap.value.get(params.RoutineId);

    return updateRoutine({
      RoutineId: params.RoutineId,
      JsonArgs: JSON.stringify({
        ...existingRoutine?.Args,
        Channels: params.Channels,
      }),
    });
  }

  async function runRoutine(params: { RoutineId: string; }) {
    const { data: { Result: updatedRoutine } } = await apiClient.value.post<IApiResponse<{}>>(`/routines/force-fire?RoutineId=${params.RoutineId}`);
    const existingRoutine = routineMap.value.get(params.RoutineId);

    if (existingRoutine && updatedRoutine) {
      return Object.assign(existingRoutine, updatedRoutine);
    }
  }

  async function deleteRoutine(params: { RoutineId: string; }) {
    await apiClient.value.delete<IApiResponse<IRoutineModel>>(`/routines?routineId=${params.RoutineId}`);
    const existingRoutine = routineMap.value.get(params.RoutineId);

    if (existingRoutine) {
      routines.value.splice(routines.value.indexOf(existingRoutine), 1);
      routineMap.value.delete(params.RoutineId);
    }
  }

  function handleRoutineFiredFromServer(payload: { UserId: string, Routine: IRoutineModel; }) {
    const existingRoutine = routineMap.value.get(payload.Routine.RoutineId);
    const updatedRoutine = payload.Routine;

    if (existingRoutine) {
      Object.assign(existingRoutine, updatedRoutine);
    }
  }

  function handleRoutineCreatedFromServer(payload: { UserId: string, Routine: IRoutineModel; }) {
    const newRoutine = payload.Routine;

    setRoutine(newRoutine, { shouldOverride: false });
  }

  function handleRoutineStatusChangedFromServer(payload: { UserId: string, Routine: IRoutineModel; }) {
    const existingRoutine = routineMap.value.get(payload.Routine.RoutineId);
    const updatedRoutine = payload.Routine;

    if (existingRoutine) {
      existingRoutine.Status = updatedRoutine.Status;
    }
  }

  function setRoutine(routine: IRoutineModel, { shouldOverride = true } = {}) {
    const existingRoutine = routineMap.value.get(routine.RoutineId);

    if (existingRoutine && shouldOverride) {
      Object.assign(existingRoutine, routine);
    } else if (!existingRoutine) {
      routineMap.value.set(routine.RoutineId, routine);
      routines.value.unshift(routine);
    }
  }

  return {
    userRoutines,
    pagination,
    canActivateMoreRoutines,

    connectRoutines,
    disconnectRoutines,
    fetchRoutines,
    toggleRoutine,
    updateRoutineChannels,
    runRoutine,
    createRoutine,
    updateRoutine,
    deleteRoutine,
  };
});
