<template>
  <Form
    class="BudgetForm"
    v-bind="{isDisabled}"
    @submit="handleSubmit"
  >
    <div class="BudgetForm__fieldset">
      <CurrencyInput
        v-model="amount"
      />
      <IconFormButton
        class="BudgetForm__button"
        type="submit"
        iconName="Check"
      />
    </div>
  </Form>
</template>

<script lang="ts" setup>
import CurrencyInput from "@cosine/components/CurrencyInput.vue";
import Form from "@cosine/components/Form.vue";
import IconFormButton from "@cosine/components/IconFormButton.vue";
import { IBudgetDefinitionModel } from "@cosine/types/api-models";
import { ref, toRefs } from "vue";

const emit = defineEmits<{
  focus: [],
  submit: [form:{amount: number}],
}>();

const props = withDefaults(defineProps<{
  budgetDefinition: IBudgetDefinitionModel,
  isDisabled?: boolean,
}>(), {
  isDisabled: false,
});

const {budgetDefinition} = toRefs(props);
const amount = ref(budgetDefinition.value.DefaultBudgetAmount);

function handleSubmit() {
  emit("submit", {amount: amount.value});
}
</script>

<style lang="scss" scoped>
.BudgetForm__fieldset {
  display: flex;
  align-items: center;
}

.BudgetForm__button {
  --buttonColor: var(--colorGreen500);
}
</style>
