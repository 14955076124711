//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export interface Money extends IMoney
{
}
export interface IMoney
{
	Amount: number;
	Currency: Currency;
}
export interface INotifyAccountMerging
{
	EraUserId: string;
	PreferredAccountId?: string;
	AccountIds?: string[];
	IsSuccess: boolean;
	ErrorMessage?: string;
}
export interface INotifyAgentActivity
{
	ThreadId: string;
	UserId: string;
	ActivityCeased: boolean;
	DateCreated: any;
	IdReference?: string;
}
export interface INotifyBalanceAlert
{
	UserId: string;
	TotalBalance: number;
}
export interface INotifyBudgetThresholdExceeded
{
	EraUserId: string;
	BudgetInstanceId: string;
	BudgetName: string;
	TotalAmount: number;
	AmountSpent: number;
	AmountExceeded: number;
	Threshold: number;
	Type: BudgetThresholdType;
}
export interface INotifyInitialMxDataSynched
{
	EraUserId: string;
	BackgroundJobStatus: BackgroundJobStatus;
}
export interface INotifyMintCleanResult
{
	EraUserId: string;
	FileId: string;
	IsSuccess: boolean;
	CleanedAccountCount?: number;
	CleanedTransactionCount?: number;
	ErrorMessage?: string;
}
export interface INotifyMintImportResult
{
	EraUserId: string;
	FileId: string;
	FileName: string;
	IsSuccess: boolean;
	PossibleDuplicatesCount?: number;
	UniqueTransactionsCount?: number;
	ErrorMessage?: string;
}
export interface INotifyRoutineCreated
{
	UserId: string;
	Routine: IRoutineModel;
}
export interface INotifyRoutineFired
{
	UserId: string;
	Routine: IRoutineModel;
}
export interface INotifyRoutineStatusChanged
{
	UserId: string;
	Routine: IRoutineModel;
	OldStatus: RoutineStatus;
}
export interface INotifySubscriptionPlanChanged
{
	EraUserId: string;
	BackgroundJobStatus: BackgroundJobStatus;
	PlanKey: string;
}
export interface INotifySyncStatusChanged
{
	EraUserId: string;
	BackgroundJobStatus: BackgroundJobStatus;
	Type: BackgroundJobType;
}
export interface IFeatureLimitStatus
{
	Feature: FeatureLimitType;
	Limit: number;
	TimePeriod: FeatureLimitTimePeriod;
	UsageCount: number;
	StartAt?: any;
	EndAt?: any;
	Currency?: Currency;
	FeatureUniqueKey?: string;
}
export enum FeatureLimitTimePeriod {
	Always = "Always",
	Daily = "Daily",
	Weekly = "Weekly",
	Monthly = "Monthly"
}
export enum FeatureLimitType {
	ChatMessageCount = "ChatMessageCount",
	ChatCharacterCount = "ChatCharacterCount",
	FinancialAccountCount = "FinancialAccountCount",
	FundsTransferCount = "FundsTransferCount",
	FundsTransferAmount = "FundsTransferAmount",
	ActiveRoutineCount = "ActiveRoutineCount",
	PaidRoutineCount = "PaidRoutineCount"
}
export abstract class TimelineEntryFlag
{
	public static Pinned: string = `Pinned`;
	public static Viewed: string = `Viewed`;
	public static ReadOnly: string = `ReadOnly`;
	public static Routine: string = `Routine`;
	public static PushEligible: string = `PushEligible`;
	public static AccountConnectionProblem: string = `AccountConnectionProblem`;
	public static FundsTransfer: string = `FundsTransfer`;
}
export abstract class TimelineEntrySources
{
	public static User: string = `User`;
	public static System: string = `System`;
	public static RoutineMessage: string = `RoutineMessage`;
	public static FundsTransfer: string = `FundsTransfer`;
	public static System_NewsItem: string = `System:NewsItem`;
	public static System_PeriodicBrief: string = `System:PeriodicBrief`;
	public static Announcement: string = `Announcement`;
	public static AccountConnectionStatus: string = `AccountConnectionStatus`;
}
export enum TimelineEntryAudience {
	Private = "Private",
	Public = "Public",
	Followers = "Followers"
}
export enum TimelineEntryVisibilities {
	Visible = 0,
	HiddenSuperseded = 1,
	HiddenByUser = 2
}
export abstract class MessageSources
{
	public static User: string = `User`;
	public static System: string = `System`;
	public static Agent: string = `Agent`;
	public static SystemEphemeral: string = `SystemCourtesy`;
	public static SystemError: string = `SystemError`;
}
export enum AccountNicknameType {
	User = "User",
	MxLast4 = "MxLast4",
	MethodFiLast4 = "MethodFiLast4",
	Filename = "Filename",
	SnapTradeLast4 = "SnapTradeLast4"
}
export enum EnrichedTransactionStatus {
	None = "None",
	Pending = "Pending",
	Posted = "Posted",
	ReversePending = "ReversePending",
	ReversePosted = "ReversePosted"
}
export enum AccountScope {
	Normal = "Normal",
	Limited = "Limited"
}
export enum AccountType {
	Any = "Any",
	Checking = "Checking",
	Savings = "Savings",
	Loan = "Loan",
	AutoLoan = "AutoLoan",
	StudentLoan = "StudentLoan",
	CreditCard = "CreditCard",
	Investment = "Investment",
	LineOfCredit = "LineOfCredit",
	Mortgage = "Mortgage",
	Property = "Property",
	Cash = "Cash",
	Insurance = "Insurance",
	Prepaid = "Prepaid",
	CheckingLineOfCredit = "CheckingLineOfCredit"
}
export enum AssignmentRole {
	Original = "Original",
	Enriched = "Enriched",
	User = "User"
}
export enum TransactionTag {
	Subscription = "Subscription",
	DirectDeposit = "DirectDeposit",
	BillPay = "BillPay",
	Recurring = "Recurring",
	Income = "Income"
}
export interface ICategoryAssignment extends IAbstractRecord
{
	CategoryRole: AssignmentRole;
	Category: string;
	Comment?: string;
	AssignedByUserId?: string;
	AssignedDate: any;
	Percentage: number;
}
export interface IMerchantCategoryCodeAssignment extends IAbstractRecord
{
	AssignmentRole: AssignmentRole;
	MerchantCategoryCode: string;
	AssignedDate: any;
}
export interface INicknameAssignment extends IAbstractRecord
{
	Nickname: string;
	AssignedDate: any;
	NicknameType: AccountNicknameType;
}
export interface ITagAssignment extends IAbstractRecord
{
	AssignmentRole: AssignmentRole;
	Tag: TransactionTag;
	AssignedDate: any;
}
export interface ITransactionLineItem extends IAbstractRecord
{
	Amount: number;
	Notes?: string;
	CategoryAssignments: ICategoryAssignment[];
	GetPriorityCategoryAssignment() : ICategoryAssignment;
}
export interface IPersonalDetails extends IAbstractRecord
{
	PreferredNickname?: string;
	LegalFirstname?: string;
	LegalOtherNames?: string;
	PhoneNumber?: string;
	TimeZoneId?: string;
	DateOfBirth?: any;
	CountryCode?: string;
	DisplayLocale?: string;
	LLMLocale?: string;
	AutoDetectedIpInfo?: IAutoDetectedIpInfo;
}
export interface IAttachment
{
	Payloads?: IAttachmentPayload[];
	DisplayText: string;
	Url: string;
	Types: AttachmentTypes;
	ThumbnailUrl?: string;
}
export interface IAttachmentPayload extends IAbstractRecord
{
	TypeName: string;
	Data: any;
}
export enum AttachmentTypes {
	Image = "Image",
	Audio = "Audio",
	File = "File",
	Link = "Link",
	Chart = "Chart",
	Text = "Text",
	Object = "Object"
}
export interface IReference extends IAbstractRecord
{
	ReferenceType: ReferenceTypes;
	Value: string;
	CompareTo(other?: IReference) : number;
}
export enum ReferenceTypes {
	IngestedContent = "IngestedContent",
	AutomaticContentCluster = "AutomaticContentCluster",
	ClusterStateProjection = "ClusterStateProjection",
	Routine = "Routine",
	MxAccountProjection = "MxAccountProjection",
	ManualAccountProjection = "ManualAccountProjection",
	MxTransactionProjection = "MxTransactionProjection",
	ManualTransactionProjection = "ManualTransactionProjection",
	S3FileId = "S3FileId",
	MxMemberProjection = "MxMemberProjection",
	Announcement = "Announcement",
	MethodFiAccountProjection = "MethodFiAccountProjection",
	MethodFiTransactionProjection = "MethodFiTransactionProjection",
	FundsTransferProjection = "FundsTransferProjection",
	SnapTradeAccountProjection = "SnapTradeAccountProjection",
	SnapTradeTransactionProjection = "SnapTradeTransactionProjection"
}
export interface IAbstractImmutableClientModel extends IAbstractRecord
{
	IdReference?: string;
	DateCreated: any;
}
export interface IAbstractMutableClientModel extends IAbstractImmutableClientModel
{
	DateUpdated: any;
	DateDeleted?: any;
}
export enum FunctionOperation {
	Created = "Created",
	Read = "Read",
	Updated = "Updated",
	Deleted = "Deleted"
}
export interface IAbstractRecord
{
}
export enum ActivityType {
	Undefined = "Undefined",
	Buy = "Buy",
	Sell = "Sell",
	SellShort = "SellShort",
	OptionExpiration = "OptionExpiration",
	Split = "Split",
	AssetTransferredIn = "AssetTransferredIn",
	AssetMovedIn = "AssetMovedIn",
	AssetTransferredOut = "AssetTransferredOut",
	AssetMovedOut = "AssetMovedOut",
	Contribution = "Contribution",
	CashMovedIn = "CashMovedIn",
	Withdrawal = "Withdrawal",
	CashMovedOut = "CashMovedOut",
	Forex = "Forex",
	Dividend = "Dividend",
	Rebate = "Rebate",
	Interest = "Interest",
	RealEstateInterest = "RealEstateInterest",
	Fee = "Fee",
	CryptocurrencyFee = "CryptocurrencyFee",
	Tax = "Tax",
	GovGrant = "GovGrant",
	New = "New"
}
export enum SecurityType {
	Undefined = "Undefined",
	AmericanDepositaryReceipt = "AmericanDepositaryReceipt",
	Automobile = "Automobile",
	Bond = "Bond",
	CloseEndedFund = "CloseEndedFund",
	CommonStock = "CommonStock",
	Cryptocurrency = "Cryptocurrency",
	DelistedDefunct = "DelistedDefunct",
	ETF = "ETF",
	GlobalDepositaryReceipt = "GlobalDepositaryReceipt",
	OpenEndedFund = "OpenEndedFund",
	PreferredStock = "PreferredStock",
	RealEstate = "RealEstate",
	Right = "Right",
	StructuredProduct = "StructuredProduct",
	Temporary = "Temporary",
	Unit = "Unit",
	Warrant = "Warrant",
	New = "New"
}
export interface ISnapTradeAccount
{
	EraUserId: string;
	AccountId: string;
	AccountNumber: string;
	AccountName: string;
	Nickname?: string;
	MarginType?: string;
	BrokerageId: string;
	InstitutionName: string;
	AuthorizationId: string;
	BrokerageConnection?: ISnapTradeConnection;
	ConnectedDate: any;
	LastTxSyncDate?: any;
	TotalAccountValue: number;
	CashBalance: number;
	CurrencyCode: string;
	Positions: ISnapTradePosition[];
	Activities: ISnapTradeActivity[];
	BalanceHistory: ISnapTradeBalanceHistory[];
}
export interface ISnapTradeActivity
{
	EraUserId: string;
	AccountId: string;
	ActivityId: string;
	ActivityDate: any;
	SettlementDate: any;
	ActivityType: ActivityType;
	Description: string;
	SymbolId?: string;
	Symbol?: string;
	SecurityType?: SecurityType;
	Units: number;
	Price: number;
	TotalAmount: number;
	CurrencyCode?: string;
	Blurb: string;
}
export interface ISnapTradeApiStatus
{
	IsAvailable: boolean;
	StatusCode: number;
	SystemErrorMessage: string;
	ApiRequestId: string;
	BaseUrl: string;
	IsDefaultRetry: boolean;
	RetryOffset: number;
	LastCheck: any;
	Expires: any;
}
export interface ISnapTradeBalanceHistory
{
	BalanceDate: any;
	CashBalance?: number;
	InvestedValue?: number;
	TotalAccountValue?: number;
}
export interface ISnapTradeBrokerage
{
	BrokerageId: string;
	Slug: string;
	Name: string;
	BrokerType: string;
	Description: string;
	LogoUrl: string;
	IsUserOption: boolean;
}
export interface ISnapTradeConnection
{
	EraUserId: string;
	BrokerageId: string;
	AuthorizationId: string;
	IsReadonly: boolean;
	IsDisabled: boolean;
	DisabledDate?: any;
	Brokerage?: ISnapTradeBrokerage;
}
export interface ISnapTradeLoginUri
{
	LoginUri: string;
	SessionId: string;
}
export interface ISnapTradePosition
{
	EraUserId: string;
	AccountId: string;
	SymbolId: string;
	Symbol?: string;
	Description?: string;
	Type: SecurityType;
	TypeDescription?: string;
	Units: number;
	AvgUnitCost: number;
	LastPrice: number;
	CurrencyCode?: string;
	TotalPositionValue: number;
	OpenPnl: number;
	OpenPnlPct?: number;
}
export interface ISnapTradeUser
{
	EraUserId: string;
	IsRegistered: boolean;
	RegistrationDate: any;
	LastFullSyncDate?: any;
	TotalValueOfAccounts: number;
	BrokerageConnections: ISnapTradeConnection[];
	Accounts: ISnapTradeAccount[];
}
export interface IAddWebPushDeviceRequest
{
	Name?: string;
	PushEndpoint: string;
	PushP256DH: string;
	PushAuth: string;
}
export interface IWebPushDevice
{
	Id: string;
	Name?: string;
	PushEndpoint: string;
	PushP256DH: string;
	PushAuth: string;
	CreationTime: any;
}
export interface IClientReference
{
	ReferenceType: ReferenceTypes;
	Value: string;
	DisplayName: string;
	SourceName: string;
}
export interface IClientThreadEntry
{
	ThreadId: string;
	Reactions: string[];
	Text: string;
	Source: string;
	Attachments: IAttachment[];
	DateCreated: any;
	DateUpdated: any;
	DateDeleted?: any;
	IdReference?: string;
}
export interface IClientTimelineEntry
{
	ShareKey: string;
	Title: string;
	Subtitle?: string;
	Source: string;
	BodyAsMarkdown: string;
	Flags: string[];
	Tags: string[];
	ImageUrls: string[];
	CreditSources: string[];
	DateForDisplay?: any;
	Visibility: TimelineEntryVisibilities;
	References: IClientReference[];
	TitleCallouts: string[];
	SubtitleCallouts: string[];
	Attachments: IAttachment[];
	DateCreated: any;
	DateUpdated: any;
	DateDeleted?: any;
	IdReference?: string;
}
export enum InactiveReason {
	Expired = "Expired",
	Canceled = "Canceled",
	Suspended = "Suspended",
	FailedPayment = "FailedPayment"
}
export interface ICustomerPortalSessionLink
{
	Id: string;
	Url: string;
}
export interface IExpireTrialSubscriptionHandlerArgs extends IJsonRoutineArgs
{
	EraSubscriptionId: string;
}
export interface IPaymentSessionLink
{
	Id: string;
	Url: string;
}
export interface ISubscriptionAddon
{
	UniqueKey: string;
	Type: SubscriptionAddonType;
	Prices: ISubscriptionPrice[];
	Description?: string;
	DisplayName: string;
}
export interface ISubscriptionAddonBlob extends IAbstractRecord
{
	UniqueKey: string;
	Quantity: number;
	Type: SubscriptionAddonType;
}
export interface ISubscriptionPlan
{
	UniqueKey: string;
	Prices: ISubscriptionPrice[];
	Description?: string;
	DisplayName: string;
	Features: { [key:string]: any };
}
export interface ISubscriptionPrice
{
	UniqueKey: string;
	Period: SubscriptionPeriod;
	Cost: IMoney;
}
export interface IUserSubscriptionModel
{
	EraSubscriptionId: string;
	EraUserId: string;
	Description?: string;
	DisplayName: string;
	Period: SubscriptionPeriod;
	Status: SubscriptionStatus;
	PlanKey: string;
	StartDate: any;
	EndDate?: any;
	Addons: ISubscriptionAddonBlob[];
}
export enum SubscriptionAddonType {
	Routine = "Routine"
}
export enum SubscriptionPeriod {
	Unknown = "Unknown",
	Weekly = "Weekly",
	Monthly = "Monthly",
	Yearly = "Yearly",
	Unlimited = "Unlimited"
}
export enum SubscriptionProviderType {
	Stripe = "Stripe"
}
export enum SubscriptionStatus {
	Active = "Active",
	Inactive = "Inactive"
}
export enum RoutineSource {
	User = "User",
	System = "System"
}
export enum RoutineStatus {
	Disabled = "Disabled",
	Busy = "Busy",
	Idle = "Idle",
	ExecutionErrorWillRetry = "ExecutionErrorWillRetry",
	SchedulingErrorWillRetry = "SchedulingErrorWillRetry",
	InvalidCronExpressionError = "InvalidCronExpressionError",
	FinallyCompleted = "FinallyCompleted"
}
export enum RoutineTriggerType {
	Scheduled = "Scheduled",
	Event = "Event"
}
export interface IRoutineInterval
{
}
export interface ICronRoutineInterval extends IRoutineInterval
{
	CronExpression: string;
	NextFireTime?: any;
}
export interface IRecurrenceRoutineInterval extends IRoutineInterval
{
	Recurrence: any;
}
export enum FundsTransferConfirmationType {
	Unknown = "Unknown",
	ManualByUser = "ManualByUser",
	AutomaticByRoutine = "AutomaticByRoutine"
}
export enum FundsTransferState {
	Unknown = "Unknown",
	Requested = "Requested",
	Scheduled = "Scheduled",
	Queued = "Queued",
	Pending = "Pending",
	Completed = "Completed",
	Canceled = "Canceled",
	Failed = "Failed",
	Reversed = "Reversed",
	LimitExceeded = "LimitExceeded",
	PausedForRisk = "PausedForRisk",
	RejectedForRisk = "RejectedForRisk",
	FailedToStart = "FailedToStart"
}
export enum FundsTransferTrigger {
	Unknown = "Unknown",
	UserChat = "UserChat",
	Schedule = "Schedule",
	UserApiCall = "UserApiCall"
}
export interface IFinancialAccount
{
	EraUserId: string;
	AggregatedAt?: any;
	SuccessfullyAggregatedAt?: any;
	InstitutionName: string;
	IsOauth: boolean;
	AccountGuid: string;
	AccountNumberLast4?: string;
	Apr?: number;
	Apy?: number;
	AvailableBalance?: number;
	AvailableCredit?: number;
	Balance: number;
	CashBalance?: number;
	CashSurrenderValue?: number;
	MxCreatedAt: any;
	CreditLimit?: number;
	CurrencyCode?: string;
	DayPaymentIsDue?: number;
	DeathBenefit?: number;
	HoldingsValue?: number;
	ImportedAt?: any;
	InstitutionCode: string;
	InsuredName?: string;
	InterestRate?: number;
	IsClosed?: boolean;
	IsHidden?: boolean;
	LastPaymentAt?: any;
	LastPayment?: number;
	LoanAmount?: number;
	MaturesOn?: any;
	MemberGuid: string;
	MinimumBalance?: number;
	MinimumPayment?: number;
	Name: string;
	Nickname?: string;
	OriginalBalance?: number;
	PayOutAmount?: number;
	PaymentDueAt?: any;
	PayoffBalance?: number;
	PremiumAmount?: number;
	RoutingNumberLast4?: string;
	StartedOn?: any;
	Subtype: AccountSubtype;
	TotalAccountValue?: number;
	Type: MxAccountType;
	MxUpdatedAt: any;
	UserGuid: string;
	MoovBankAccountId?: string;
}
export interface IFinancialHolding
{
	HoldingGuid: string;
	EraUserId: string;
	AccountGuid: string;
	CostBasis?: number;
	MxCreatedAt: any;
	CurrencyCode?: string;
	Cusip?: string;
	DailyChange?: number;
	Description?: string;
	HoldingType?: HoldingType;
	MarketValue?: number;
	MemberGuid: string;
	Metadata?: string;
	PurchasePrice?: number;
	Shares?: number;
	Symbol?: string;
	MxUpdatedAt: any;
	UserGuid: string;
}
export interface IFinancialMember
{
	EraUserId: string;
	AggregatedAt?: any;
	BackgroundAggregationIsDisabled: boolean;
	ConnectionStatus: string;
	ConnectionStatusMessage?: string;
	MemberGuid: string;
	InstitutionCode: string;
	IsBeingAggregated: boolean;
	IsManagedByUser: boolean;
	IsOAuth: boolean;
	Metadata?: string;
	Name: string;
	SuccessfullyAggregatedAt?: any;
	UserGuid: string;
}
export interface IFinancialTransaction
{
	TransactionGuid: string;
	EraUserId: string;
	AccountGuid: string;
	Amount: number;
	Category?: string;
	CategoryGuid?: string;
	CheckNumberString?: string;
	MxCreatedAt: any;
	CurrencyCode?: string;
	Date: any;
	Description?: string;
	IsBillPay?: boolean;
	IsDirectDeposit?: boolean;
	IsExpense?: boolean;
	IsFee?: boolean;
	IsIncome?: boolean;
	IsInternational?: boolean;
	IsOverdraftFee?: boolean;
	IsPayrollAdvance?: boolean;
	IsRecurring?: boolean;
	IsSubscription?: boolean;
	Latitude?: number;
	LocalizedDescription?: string;
	LocalizedMemo?: string;
	Longitude?: number;
	MemberGuid: string;
	Memo?: string;
	MerchantCategoryCode?: number;
	MerchantGuid?: string;
	MerchantLocationGuid?: string;
	OriginalDescription?: string;
	PostedAt?: any;
	Status: MxTransactionStatus;
	TopLevelCategory?: string;
	TransactedAt?: any;
	Type: TransactionType;
	MxUpdatedAt: any;
	UserGuid: string;
	MerchantName?: string;
	MerchantLogoUrl?: string;
	MerchantWebsiteUrl?: string;
}
export enum AccountSubtype {
	None = "None",
	MoneyMarket = "MoneyMarket",
	CertificateOfDeposit = "CertificateOfDeposit",
	Auto = "Auto",
	Student = "Student",
	SmallBusiness = "SmallBusiness",
	Personal = "Personal",
	PersonalWithCollateral = "PersonalWithCollateral",
	HomeEquity = "HomeEquity",
	Plan401K = "Plan401K",
	Plan403B = "Plan403B",
	Plan529 = "Plan529",
	Ira = "Ira",
	RolloverIra = "RolloverIra",
	RothIra = "RothIra",
	Taxable = "Taxable",
	NonTaxable = "NonTaxable",
	Brokerage = "Brokerage",
	Trust = "Trust",
	UniformGiftsToMinorsAct = "UniformGiftsToMinorsAct",
	Plan457 = "Plan457",
	Pension = "Pension",
	EmployeeStockOwnershipPlan = "EmployeeStockOwnershipPlan",
	SimplifiedEmployeePension = "SimplifiedEmployeePension",
	SimpleIra = "SimpleIra",
	Boat = "Boat",
	Powersports = "Powersports",
	Rv = "Rv",
	Heloc = "Heloc",
	PlanRoth401K = "PlanRoth401K",
	FixedAnnuity = "FixedAnnuity",
	VariableAnnuity = "VariableAnnuity",
	VehicleInsurance = "VehicleInsurance",
	Disability = "Disability",
	Health = "Health",
	LongTermCare = "LongTermCare",
	PropertyAndCasualty = "PropertyAndCasualty",
	UniversalLife = "UniversalLife",
	TermLife = "TermLife",
	WholeLife = "WholeLife",
	AccidentalDeathAndDismemberment = "AccidentalDeathAndDismemberment",
	VariableUniversalLife = "VariableUniversalLife",
	Hsa = "Hsa",
	TaxFreeSavingsAccount = "TaxFreeSavingsAccount",
	Individual = "Individual",
	RegisteredRetirementIncomeFund = "RegisteredRetirementIncomeFund",
	CashManagementAccount = "CashManagementAccount",
	EmployeeStockPurchasePlan = "EmployeeStockPurchasePlan",
	RegisteredEducationSavingsPlan = "RegisteredEducationSavingsPlan",
	ProfitSharingPlan = "ProfitSharingPlan",
	UniformTransferToMinorsAct = "UniformTransferToMinorsAct",
	Plan401A = "Plan401A",
	SarsepIra = "SarsepIra",
	FixedAnnuityTraditionalIra = "FixedAnnuityTraditionalIra",
	VariableAnnuityTraditionalIra = "VariableAnnuityTraditionalIra",
	SeppIra = "SeppIra",
	InheritedTraditionalIra = "InheritedTraditionalIra",
	FixedAnnuityRothIra = "FixedAnnuityRothIra",
	VariableAnnuityRothIra = "VariableAnnuityRothIra",
	InheritedRothIra = "InheritedRothIra",
	Coverdell = "Coverdell",
	AdvisoryAccount = "AdvisoryAccount",
	BrokerageMargin = "BrokerageMargin",
	CharitableGiftAccount = "CharitableGiftAccount",
	ChurchAccount = "ChurchAccount",
	Conservatorship = "Conservatorship",
	Custodial = "Custodial",
	DefinedBenefitPlan = "DefinedBenefitPlan",
	DefinedContributionPlan = "DefinedContributionPlan",
	Educational = "Educational",
	Estate = "Estate",
	Executor = "Executor",
	GroupRetirementSavingsPlan = "GroupRetirementSavingsPlan",
	GuaranteedInvestmentCertificate = "GuaranteedInvestmentCertificate",
	Hra = "Hra",
	IndexedAnnuity = "IndexedAnnuity",
	InvestmentClub = "InvestmentClub",
	IrrevocableTrust = "IrrevocableTrust",
	JointTenantsByEntirity = "JointTenantsByEntirity",
	JointTenantsCommunityProperty = "JointTenantsCommunityProperty",
	JointTenantsInCommon = "JointTenantsInCommon",
	JointTenantsWithRightsOfSurvivorship = "JointTenantsWithRightsOfSurvivorship",
	KeoughPlan = "KeoughPlan",
	LifeIncomeFund = "LifeIncomeFund",
	LivingTrust = "LivingTrust",
	LockedInRetirementAccount = "LockedInRetirementAccount",
	LockedInRetirementInvestmentFund = "LockedInRetirementInvestmentFund",
	LockedInRetirementSavingsAccount = "LockedInRetirementSavingsAccount",
	MoneyPurchasePlan = "MoneyPurchasePlan",
	Partnership = "Partnership",
	Plan409A = "Plan409A",
	PlanRoth403B = "PlanRoth403B",
	RegisteredDisabilitySavingsPlan = "RegisteredDisabilitySavingsPlan",
	RegisteredLockedInSavingsPlan = "RegisteredLockedInSavingsPlan",
	RegisteredPensionPlan = "RegisteredPensionPlan",
	RegisteredRetirementSavingsPlan = "RegisteredRetirementSavingsPlan",
	RevocableTrust = "RevocableTrust",
	RothConversion = "RothConversion",
	SoleProprietorship = "SoleProprietorship",
	SpousalIra = "SpousalIra",
	SpousalRothIra = "SpousalRothIra",
	TestamentaryTrust = "TestamentaryTrust",
	ThriftSavingsPlan = "ThriftSavingsPlan",
	InheritedAnnuity = "InheritedAnnuity",
	CorporateAccount = "CorporateAccount",
	LimitedLiabilityAccount = "LimitedLiabilityAccount"
}
export enum HoldingType {
	UnknownType = "UnknownType",
	Equity = "Equity",
	ExchangeTradedFund = "ExchangeTradedFund",
	MoneyMarket = "MoneyMarket",
	MutualFund = "MutualFund",
	HedgeFund = "HedgeFund",
	Annuity = "Annuity",
	UnitInvestmentTrust = "UnitInvestmentTrust",
	Cash = "Cash",
	FixedIncome = "FixedIncome",
	Options = "Options",
	AlternativeInvestments = "AlternativeInvestments",
	CertificateOfDeposit = "CertificateOfDeposit",
	Loan = "Loan"
}
export enum MxAccountType {
	Any = "Any",
	Checking = "Checking",
	Savings = "Savings",
	Loan = "Loan",
	CreditCard = "CreditCard",
	Investment = "Investment",
	LineOfCredit = "LineOfCredit",
	Mortgage = "Mortgage",
	Property = "Property",
	Cash = "Cash",
	Insurance = "Insurance",
	Prepaid = "Prepaid",
	CheckingLineOfCredit = "CheckingLineOfCredit"
}
export enum MxTransactionStatus {
	None = "None",
	Pending = "Pending",
	Posted = "Posted"
}
export enum TransactionTopLevelCategory {
	AutoAndTransport = "Auto & Transport",
	BillsAndUtilities = "Bills & Utilities",
	BusinessServices = "Business Services",
	Education = "Education",
	Entertainment = "Entertainment",
	FeesAndCharges = "Fees & Charges",
	Financial = "Financial",
	FoodAndDining = "Food & Dining",
	GiftsAndDonations = "Gifts and Donations",
	HealthAndFitness = "Health & Fitness",
	Home = "Home",
	Income = "Income",
	Investment = "Investment",
	Kids = "Kids",
	PersonalCare = "Personal Care",
	Pets = "Pets",
	Shopping = "Shopping",
	Taxes = "Taxes",
	Transfer = "Transfer",
	Travel = "Travel",
	Uncategorized = "Uncategorized"
}
export enum TransactionType {
	Any = "Any",
	Credit = "Credit",
	Debit = "Debit"
}
export enum MessageChannel {
	Email = "Email",
	Sms = "Sms",
	Timeline = "Timeline"
}
export interface IBalanceSnapshot
{
	Balance?: number;
	BalanceType: BalanceType;
	CurrencyCode?: string;
}
export interface IPaginatedList<T>
{
	Items: T[];
	Pagination: IPagination;
}
export interface IPagination
{
	Page: number;
	RecordsPerPage: number;
	TotalRecords: number;
	TotalPages: number;
}
export interface ISublistAndTotalCount<T>
{
	TotalCount: number;
	Items: T[];
}
export enum SendOtpResponseStatus {
	ResendOtpCode = "ResendOtpCode",
	Sent = "Sent",
	PhoneNumberAlreadyVerified = "PhoneNumberAlreadyVerified",
	PhoneNumberNotFound = "PhoneNumberNotFound",
	Error = "Error"
}
export interface ISendOtpResult
{
	CanSendNow: boolean;
	Reason: SendOtpResponseStatus;
}
export interface IUserModel
{
	UserId: string;
	StytchUserId: string;
	FirstName: string;
	LastName: string;
	Email: string;
	Status: UserStatus;
	Roles: string[];
	CreatedAt: any;
	Subscription?: IUserSubscriptionModel;
	ListSubscriptions: string[];
	MxUserGuid?: string;
	MoovAccountId?: string;
	LastActiveAt?: any;
}
export enum UserStatus {
	WaitingForAccess = "WaitingForAccess",
	Active = "Active",
	Suspended = "Suspended",
	Deleted = "Deleted"
}
export interface IUserProfileModel
{
	Nickname?: string;
	FirstName?: string;
	OtherNames?: string;
	PhoneNumber?: string;
	VerifiedPhoneNumber?: string;
	UserTimeZoneId?: string;
	DateOfBirth?: any;
	CountryCode?: string;
	DisplayLocale?: string;
	LLMLocale?: string;
	AutoDetectedIpInfo?: IAutoDetectedIpInfo;
}
export interface IUserSettingsModel
{
	NewsfeedFilters: string[];
	IsSmsNotificationEnabled: boolean;
}
export interface ITimePeriodValue<T>
{
	Timestamp: any;
	Duration: any;
	Value: T;
}
export interface ITimeSeriesOf<TSummary, TData>
{
	Summary: TSummary;
	Data: ITimePeriodValue<TData>[];
	Overall?: TData;
}
export interface ITimeSeriesRangeOf<TSummary, TData>
{
	FromInclusive: any;
	ToInclusive: any;
	Series: ITimeSeriesOf<TSummary, TData>[];
}
export interface ITimelineEntryRequest
{
	Page: number;
	PerPage: number;
	SourcesFilter?: string[];
	TagsFilter?: string[];
	FromDateInUtc?: any;
	ToDateInUtc?: any;
}
export interface ICreateRoutineRequest
{
	RoutineType: string;
	RoutineName?: string;
	Interval: IRoutineInterval;
	Description?: string;
	JsonArgs: string;
}
export interface IJsonRoutineArgs
{
}
export interface IJsonRoutineArgsWithMessageChannels extends IJsonRoutineArgs
{
	IsPinned: boolean;
	SendPushNotification: boolean;
	Channels: MessageChannel[];
}
export interface IRoutineModel
{
	CronExpression: string;
	Interval: IRoutineInterval;
	RoutineId: string;
	ExecutionCount: number;
	Type: string;
	IsDisabled: boolean;
	Args?: IJsonRoutineArgs;
	Status: string;
	Source: string;
	SupportedChannels: MessageChannel[];
	NextFireTime?: any;
	LastFireTime?: any;
	DateCreated: any;
	Name?: string;
	Description?: string;
}
export interface IUpdateRoutineRequest
{
	RoutineId: string;
	Name?: string;
	Interval?: IRoutineInterval;
	Description?: string;
	Disable?: boolean;
	JsonArgs?: string;
}
export interface IApiResponse<TResult>
{
	RequestId: any;
	Status: ApiResponseStatus;
	Result?: TResult;
	ErrorType?: string;
	ErrorMessage?: string;
	HttpStatusCode?: number;
	Metadata?: any[];
}
export abstract class ErrorType
{
	public static Unknown: string = `unknown`;
	public static NotFound: string = `not_found`;
	public static Forbidden: string = `forbidden`;
	public static Unauthorized: string = `unauthorized`;
	public static InternalError: string = `internal_error`;
	public static ValidationError: string = `validation_error`;
	public static TimeoutError: string = `timeout_error`;
	public static BadRequest: string = `bad_request`;
	public static DuplicateEmail: string = `duplicate_email`;
	public static TooManyRequests: string = `too_many_requests`;
	public static InvalidSessionId: string = `invalid_session_id`;
	public static SessionNotFound: string = `session_not_found`;
	public static UserAlreadyExists: string = `user_already_exists`;
	public static UserDoesNotExist: string = `user_does_not_exist`;
	public static UserAlreadyHasAccess: string = `user_already_has_access`;
	public static UserAlreadyHasRole: string = `user_already_has_role`;
	public static UserDoesNotHaveRequiredRole: string = `user_does_not_have_required_role`;
	public static UserIsNotActive: string = `user_is_not_active`;
	public static UserDoesNotHaveRole: string = `user_does_not_have_role`;
	public static AdminCannotChangeOwnRoles: string = `admin_cannot_change_own_roles`;
	public static StorageError: string = `storage_error`;
	public static FeedbackDoesNotExist: string = `feedback_does_not_exist`;
	public static UserWithEmailAlreadyExist: string = `user_with_email_already_exist`;
	public static TransactionNotFound: string = `transaction_not_found`;
	public static InvalidCsvFile: string = `invalid_csv_file`;
	public static InvalidZipFile: string = `invalid_zip_file`;
	public static TransactionIsNotDuplicate: string = `transaction_is_not_duplicate`;
	public static BudgetDefinitionDoesNotExist: string = `budget_definition_does_not_exist`;
	public static FailedToUpsertBudgetDefinition: string = `failed_to_upsert_budget_definition`;
	public static FailedToChangeBudgetDefinitionStatus: string = `failed_to_change_budget_definition_status`;
	public static FileUploadFailed: string = `file_upload_failed`;
	public static InvalidFileId: string = `invalid_file_id`;
	public static BudgetInstanceAlreadyGenerated: string = `budget_instance_already_generated`;
	public static AccountNotFound: string = `account_not_found`;
	public static AlreadyExists: string = `already_exists`;
	public static InvalidState: string = `invalid_state`;
	public static UnsupportedOperation: string = `unsupported_operation`;
	public static TransferLimitsExceeded: string = `transfer_limits_exceeded`;
	public static TransferAccountHolderNameMismatch: string = `transfer_account_holder_name_mismatch`;
	public static TooManyNormalAccounts: string = `too_many_normal_accounts`;
	public static InvalidFileType: string = `invalid_file_type`;
	public static EnrichedTransactionNotFound: string = `enriched_transaction_not_found`;
	public static EnrichedTransactionAlreadyDeleted: string = `enriched_transaction_already_deleted`;
	public static SourceReferencesExceededTheLimit: string = `source_references_exceeded_the_limit`;
	public static EntityAlreadyExists: string = `entity_already_exists`;
	public static ChatLimitsExceeded: string = `chat_limits_exceeded`;
	public static InvalidArgument: string = `invalid_argument`;
	public static DailyChatCountLimitExceeded: string = `daily_chat_count_limit_exceeded`;
	public static ChatCharacterLimitExceeded: string = `chat_charactr_limit_exceeded`;
	public static SubscriptionNotFound: string = `subscription_not_found`;
	public static SubscriptionAlreadyExists: string = `subscription_already_exists`;
	public static SubscriptionAlreadyInactive: string = `subscription_already_inactive`;
	public static InvalidUrl: string = `invalid_url`;
	public static MaxActiveRoutinesReached: string = `max_active_routines_reached`;
	public static MissingTemplateKeys: string = `missing_template_keys`;
	public static UserIpAddressNotProvided: string = `user_ip_address_not_provided`;
	public static TimelineEntryNotFound: string = `timeline_entry_not_found`;
	public static InvalidKey: string = `invalid_key`;
	public static FeatureDisabled: string = `feature_disabled`;
	public static SubscriptionAddonNotFound: string = `subscription_addon_not_found`;
	public static EntityNotFound: string = `entity_not_found`;
}
export enum ApiResponseStatus {
	Error = "Error",
	Success = "Success"
}
export interface IMxMerchant
{
	CreatedAt: any;
	Guid: string;
	LogoUrl?: string;
	LogoUpdatedAt?: any;
	Name: string;
	UpdatedAt: any;
	WebsiteUrl?: string;
	ToModel(projection: any) : IMxMerchant;
}
export interface IMxMerchantLocation
{
	City: string;
	Country: string;
	CreatedAt: any;
	Guid: string;
	Latitude: number;
	Longitude: number;
	MerchantGuid: string;
	PhoneNumber: string;
	PostalCode: number;
	State: string;
	StreetAddress: string;
	UpdatedAt: any;
}
export interface IMxPagination
{
	CurrentPage: number;
	PerPage: number;
	TotalEntries: number;
	TotalPages: number;
}
export enum MxWidgetColorScheme {
	Light = "Light",
	Dark = "Dark"
}
export enum MxWidgetMode {
	Aggregation = "Aggregation",
	Verification = "Verification"
}
export interface IMxWidgetUrl
{
	Url: string;
}
export interface IMxWidgetUrlRequest
{
	DisableBackgroundAggregation?: boolean;
	IncludeTransactions?: boolean;
	MemberGuid?: string;
	IncludeIdentity?: boolean;
	Mode?: MxWidgetMode;
	ColorScheme?: MxWidgetColorScheme;
}
export interface ICreateAccountRequest
{
	TosToken?: string;
}
export interface IAssetDetail
{
	Name: string;
	Type: string;
	Market: string;
	Locale: string;
	Currency: string;
	IsActive: boolean;
	PrimaryExch: string;
	Url?: string;
	IsEtf: boolean;
	Description?: string;
	DisplaySymbol: string;
	CompositeFigi: string;
	Cik: string;
	ShareClassFigi: string;
	Country: string;
	SicCode?: string;
	SicDescription?: string;
}
export interface IAssetPrice
{
	Open: number;
	Close: number;
	High: number;
	Low: number;
	Volume: number;
	Vwap: number;
	PercentChange: number;
	AbsoluteChange: number;
	Typical: number;
}
export interface IAddUserToSubscriptionListRequest
{
	ListName: string;
}
export interface IAccountGroupAmount
{
	Amount: number;
}
export interface IAccountGroupSummary
{
	AffectedAccountsCount: number;
	AffectedTransactionsCount: number;
}
export interface IAccountSummary
{
	AccountId: string;
}
export interface IAccountAmount
{
	Amount: number;
}
export interface ITransactionGroupSummary
{
	AffectedTransactionsCount: number;
}
export interface ITransactionGroupAmount
{
	Amount: number;
}
export interface ITransactionLineItemDetails
{
	Amount: number;
	Notes?: string;
	CategoryAssignments: ICategoryAssigmentDetails[];
}
export interface ICategoryAssigmentDetails
{
	CategoryRole: string;
	Category: string;
	Comment?: string;
	AssignedByUserId?: string;
	AssignedDate: any;
	Percentage: number;
}
export interface ITransactionDetails
{
	AccountId: string;
	UserId: string;
	TransactionId: string;
	TransactionPostDate: any;
	Amount: number;
	Currency: string;
	Status: string;
	OriginalDescription?: string;
	EnrichedDescription?: string;
	Notes?: string;
	OverallCategoryAssignments: ICategoryAssigmentDetails[];
	LineItems: ITransactionLineItemDetails[];
}
export interface IFundsTransfer
{
	EraFundsTransferId: string;
	Amount: number;
	Currency: Currency;
	Description?: string;
	SourceEraUserId: string;
	SourceEraAccountId: string;
	DestinationEraUserId: string;
	DestinationEraAccountId: string;
	State: FundsTransferState;
	TriggeredBy: FundsTransferTrigger;
	RequestedAt: any;
	ConfirmedAt?: any;
	StartedAt?: any;
	FinishedAt?: any;
	EstimatedCompletedAt?: any;
	FailureReason?: string;
	ConfirmationType?: FundsTransferConfirmationType;
	ConfirmedByEraId?: string;
	RoutineId?: string;
}
export enum TransferEndpointType {
	Source = "Source",
	Destination = "Destination"
}
export interface IFileDetails
{
	Id: string;
	Name: string;
	Created: any;
	ContentLength: number;
}
export interface IFilesUploadResult
{
	Items: IFileUploadItem[];
}
export interface IFileUploadItem
{
	FileName: string;
	Id?: string;
	ErrorMessage?: string;
}
export enum AggregateSpan {
	Second = "Second",
	Minute = "Minute",
	FiveMinutes = "FiveMinutes",
	Hour = "Hour",
	Day = "Day",
	Week = "Week",
	Month = "Month",
	Quarter = "Quarter",
	Year = "Year"
}
export enum BalanceType {
	Current = "Current",
	Available = "Available",
	AvailableCredit = "AvailableCredit",
	CreditLimit = "CreditLimit"
}
export enum Currency {
	Invalid = "Invalid",
	ALL = "ALL",
	DZD = "DZD",
	ARS = "ARS",
	AUD = "AUD",
	BSD = "BSD",
	BHD = "BHD",
	BDT = "BDT",
	AMD = "AMD",
	BBD = "BBD",
	BMD = "BMD",
	BTN = "BTN",
	BOB = "BOB",
	BWP = "BWP",
	BZD = "BZD",
	SBD = "SBD",
	BND = "BND",
	MMK = "MMK",
	BIF = "BIF",
	KHR = "KHR",
	CAD = "CAD",
	CVE = "CVE",
	KYD = "KYD",
	LKR = "LKR",
	OldUsd = "OldUsd",
	CLP = "CLP",
	CNY = "CNY",
	COP = "COP",
	KMF = "KMF",
	CRC = "CRC",
	CUP = "CUP",
	CZK = "CZK",
	DKK = "DKK",
	DOP = "DOP",
	SVC = "SVC",
	ETB = "ETB",
	ERN = "ERN",
	FKP = "FKP",
	FJD = "FJD",
	DJF = "DJF",
	GMD = "GMD",
	GIP = "GIP",
	GTQ = "GTQ",
	GNF = "GNF",
	GYD = "GYD",
	HTG = "HTG",
	HNL = "HNL",
	HKD = "HKD",
	HUF = "HUF",
	ISK = "ISK",
	INR = "INR",
	IDR = "IDR",
	IRR = "IRR",
	IQD = "IQD",
	ILS = "ILS",
	JMD = "JMD",
	JPY = "JPY",
	KZT = "KZT",
	JOD = "JOD",
	KES = "KES",
	KPW = "KPW",
	KRW = "KRW",
	KWD = "KWD",
	KGS = "KGS",
	LAK = "LAK",
	LBP = "LBP",
	LSL = "LSL",
	LRD = "LRD",
	LYD = "LYD",
	MOP = "MOP",
	MWK = "MWK",
	MYR = "MYR",
	MVR = "MVR",
	MUR = "MUR",
	MXN = "MXN",
	MNT = "MNT",
	MDL = "MDL",
	MAD = "MAD",
	OMR = "OMR",
	NAD = "NAD",
	NPR = "NPR",
	ANG = "ANG",
	AWG = "AWG",
	VUV = "VUV",
	NZD = "NZD",
	NIO = "NIO",
	NGN = "NGN",
	NOK = "NOK",
	PKR = "PKR",
	PAB = "PAB",
	PGK = "PGK",
	PYG = "PYG",
	PEN = "PEN",
	PHP = "PHP",
	QAR = "QAR",
	RUB = "RUB",
	RWF = "RWF",
	SHP = "SHP",
	SAR = "SAR",
	SCR = "SCR",
	SLL = "SLL",
	SGD = "SGD",
	VND = "VND",
	SOS = "SOS",
	ZAR = "ZAR",
	SSP = "SSP",
	SZL = "SZL",
	SEK = "SEK",
	CHF = "CHF",
	SYP = "SYP",
	THB = "THB",
	TOP = "TOP",
	TTD = "TTD",
	AED = "AED",
	TND = "TND",
	UGX = "UGX",
	MKD = "MKD",
	EGP = "EGP",
	GBP = "GBP",
	TZS = "TZS",
	USD = "USD",
	UYU = "UYU",
	UZS = "UZS",
	WST = "WST",
	YER = "YER",
	TWD = "TWD",
	SLE = "SLE",
	VED = "VED",
	UYW = "UYW",
	VES = "VES",
	MRU = "MRU",
	STN = "STN",
	ZWL = "ZWL",
	BYN = "BYN",
	TMT = "TMT",
	GHS = "GHS",
	SDG = "SDG",
	UYI = "UYI",
	RSD = "RSD",
	MZN = "MZN",
	AZN = "AZN",
	RON = "RON",
	CHE = "CHE",
	CHW = "CHW",
	TRY = "TRY",
	XAF = "XAF",
	XCD = "XCD",
	XOF = "XOF",
	XPF = "XPF",
	XBA = "XBA",
	XBB = "XBB",
	XBC = "XBC",
	XBD = "XBD",
	XAU = "XAU",
	XDR = "XDR",
	XAG = "XAG",
	XPT = "XPT",
	XTS = "XTS",
	XPD = "XPD",
	XUA = "XUA",
	ZMW = "ZMW",
	SRD = "SRD",
	MGA = "MGA",
	COU = "COU",
	AFN = "AFN",
	TJS = "TJS",
	AOA = "AOA",
	BGN = "BGN",
	CDF = "CDF",
	BAM = "BAM",
	EUR = "EUR",
	MXV = "MXV",
	UAH = "UAH",
	GEL = "GEL",
	BOV = "BOV",
	PLN = "PLN",
	BRL = "BRL",
	CLF = "CLF",
	XSU = "XSU",
	USN = "USN",
	XXX = "XXX"
}
export interface IEraCapabilities
{
	EraUserId: string;
	UserCapabilities: IUserCapabilityStatus[];
	AccountCapabilities: IFinancialAccountCapabilityStatus[];
}
export enum FinancialAccountCapabilityRequirement {
	AccountVerification = "AccountVerification",
	MoovConnection = "MoovConnection"
}
export interface IFinancialAccountCapabilityStatus
{
	EraAccountId: string;
	CapabilityType: FinancialAccountCapabilityType;
	IsEnabled: boolean;
	UnmetRequirements: FinancialAccountCapabilityRequirement[];
}
export enum FinancialAccountCapabilityType {
	FundsTransfer = "FundsTransfer"
}
export enum UserCapabilityRequirement {
	FullLegalName = "FullLegalName",
	MoovUserAccount = "MoovUserAccount"
}
export interface IUserCapabilityStatus
{
	CapabilityType: UserCapabilityType;
	IsEnabled: boolean;
	UnmetRequirements: UserCapabilityRequirement[];
}
export enum UserCapabilityType {
	FundsTransfer = "FundsTransfer"
}
export interface IAuthenticatedSession
{
	Session: any;
	User: any;
	SessionToken: string;
	SessionJwt: string;
}
export interface IManualTransactionModel
{
	EraUserId: string;
	EraId: string;
	PostedDate: any;
	Description: string;
	AmountMinorUnits: number;
	TransactionType: TransactionType;
	Category: string;
	Account: string;
	Currency: Currency;
	Duplication: number;
	AdditionalData: { [key:string]: string };
}
export interface IGetManualTransactionsCommand extends IPaginatedCommand
{
	Duplication?: number;
}
export interface IUpdateManualTransactionDuplicationCommand
{
	TransactionId: string;
	IsDuplicate: boolean;
}
export interface IMethodFiEntityModel
{
	MethodFiEntityId: string;
	EraUserId: string;
	FirstName?: string;
	LastName?: string;
	Email?: string;
	Phone?: string;
	Dob?: string;
	Capabilities: string[];
	Status: MethodFiEntityStatus;
}
export enum MethodFiEntityStatus {
	Active = "Active",
	Incomplete = "Incomplete",
	Disabled = "Disabled"
}
export interface IAutoDetectedIpInfo extends IAbstractRecord
{
	CountryCode: string;
	Region: string;
	RegionCode: string;
	City: string;
	IsEu: boolean;
	Security?: ISecurityInfo;
}
export interface ISecurityInfo extends IAbstractRecord
{
	Anonymous: boolean;
	Proxy: boolean;
	Vpn: boolean;
	Tor: boolean;
	Hosting: boolean;
}
export interface IFollowerAdded
{
	FollowerEraUserId: string;
	FollowingEraUserId: string;
}
export interface IFollowerRemoved
{
	FollowerEraUserId: string;
	FollowingEraUserId: string;
}
export interface IEnrichedAccountModel
{
	EraUserId: string;
	EraAccountId: string;
	Type: AccountType;
	Nickname: string;
	Balance: number;
	Balances: IBalanceModel[];
	Scope: AccountScope;
	DisplayName: string;
	InstitutionName: string;
	SyncedAt?: any;
	SourceReferences: IReference[];
	OverallNicknameAssignments: INicknameAssignment[];
	SyncedAtWithSources: { [key in ReferenceTypes]: any };
}
export interface IBalanceModel
{
	BalanceType: BalanceType;
	Amount?: number;
}
export interface IEnrichedTransactionModel
{
	EraUserId: string;
	EraTransactionId: string;
	EraAccountId: string;
	SourceReferences: IReference[];
	TransactionPostedDate: any;
	SyncedAt?: any;
	Amount: number;
	Currency: Currency;
	Type: TransactionType;
	PriorityCategory: string;
	Status: EnrichedTransactionStatus;
	OriginalDescription?: string;
	EnrichedDescription?: string;
	Notes?: string;
	OverallCategoryAssignments: ICategoryAssignment[];
	LineItems: ITransactionLineItem[];
}
export interface IGetEnrichedAccountsCommand
{
}
export interface IGetEnrichedTransactionsCommand extends IPaginatedCommand
{
	AccountIds?: string[];
	Categories?: string[];
	TransactionIds?: string[];
	FromInclusiveUtc?: any;
	ToInclusiveUtc?: any;
}
export interface IPaginatedCommand
{
	Page: number;
	RecordsPerPage: number;
	Skip: number;
	Take: number;
}
export interface IBudgetDefinitionModel
{
	EraId: string;
	EraUserId: string;
	Name: string;
	Group?: string;
	ParentBudgetDefinitionId?: string;
	Status: BudgetStatus;
	Period: BudgetPeriod;
	RecurringEndDate?: any;
	DefaultBudgetAmount: number;
	AssignedTransactionCategories: string[];
}
export interface IBudgetInstanceModel
{
	EraId: string;
	Name: string;
	BudgetDefinitionId: string;
	Period: BudgetPeriod;
	StartDate: any;
	EndDate: any;
	Amount: number;
	AmountRemaining: number;
	AmountSpent: number;
	Group?: string;
	AssignedTransactionCategories: string[];
}
export enum BudgetPeriod {
	Weekly = "Weekly",
	Monthly = "Monthly",
	Yearly = "Yearly"
}
export enum BudgetRemainingDestination {
	None = "None",
	RollOver = "RollOver"
}
export enum BudgetStatus {
	Active = "Active",
	Inactive = "Inactive"
}
export enum BudgetThresholdType {
	Warning = "Warning",
	Critical = "Critical"
}
export interface IGetBudgetDefinitionsCommand extends IPaginatedCommand
{
}
export interface IGetBudgetInstancesCommand extends IPaginatedCommand
{
	FromDate?: any;
	ToDate?: any;
	Group?: string;
}
export interface IUpsertBudgetDefinitionCommand
{
	BudgetId?: string;
	Name: string;
	Group?: string;
	ParentBudgetDefinitionId?: string;
	Period: BudgetPeriod;
	RecurringEndDate?: any;
	DefaultAmount: number;
	AssignedTransactionCategories: string[];
	IsUpdateAllInstances: boolean;
	RollOverStartDate?: any;
	BudgetRemainingDestination: BudgetRemainingDestination;
}
export interface IBackgroundStatusJobRecord
{
	Type: BackgroundJobType;
	Status: BackgroundJobStatus;
	LastAttemptedDate?: any;
	LastProcessedDate?: any;
}
export enum BackgroundJobStatus {
	NotStarted = "NotStarted",
	InProgress = "InProgress",
	Completed = "Completed"
}
export enum BackgroundJobType {
	BudgetsSync = "BudgetsSync",
	EnrichedAccountsSync = "EnrichedAccountsSync",
	EnrichedTransactionsSync = "EnrichedTransactionsSync"
}
export abstract class FullStackConstants
{
	public static StytchSessionDurationMinutes: number = 4320;
	public static DevStytchSessionDurationMinutes: number = 1440;
}
export abstract class RoutineTypes
{
	public static UserMessage: string = `UserMessage`;
	public static UserSpending: string = `UserSpending`;
	public static UserFinances: string = `UserFinances`;
	public static UserFundsTransfer: string = `UserFundsTransfer`;
	public static UserFixedAmountFundsTransfer: string = `UserFixedAmountFundsTransfer`;
	public static UserPercentageBasedFundsTransfer: string = `UserPercentageBasedFundsTransfer`;
	public static UserThresholdBasedFundsTransfer: string = `UserThresholdBasedFundsTransfer`;
	public static UserRoundUpSavingsFundsTransfer: string = `UserRoundUpSavingsFundsTransfer`;
	public static UserPersonalSpends: string = `PeriodicPersonalSpendHandler`;
	public static UserEmailNotification: string = `UserEmailNotification`;
}
export abstract class Roles
{
	public static All: string[];
	public static User: string = `User`;
	public static Admin: string = `Admin`;
	public static Employee: string = `Employee`;
}
export abstract class UserFeatureFlags
{
	public static MaxActiveRoutines: string = `MaxActiveRoutines`;
	public static MaxConnectedAccounts: string = `MaxConnectedAccounts`;
	public static MaxDailyChatMessages: string = `MaxDailyChatMessages`;
	public static MaxChatCharacterCount: string = `MaxChatCharacterCount`;
	public static MaxCustomBudgets: string = `MaxCustomBudgets`;
	public static MaxCustomRoutines: string = `MaxCustomRoutines`;
	public static PayrollConnectionIncluded: string = `PayrollConnectionIncluded`;
	public static CustomCategoriesIncluded: string = `CustomCategoriesIncluded`;
	public static FundsTransfersEnabled: string = `FundsTransfersEnabled`;
	public static FixedAmountFundsTransferRoutinesEnabled: string = `FixedAmountFundsTransferRoutinesEnabled`;
	public static FundsTransferRoutinesEnabled: string = `FundsTransferRoutinesEnabled`;
	public static MinAmountPerTransfer: string = `MinAmountPerTransferInclusive`;
	public static MaxAmountPerTransfer: string = `MaxAmountPerTransferInclusive`;
	public static MaxDailyTransferAmount: string = `MaxDailyTransferAmountInclusive`;
	public static MaxWeeklyTransferAmount: string = `MaxWeeklyTransferAmountInclusive`;
	public static MaxMonthlyTransferAmount: string = `MaxMonthlyTransferAmountInclusive`;
	public static MaxDailyTransfers: string = `MaxDailyTransfers`;
	public static MaxWeeklyTransfers: string = `MaxWeeklyTransfers`;
	public static MaxMonthlyTransfers: string = `MaxMonthlyTransfers`;
}
export interface ISchedulerApiTimelinePayload extends IAbstractRecord
{
	FunctionOperation: FunctionOperation;
	RoutineIds: string[];
}
export interface IRoutineUserMessageChannelArgs extends IJsonRoutineArgsWithMessageChannels
{
	MessageText: string;
}
export interface IRoutineWeeklySpendingArgs extends IJsonRoutineArgsWithMessageChannels
{
	EmailTemplateName?: string;
}
export interface IAbstractFundsTransferRoutineArgs extends IJsonRoutineArgsWithMessageChannels
{
	SourceEraAccountId: string;
	DestinationEraAccountId: string;
	InitiationType: AutomatedTransferInitiationType;
	SourceAccountMinimumBalance?: Money;
	TransferDescription?: string;
}
export enum AutomatedTransferInitiationType {
	Manual = "Manual",
	AutomaticAfterReviewWindow = "AutomaticAfterReviewWindow",
	FullyAutomated = "FullyAutomated"
}
export interface IFixedAmountFundsTransferRoutineArgs extends IAbstractFundsTransferRoutineArgs
{
	FixedAmount: Money;
}
export interface IPercentageBasedFundsTransferRoutineArgs extends IAbstractFundsTransferRoutineArgs
{
	RatioOfSourceBalance: number;
	RatioAppliesTo: PercentageBasedFundsTransferRoutineTarget;
}
export enum PercentageBasedFundsTransferRoutineTarget {
	AccountBalance = "AccountBalance",
	Income = "Income"
}
export interface IRoundUpSavingsFundsTransferRoutineArgs extends IAbstractFundsTransferRoutineArgs
{
	TransactionCategories: string[];
	RoundUpIncrement: number;
}
export interface IThresholdBasedFundsTransferRoutineArgs extends IAbstractFundsTransferRoutineArgs
{
	Target: TransferEndpointType;
	TargetBalance: Money;
}
export interface IPeriodicPersonalSpendRoutineArgs extends IJsonRoutineArgsWithMessageChannels
{
	Categories: string[];
	MessageText: string;
	Title: string;
	Subtitle: string;
	EmailTemplateName?: string;
	Period: AggregateSpan;
}
export interface IRoutineMoneyArgs extends IJsonRoutineArgsWithMessageChannels
{
	IncludeAccountBalances: boolean;
	IncludeTopTransactions: boolean;
	IncludeNews: boolean;
	EmailTemplateName?: string;
}
export interface IWeeklyFinancialSummary extends IAbstractRecord
{
	IncludeNews: boolean;
	IncludeTopTransactions: boolean;
	IncludeAccountBalances: boolean;
	IsAnyConnectedAccounts: boolean;
	IsOnFreeTrial: boolean;
	ConnectAccountsLink?: string;
	RoutinesTabLink?: string;
	EraAppLink?: string;
	BudgetsLink?: string;
	TotalSpent?: IMoney;
	TotalSpentFormatted?: string;
	News?: { [key:string]: string };
	BudgetSummaries?: IBudgetSummary[];
	NormalAccountSummaries?: IAccountSummary[];
	LimitedAccountSummaries?: IAccountSummary[];
	TopTransactions?: ITransactionSummary[];
	IsAnyTransfers: boolean;
	TransferSummaries?: { [key in FundsTransferState]: ITransferSummary };
}
export interface IBudgetSummary extends IAbstractRecord
{
	EndDate: any;
	EndDateFormatted: string;
	BudgetName: string;
	SpentAmount: IMoney;
	SpentAmountFormatted: string;
	SpentPercentage: number;
	SpentPercentageFormatted: string;
	RemainingAmount: IMoney;
	RemainingAmountFormatted: string;
	RemainingPercentage: number;
	RemainingPercentageFormatted: string;
}
export interface IAccountSummary extends IAbstractRecord
{
	AccountName: string;
	Balance: IMoney;
	BalanceFormatted: string;
}
export interface ITransactionSummary extends IAbstractRecord
{
	Description: string;
	Amount: IMoney;
	AmountFormatted: string;
}
export interface ITransferSummary extends IAbstractRecord
{
	State: FundsTransferState;
	TransferCount: number;
	HasTransfers: boolean;
	TransferDetails: ITransferDetail[];
}
export interface ITransferDetail extends IAbstractRecord
{
	TransferDescription: string;
	TransferRoutineTitle?: string;
	TransferRoutineLink?: string;
}
export interface IPeriodicPersonalSpendSummaryData extends IAbstractRecord
{
	Categories: string[];
	TotalSpend: IMoney;
	TotalSpendFormatted?: string;
	PeriodStartFormatted?: string;
	PeriodEndFormatted?: string;
	FormattedCategories: { [key:string]: string[] };
	PeriodStart: any;
	PeriodEnd: any;
}
export interface IPersonalSpendSummaryComparison extends IAbstractRecord
{
	PriorPeriod?: IPeriodicPersonalSpendSummaryData;
	CurrentPeriod: IPeriodicPersonalSpendSummaryData;
	RoutinesTabLink?: string;
	EraAppLink?: string;
}
export interface IBudgetInstanceGeneratorRoutineArgs extends IJsonRoutineArgs
{
	BudgetDefinitionId: string;
}
export interface IBudgetThresholdExceededHandlerArgs extends IJsonRoutineArgs
{
	BudgetDefinitionIds: string[];
	NotificationThreshold: number;
	Type: BudgetThresholdType;
}
export interface IMergeAccountsCommand
{
	AccountIds: string[];
	PreferredAccountId?: string;
}
export interface IGetPaginatedListRequest
{
	Page: number;
	RecordsPerPage: number;
	Skip: number;
	Take: number;
}
export interface IUpdateEnrichedAccountNicknameRequest
{
	EraAccountId: string;
	Nickname: string;
}
export interface IUpdateEnrichedAccountScopeRequest
{
	EraAccountId: string;
	Body: IUpdateEnrichedAccountScopeRequestBody;
}
export interface IUpdateEnrichedAccountScopeRequestBody
{
	Scope: AccountScope;
}
/** A reaction to a message sent from the client to the server. */
export interface IClientMessageReactionRequest
{
	TimelineKey: string;
	MessageGuid: any;
	Reaction: string;
	Remove: boolean;
}
/** A message sent from the client to the server. */
export interface IClientMessageRequest
{
	TimelineKey: string;
	ClientMessageGuid: any;
	Text: string;
}
export interface IMxFinancialAccountsChanged
{
	UserId: string;
	Accounts: IFinancialAccount[];
}
export interface IMxFinancialTransactionsChanged
{
	UserId: string;
	Count: number;
}
export interface ISetTimelineEntryAudienceRequest
{
	TimelineKey: string;
	Audience: TimelineEntryAudience;
}
/** This set of constants primarily exists for easy transpiling to TypeScript for the client. */
export abstract class ClientConstants
{
	public static ReceiveMessageFromServer: string = `ReceiveMessageFromServer`;
	public static ReceiveTimelineEntryFromServer: string = `ReceiveTimelineEntryFromServer`;
	public static ReceiveAgentActivity: string = `ReceiveAgentActivity`;
	public static ReceiveBalanceAlert: string = `ReceiveBalanceAlert`;
	public static ReceiveScheduledTaskFiredFromServer: string = `ReceiveScheduledTaskFiredFromServer`;
	public static ReceiveRoutineCreatedFromServer: string = `ReceiveRoutineCreatedFromServer`;
	public static ReceiveRoutineStatusChangedFromServer: string = `ReceiveRoutineStatusChangedFromServer`;
	public static ReceiveMxAccountUpdateFromServer: string = `ReceiveMxAccountUpdateFromServer`;
	public static ReceiveMxTransactionUpdateFromServer: string = `ReceiveMxTransactionUpdateFromServer`;
	public static ReceiveAccountMergeNotificationFromServer: string = `ReceiveAccountMergeNotificationFromServer`;
	public static ReceiveMintImportResultFromServer: string = `ReceiveMintImportResultFromServer`;
	public static ReceiveMintCleanResultFromServer: string = `ReceiveMintCleanResultFromServer`;
	public static ReceiveBudgetThresholdExceeded: string = `ReceiveBudgetThresholdExceeded`;
	public static ReceiveEnrichedSynchedFromServer: string = `ReceiveEnrichedSynchedFromServer`;
	public static ReceiveInitialMxDataSynchedFromServer: string = `ReceiveInitialMxDataSynchedFromServer`;
	public static ReceiveChangeSubscriptionPlanFromServer: string = `ReceiveChangeSubscriptionPlanFromServer`;
	public static ReceiveFollowNotificationFromServer: string = `ReceiveFollowNotificationFromServer`;
	public static ReceiveUnFollowNotificationFromServer: string = `ReceiveUnFollowNotificationFromServer`;
}
/** This set of constants primarily exists for easy transpiling to TypeScript for the client. */
export abstract class Constants
{
	public static HubRoute: string = `/streams/message-thread`;
	public static ReceiveClientMessage: string = `ReceiveClientTextMessage`;
	public static GetMessagesForUser: string = `GetMessagesForUser`;
	public static GetTimelineEntries: string = `GetTimelineEntries`;
	public static GetTimelineEntry: string = `GetTimelineEntry`;
	public static PinTimelineEntry: string = `PinTimelineEntry`;
	public static UnpinTimelineEntry: string = `UnpinTimelineEntry`;
	public static MarkTimelineEntryViewed: string = `MarkTimelineEntryViewed`;
	public static MarkTimelineEntryUnviewed: string = `MarkTimelineEntryUnviewed`;
	public static CreateTimelineEntry: string = `CreateTimelineEntry`;
	public static ReceiveClientTextMessageReaction: string = `ReceiveClientTextMessageReaction`;
	public static GetProfile: string = `GetUserProfileAsync`;
	public static UpdateProfile: string = `UpdateUserProfileAsync`;
	public static GetSettings: string = `GetUserSettingsAsync`;
	public static UpdateSettings: string = `UpdateUserSettingsAsync`;
	public static FindTimelineEntries: string = `FindTimelineEntries`;
	public static SetTimelineEntryAudience: string = `SetTimelineEntryAudience`;
}
export interface IEditFundsTransferRequestBody
{
	Amount: number;
	SourceEraAccountId: string;
	DestinationEraAccountId: string;
	Description?: string;
}
export interface IPrepareFundsTransferRequest
{
	Amount: number;
	SourceEraAccountId: string;
	DestinationEraAccountId: string;
	Description?: string;
}
