<template>
  <Form
    class="EmailFormView"
    :isDisabled="status === 'Submitting'"
    @submit="handleSubmit"
  >
    <FormField
      class="EmailFormView__emailField"
      label="Email"
    >
      <TextInput
        v-model="formData.email"
        type="email"
        required
      />
    </FormField>

    <FormButton
      class="EmailFormView__submitButton"
      type="submit"
      v-bind="{status}"
    >
      <span v-if="status === 'Submitting'">
        Submitting…
      </span>
      <span v-else-if="status === 'Success'">
        Email sent! Check your inbox.
      </span>
      <span v-else-if="status === 'Error'">
        Error occurred. Try again.
      </span>
      <span v-else>
        Sign in with email
      </span>
    </FormButton>
  </Form>
</template>

<script lang="ts" setup>
import Form from "@cosine/components/Form.vue";
import FormButton from "@cosine/components/FormButton.vue";
import FormField from "@cosine/components/FormField.vue";
import TextInput from "@cosine/components/TextInput.vue";
import useAuthConfig from "@cosine/composables/useAuthConfig";
import useStytchStore from "@cosine/stores/useStytchStore";
import { ref } from "vue";

const { stytchClient } = useStytchStore();
const {
  authenticateUrl,
  authExpirationMinuteCount,
} = useAuthConfig();

type FormStatus = "Idle" | "Submitting" | "Success" | "Error"
const status = ref<FormStatus>("Idle");
const formData = {
  email: "",
};

async function handleSubmit() {
  status.value = "Submitting";

  try {
    await stytchClient.magicLinks.email.loginOrCreate(formData.email, {
      login_magic_link_url: authenticateUrl,
      login_expiration_minutes: authExpirationMinuteCount,
      signup_magic_link_url: authenticateUrl,
      signup_expiration_minutes: authExpirationMinuteCount,
    });

    status.value = "Success";
  } catch {
    status.value = "Error";
  }
}
</script>

<style lang="scss" scoped>
.EmailFormView__submitButton {
  margin-top: 16px;
}
</style>
