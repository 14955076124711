<template>
  <section class="ChannelFieldset">
    <FormFieldset
      label="Notification method"
    >
      <CheckboxField
        v-if="supportedChannels.includes(MessageChannel.Timeline)"
        title="Timeline"
        :modelValue="modelValue.includes(MessageChannel.Timeline)"
        @update:modelValue="handleCheck(MessageChannel.Timeline, $event)"
      />
      <CheckboxField
        v-if="supportedChannels.includes(MessageChannel.Email)"
        title="Email"
        :subtitle="email"
        :modelValue="modelValue.includes(MessageChannel.Email)"
        @update:modelValue="handleCheck(MessageChannel.Email, $event)"
      />
      <CheckboxField
        v-if="supportedChannels.includes(MessageChannel.Sms)"
        title="SMS"
        :subtitle="phoneNumber"
        :modelValue="modelValue.includes(MessageChannel.Sms)"
        @update:modelValue="handleCheck(MessageChannel.Sms, $event)"
      />
    </FormFieldset>
  </section>
</template>

<script lang="ts" setup>
import FormFieldset from "@cosine/components/FormFieldset.vue";
import { MessageChannel } from "@cosine/types/api-models";
import CheckboxField from "@cosine/views/CheckboxField.vue";
import { toRefs } from "vue";

const emit = defineEmits<{
  "update:modelValue": [Array<MessageChannel>],
}>();

const props = defineProps<{
  modelValue: Array<MessageChannel>,
  supportedChannels: Array<MessageChannel>,
  email?: string,
  phoneNumber?: string,
}>();

const {modelValue} = toRefs(props);

function handleCheck(channel: MessageChannel, isChecked: boolean) {
  const updatedChannels = [...modelValue.value];

  if (isChecked && !updatedChannels.includes(channel)) {
    updatedChannels.push(channel);
  } else if (!isChecked && updatedChannels.includes(channel)) {
    updatedChannels.splice(updatedChannels.indexOf(channel), 1);
  }

  emit("update:modelValue", updatedChannels);
}
</script>

<style lang="scss" scoped>
.ChannelFieldset {
  padding: 24px var(--layoutMargin);
  outline: 1px solid var(--colorSwissGrey100);
}
</style>
