<template>
  <section
    class="ChatLimitBanner"
    :class="{
      [`ChatLimitBanner--status${modelValue.UsageCount < modelValue.Limit ? 'Neutral' : 'Negative'}`]: true
    }"
  >
    <UIText
      class="ChatLimitBanner__label"
      tag="p"
      size="2XSmall"
      weight="Medium"
    >
      <Icon
        class="ChatLimitBanner__icon"
        :name="modelValue.UsageCount < modelValue.Limit ? 'ExclamationDiamond' : 'XOctogon'"
      /> {{ formattedLabel }}
    </UIText>

    <CtaButton
      v-if="canUpgrade"
      class="ChatLimitBanner__button"
      @click="$emit('upgrade')"
    >Upgrade</CtaButton>
  </section>
</template>

<script lang="ts" setup>
import CtaButton from "@cosine/components/CtaButton.vue";
import Icon from "@cosine/components/Icon.vue";
import UIText from "@cosine/components/UIText.vue";
import { IFeatureLimitStatus } from "@cosine/types/api-models";
import { computed, toRefs } from "vue";

defineEmits<{
  upgrade: [],
}>();

const props = withDefaults(defineProps<{
  modelValue: IFeatureLimitStatus,
  canUpgrade?: boolean,
}>(), {
  canUpgrade: false,
});
const { modelValue} = toRefs(props);

const formattedLabel = computed((): string => {
  const remainingCount = Math.max(0, modelValue.value.Limit - modelValue.value.UsageCount);
  const formattedRemainingCount = remainingCount
    ? new Intl.NumberFormat().format(remainingCount)
    : "No";

  // TODO: extract singular/plural util
  return `${formattedRemainingCount} message${remainingCount !== 1 ? "s" : ""} remaining today`;
});
</script>

<style lang="scss" scoped>
.ChatLimitBanner {
  display: grid;
  grid: auto / auto min-content;
  align-items: center;
  height: 40px;
  background-color: var(--colorSwissYellow300);
}

.ChatLimitBanner--statusNegative {
  background-color: var(--colorSwissRed300);
}

.ChatLimitBanner__label {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0 16px;
}

.ChatLimitBanner__icon {
  width: 14px;
  height: 14px;
}

.ChatLimitBanner__button {
  height: 100%;
}
</style>
