import { ConnectCreateMemberErrorPayload, ConnectMemberStatusUpdatePayload, ConnectOAuthErrorPayload } from "@mxenabled/widget-post-message-definitions";

export type ConnectMemberStatusUpdateEnhancedPayload = ConnectMemberStatusUpdatePayload & {
  connection_status_name: string;
};

export type MxConnectErrorDetails = ConnectMemberStatusUpdateEnhancedPayload | ConnectCreateMemberErrorPayload | ConnectOAuthErrorPayload;

/**
 * Enum for: https://docs.mx.com/api-reference/platform-api/reference/member-connection-statuses
 * Apparently the MX Widget SDK doesn't include any constants or enums for this.
 *
 * NB: The statuses are numbered sequentially starting from 0 with no gaps, so we could use a "valueless"
 *     enum to model the integer values. However, because it is critical the numeric values remain correct,
 *     we explicitly set the values anyway. Plus, this way it's easy to manually look up a number's meaning.
 */
export enum MxMemberConnectionStatus {
  Created = 0,
  Prevented = 1,
  Denied = 2,
  Challenged = 3,
  Rejected = 4,
  Locked = 5,
  Connected = 6,
  Impeded = 7,
  Reconnected = 8,
  Degraded = 9,
  Disconnected = 10,
  Discontinued = 11,
  Closed = 12,
  Delayed = 13,
  Failed = 14,
  Updated = 15,
  Disabled = 16,
  Imported = 17,
  Resumed = 18,
  Expired = 19,
  Impaired = 20,
  Pending = 21,
}
