import { onMounted } from "vue";

export enum OS {
  Android = "Android",
  iOS = "iOS",
  Other = "Other"
}

export default function useOSDetection() {
  function detectOS(): OS {
    if (/android/i.test(navigator.userAgent)) {
      return OS.Android;
      // @ts-ignore
    } else if (/iPad|iPhone|iPod/i.test(navigator.userAgent)) {
      return OS.iOS;
    }

    return OS.Other;
  }

  onMounted(() => {
    document.documentElement.dataset.jsOs = detectOS();
  });
}
