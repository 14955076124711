<template>
  <FormButton
    class="GoogleButtonView"
    variant="Secondary"
    :status="isRedirecting ? 'Submitting' : 'Idle'"
    @click="handleGoogleClick"
  >
    <span
      v-if="!isRedirecting"
      class="GoogleButtonView__text"
    >
      <svg class="GoogleButtonView__icon" viewBox="0 0 49 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25 20.4546V30.1365H38.4546C37.8638 33.2501 36.0908 35.8866 33.4318 37.6593L41.5454 43.9548C46.2727 39.5913 49 33.1821 49 25.5685C49 23.7958 48.8409 22.0911 48.5454 20.4549L25 20.4546Z" fill="#4285F4" />
        <path d="M10.9892 29.7585L9.1593 31.1593L2.68188 36.2047C6.79553 44.3638 15.2268 50.0003 24.9995 50.0003C31.7493 50.0003 37.4084 47.773 41.5449 43.9549L33.4312 37.6593C31.2039 39.1593 28.363 40.0685 24.9995 40.0685C18.4995 40.0685 12.9769 35.6822 10.9995 29.773L10.9892 29.7585Z" fill="#34A853" />
        <path d="M2.68191 13.7955C0.977451 17.159 0.000274658 20.9546 0.000274658 25C0.000274658 29.0454 0.977451 32.8409 2.68191 36.2044C2.68191 36.227 11.0003 29.7499 11.0003 29.7499C10.5003 28.2499 10.2047 26.659 10.2047 24.9997C10.2047 23.3404 10.5003 21.7496 11.0003 20.2496L2.68191 13.7955Z" fill="#FBBC05" />
        <path d="M25 9.95459C28.6819 9.95459 31.9546 11.2273 34.5682 13.6819L41.7273 6.52285C37.3863 2.47744 31.7501 0 25 0C15.2273 0 6.79553 5.61366 2.68188 13.7955L11 20.2501C12.9772 14.3409 18.5 9.95459 25 9.95459Z" fill="#EA4335" />
      </svg>

      Sign in with Google
    </span>

    <span
      v-else
      class="GoogleButtonView__text"
    >
      Redirecting…
    </span>
  </FormButton>
</template>

<script lang="ts" setup>
import FormButton from "@cosine/components/FormButton.vue";
import useAuthConfig from "@cosine/composables/useAuthConfig";
import useStytchStore from "@cosine/stores/useStytchStore";
import { ref } from "vue";

const { stytchClient } = useStytchStore();
const { authenticateUrl } = useAuthConfig();

const isRedirecting = ref(false);

async function handleGoogleClick() {
  if (/LinkedIn|Instagram/i.test(navigator.userAgent)) {
    window.confirm("For security, Google login is disabled on in‑app browsers.\n\nTry signing in with Google directly from Safari or Chrome. Go to my.era.app");
    return;
  }

  isRedirecting.value = true;

  await stytchClient.oauth.google.start({
    login_redirect_url: authenticateUrl,
    signup_redirect_url: authenticateUrl,
  });
}
</script>

<style lang="scss" scoped>
.GoogleButtonView__icon {
  height: 16px;
}

.GoogleButtonView__text {
  display: inline-flex;
  gap: 8px;
  align-items: center;
}
</style>
