import useApiClient from "@cosine/composables/useApiClient";
import useEditModal from "@cosine/composables/useEditModal";
import { ModalName } from "@cosine/stores/useModalStore.types";
import { IApiResponse, IBudgetDefinitionModel, IBudgetInstanceModel, ISublistAndTotalCount, IUpsertBudgetDefinitionCommand } from "@cosine/types/api-models";
import { defineStore } from "pinia";
import { ref } from "vue";

export default defineStore("BudgetStore", () => {
  const { apiClient } = useApiClient();
  const budgetDefinitions = ref<Array<IBudgetDefinitionModel>>([]);
  const budgetInstances = ref<Array<IBudgetInstanceModel>>([]);
  const {
    editingItem: editingBudgetDefinition,
    startEditingItem: startEditingBudgetDefinition,
    finishEditingItem: finishEditingBudgetDefinition,
  } = useEditModal<IBudgetDefinitionModel>(ModalName.EditBudgetDefinition);

  async function fetchBudgetDefinitions() {
    const { data: { Result: _budgetDefinitions } } = await apiClient.value.get<IApiResponse<ISublistAndTotalCount<IBudgetDefinitionModel>>>("/finances/budget/definitions");

    budgetDefinitions.value = _budgetDefinitions?.Items || [];
  }

  async function fetchBudgetInstances() {
    const { data: { Result: _budgetInstances } } = await apiClient.value.get<IApiResponse<ISublistAndTotalCount<IBudgetInstanceModel>>>("/finances/budget/instances");

    budgetInstances.value = _budgetInstances?.Items || [];
  }

  async function updateBudgetDefinition(params: IUpsertBudgetDefinitionCommand) {
    const { data: { Result: updatedBudgetDefinition } } = await apiClient.value.post<IApiResponse<IBudgetDefinitionModel>>(`/finances/budget/definitions`, params);

    const existingBudgetDefinition = budgetDefinitions.value.find((budgetDefinition) => {
      return budgetDefinition.EraId === params.BudgetId;
    });

    if (existingBudgetDefinition && updatedBudgetDefinition) {
      return Object.assign(existingBudgetDefinition, updatedBudgetDefinition);
    }
  }

  return {
    budgetDefinitions,
    budgetInstances,
    editingBudgetDefinition,

    fetchBudgetDefinitions,
    fetchBudgetInstances,
    startEditingBudgetDefinition,
    finishEditingBudgetDefinition,
    updateBudgetDefinition,
  };
});
