<template>
  <section class="BudgetListView">
    <BudgetCard
      v-for="budget in budgetInstancesForPeriod"
      :key="budget.EraId"
      :modelValue="budget"
      @click="handleBudgetCardClick(budget)"
    />
  </section>
</template>

<script lang="ts" setup>
import BudgetCard from "@cosine/components/BudgetCard.vue";
import { RoutePath } from "@cosine/routes";
import useBudgetStore from "@cosine/stores/useBudgetStore";
import { BudgetPeriod, IBudgetInstanceModel } from "@cosine/types/api-models";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const budgetStore = useBudgetStore();
const {
  budgetDefinitions,
  budgetInstances,
} = storeToRefs(budgetStore);
const {
  startEditingBudgetDefinition,
} = budgetStore;

const period = computed((): BudgetPeriod => {
  switch (route.path) {
    case RoutePath.budgetsWeekly:
      return BudgetPeriod.Weekly;
    case RoutePath.budgetsMonthly:
      return BudgetPeriod.Monthly;
  }

  return BudgetPeriod.Yearly;
});

const budgetInstancesForPeriod = computed(() => {
  return budgetInstances.value.filter((budgetInstance) => {
    return budgetInstance.Period === period.value;
  });
});

function handleBudgetCardClick(budget: IBudgetInstanceModel) {
  // TODO: handle when definitions weren’t loaded or failed to load
  const budgetDefinition = budgetDefinitions.value.find((budgetDefinition) => {
    return budgetDefinition.EraId === budget.BudgetDefinitionId;
  });
  if (!budgetDefinition) return;

  startEditingBudgetDefinition(budgetDefinition);
}
</script>
