<template>
  <SelectInput
    v-bind="{ modelValue, ...$attrs }"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <option value="" disabled>
      Select a language
    </option>
    <option
      v-for="option in options"
      :key="option.value"
      :value="option.value"
    >
      {{ option.label }}
    </option>
  </SelectInput>
</template>

<script lang="ts" setup>
import SelectInput from "@cosine/components/SelectInput.vue";
import { Language, Languages } from "@cosine/lib/constants/languages";
import { computed } from "vue";

defineEmits<{
  "update:modelValue": [string],
}>();

defineProps<{
  modelValue?: string,
}>();

const options = computed((): Array<{
  label: string,
  value: string,
}> => {
  return Object.keys(Languages).sort((a, b) => {
    return Languages[a as Language].localeCompare(Languages[b as Language]);
  }).map((country) => {
    return {
      label: Languages[country as Language],
      value: country,
    };
  });
});
</script>
