<template>
  <time
    class="Timestamp"
    :datetime="isoDate"
  >
    {{ formattedTimestamp }}
  </time>
</template>

<script lang="ts" setup>
import useNow from "@cosine/composables/useNow";
import { format as formatDate, formatDistanceStrict } from "date-fns";
import { computed, toRefs } from "vue";

const { now } = useNow();

const props = withDefaults(defineProps<{
  datetime: Date | string,
  format?: "Relative" | "AbsoluteShort" | "AbsoluteShortDate"
}>(), {
  format: "Relative",
});

const {
  datetime,
  format,
} = toRefs(props);

const date = computed((): Date => {
  return new Date(datetime.value);
});

const isoDate = computed(() => date.value.toISOString());

const formattedTimestamp = computed((): string => {
  switch (format.value) {
    case "AbsoluteShort":
      return formatDate(date.value, "MMM d, h:mmaaa");
    case "AbsoluteShortDate":
      return formatDate(date.value, "MMM d");
  }

  const isWithinMinute = now.value.getTime() - date.value.getTime() < 1000 * 60;
  return isWithinMinute
    ? "Just now"
    : formatDistanceStrict(date.value, now.value, {
      addSuffix: true,
    });
});
</script>
