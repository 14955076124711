<template>
  <div
    class="ScrollContainer"
    :class="{
      'ScrollContainer--hasBottomFade': hasBottomFade,
      [`ScrollContainer--viewport${viewport}`]: true,
      'ScrollContainer--includeScrollbar': includeScrollbar,
    }"
  >
    <div
      ref="trackEl"
      class="ScrollContainer__track"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ScrollViewport } from "@cosine/components/ScrollContainer.types";
import useTapToScrollToTop from "@cosine/composables/useTapToScrollToTop";
import { ref } from "vue";

const trackEl = ref<HTMLElement>();
useTapToScrollToTop(trackEl);

defineExpose({
  trackEl,
});

withDefaults(defineProps<{
  viewport?: keyof typeof ScrollViewport,
  includeScrollbar?: boolean,
  hasBottomFade?: boolean,
}>(), {
  viewport: "All",
  includeScrollbar: false,
  hasBottomFade: false,
});
</script>

<style lang="scss" scoped>
.ScrollContainer {
  position: relative;
}

.ScrollContainer__track {
  .ScrollContainer--hasBottomFade & {
    mask-image: linear-gradient(
      to bottom,
      black 0,
      black calc(100% - 24px),
      transparent 100%,
    );
  }

  .ScrollContainer--viewportAll & {
    position: absolute;
    inset: 0;
    overflow-y: auto;
  }

  .ScrollContainer--includeScrollbar & {
    overflow-y: scroll;
  }

  .ScrollContainer--viewportMobile & {
    @media (max-width: 599px) {
      position: absolute;
      inset: 0;
      overflow-y: auto;
    }
  }
}
</style>
