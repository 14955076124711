<template>
  <section class="InvestingBlueprintsView">
    Investing Blueprints View
  </section>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>
.InvestingBlueprintsView {
  width: 100%;
}
</style>
