<template>
  <section
    class="InvestingView"
  >
    <TabNavView
      v-if="snaptradeUser"
      class="InvestingView__tabNav"
      :items="tabNavItems"
      isSubNav
    />

    <ScrollContainer
      viewport="Mobile"
      class="InvestingView__scrollContainer"
    >
      <slot />
      <PromptPane
        v-if="!snaptradeUser"
        class="InvestingView__prompt"
        iconName="HexagonChart"
        iconColor="Green"
        backgroundColor="Green"
        title="Make smarter money moves with era's proprietary investing AI"
      >
        <template #body>
          <p>The more accounts you connect, the better era gets.</p>
          <p>See all your Investing in one place and automate your trading strategy. Once you connect your investment accounts, era can trade, rebalance, and manage your portfolios via chat or by following your favorite blueprints.</p>
        </template>
        <div class="InvestingView__logoGrid">
          <PartnerLogo name="Robinhood" />
          <PartnerLogo name="Vanguard" />
          <PartnerLogo name="Fidelity" />
          <PartnerLogo name="CharlesSchwab" />
          <PartnerLogo name="ETrade" />
          <PartnerLogo name="InteractiveBrokers" />
          <PartnerLogo name="M1Finance" />
          <PartnerLogo name="Webull" />
        </div>
        <template #button>
          <ConnectBrokerageView />
        </template>
      </PromptPane>
    </ScrollContainer>
  </section>
</template>

<script lang="ts" setup>
import PartnerLogo from "@cosine/components/PartnerLogo.vue";
import PromptPane from "@cosine/components/PromptPane.vue";
import ScrollContainer from "@cosine/components/ScrollContainer.vue";
import { TabNavItem } from "@cosine/components/TabNav.types";
import { RouteName } from "@cosine/routes";
import useInvestingStore from "@cosine/stores/useInvestingStore";
import ConnectBrokerageView from "@cosine/views/ConnectBrokerageView.vue";
import TabNavView from "@cosine/views/TabNavView.vue";
import { storeToRefs } from "pinia";
import { ref } from "vue";

const { snaptradeUser } = storeToRefs(useInvestingStore());

const tabNavItems = ref<Array<TabNavItem>>([
  { label: "Investments", to: { name: RouteName.investingInvestments } },
  { label: "Blueprints", to: { name: RouteName.investingBlueprints } },
  { label: "Explore", to: { name: RouteName.investingExplore } },
]);
</script>

<style lang="scss" scoped>
.InvestingView__logoGrid {
  padding-top: 28px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: max-content;
  color: var(--colorSwissGreen300);
}

.InvestingView__scrollContainer {
  height: 100%;
}
</style>
