<template>
  <section
    class="AccountSheetSummary"
    :class="{
      [`AccountSheetSummary--scope${account.Scope}`]: true,
    }"
  >
    <UIText
      class="AccountSheetSummary__caption"
      size="2XSmall"
      weight="Medium"
    >
      <template v-if="account.InstitutionName">
        <span class="AccountSheetSummary__institutionName">{{ account.InstitutionName }}</span>
        <span class="AccountSheetSummary__bullet"> &bull; </span>
      </template>
      <span class="AccountSheetSummary__last4">{{ account.Nickname }}</span>
    </UIText>

    <UIText
      class="AccountSheetSummary__title"
      tag="h3"
      size="XLarge"
      weight="Medium"
    >
      {{ account.DisplayName }}
    </UIText>

    <UIText
      class="AccountSheetSummary__amount"
      tag="h4"
      size="XLarge"
      weight="Medium"
    >
      {{ formattedAmount }}
    </UIText>
  </section>
</template>

<script lang="ts" setup>
import UIText from "@cosine/components/UIText.vue";
import formatAmount from "@cosine/lib/utils/financial/formatAmount";
import obscureAmount from "@cosine/lib/utils/financial/obscureAmount";
import { AccountScope, IEnrichedAccountModel } from "@cosine/types/api-models";
import { computed, toRefs } from "vue";

const props = defineProps<{
  account: IEnrichedAccountModel
}>();

const {account} = toRefs(props);
const formattedAmount = computed(() => {
  const formattedBalance = formatAmount(account.value.Balance);

  if (formattedBalance && account.value.Scope === AccountScope.Limited) {
    return obscureAmount(formattedBalance);
  }

  return formattedBalance;
});
</script>

<style lang="scss" scoped>
.AccountSheetSummary {
  display: grid;
}

.AccountSheetSummary__caption {
  display: flex;
  align-items: center;
  gap: 3px;
  margin-bottom: 12px;
  color: var(--colorSwissAlphaBlack600);
}

.AccountSheetSummary__bullet {
  font-size: 8px;
}

.AccountSheetSummary__amount {
  margin-top: 8px;

  .AccountSheetSummary--scopeLimited & {
    filter: blur(8px);
  }
}
</style>
