import useApiClient from "@cosine/composables/useApiClient";
import { MarketSymbol } from "@cosine/stores/useMarketStore.types";
import { AggregateSpan, IApiResponse, IAssetDetail, IAssetPrice, ITimeSeriesRangeOf } from "@cosine/types/api-models";
import useLogger from "@shared/composables/useLogger";
import { defineStore } from "pinia";
import { ref } from "vue";

export default defineStore("MarketStore", () => {
  const { apiClient } = useApiClient();
  const logger = useLogger();

  const marketData = ref<ITimeSeriesRangeOf<IAssetDetail, IAssetPrice>>();
  const isFetching = ref(false);
  const symbols = Object.keys(MarketSymbol);

  const aggregateSpan = AggregateSpan.FiveMinutes;

  async function fetchStocks() {
    isFetching.value = true;

    try {
      const tickerParams = symbols.map((symbol) => `Tickers=${symbol}`).join("&");
      const response = await apiClient.value.get<IApiResponse<ITimeSeriesRangeOf<IAssetDetail, IAssetPrice>>>(
        `/market-data/aggregates?${tickerParams}&AggregateSpan=${aggregateSpan}`,
      );

      marketData.value = response.data.Result;
    } catch (err) {
      logger.error(err);
    } finally {
      isFetching.value = false;
    }
  }

  return {
    marketData,

    fetchStocks,
  };
});
