import { RoutePath } from "@cosine/routes";
import useAuthStore from "@cosine/stores/useAuthStore";
import useStytchStore from "@cosine/stores/useStytchStore";
import { Session } from "@stytch/vanilla-js";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";

export default function () {
  const router = useRouter();
  const { stytchClient } = useStytchStore();

  const {
    currentUser,
    session,
  } = storeToRefs(useAuthStore());

  stytchClient.session.onChange(handleSessionChange);
  session.value = stytchClient.session.getSync();

  function handleSessionChange(newSession: Session | null) {
    session.value = newSession;

    if (!newSession) {
      currentUser.value = null;
      router.push(RoutePath.login);
    }
  }
}
