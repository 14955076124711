<template>
  <BudgetView class="BudgetsPage">
    <RouterView />
  </BudgetView>
</template>

<script lang="ts" setup>
import BudgetView from "@cosine/views/BudgetView.vue";
import { useSeoMeta } from "@unhead/vue";

useSeoMeta({
  title: "Budgets",
});
</script>

<style lang="scss" scoped>
.BudgetsPage {
  height: 100%;
}
</style>
