// TODO: Temporary until we introduce custom symbol watching
export enum MarketSymbol {
  SPY = "SPY",
  DIA = "DIA",
  VOX = "VOX",
  VDC = "VDC",
  VDE = "VDE",
  KCE = "KCE",
  VHT = "VHT",
  VIS = "VIS",
  IFRA = "IFRA",
  VAW = "VAW",
  USRT = "USRT",
  XSW = "XSW",
  XNTK = "XNTK",
  VCR = "VCR",
  GLD = "GLD",
  USO = "USO",
  VGK = "VGK",
  VWO = "VWO",
  EMB = "EMB",
}
