<template>
  <section>
    <RoutineList
      v-if="transferRoutinesFlag && transferTemplates.length > 0"
      class="RoutineExploreView__routineList--transfers"
      title="Money transfers"
      subtitle="Transfer between accounts on a regular basis"
    >
      <RoutineListItem
        v-for="template in transferTemplates"
        :key="template.title"
        v-bind="template"
        @click="handleTemplateClick(template)"
      />
    </RoutineList>
    <RoutineList
      title="Coming soon"
      subtitle="Proactive monitoring, portfolio rebalancing, and more"
      :routines="comingSoonRoutines"
      :isClickable="false"
    />
  </section>
</template>

<script lang="ts" setup>
import RoutineList from "@cosine/components/RoutineList.vue";
import RoutineListItem from "@cosine/components/RoutineListItem.vue";
import useFlag from "@cosine/composables/useFlag";
import useRoutineExploreStore from "@cosine/stores/useRoutineExploreStore";
import { RoutineTemplate } from "@cosine/stores/useRoutineStore.types";
import { createRoutine } from "@cosine/tests/factories/RoutineFactory";
import { UserFeatureFlags } from "@cosine/types/api-models";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const {flag:transferRoutinesFlag} = useFlag(UserFeatureFlags.FundsTransferRoutinesEnabled, false);
const {
  transferTemplates,
} = storeToRefs(useRoutineExploreStore());

const comingSoonRoutines = ref([
  createRoutine({Description: "Auto-donate to my favorite charities", CronExpression: "0 12 * * 5", IsDisabled: true }),
  createRoutine({Description: "Rebalance Robinhood portfolio", CronExpression: "0 10 * * 5", IsDisabled: true }),
  createRoutine({Description: "Monitor 401k contribution", CronExpression: "0 12 5 * *", IsDisabled: true }),
]);

function handleTemplateClick(template: RoutineTemplate) {
  router.push(template.route);
}
</script>
