<template>
  <ScrollContainer
    class="MarketsPage"
    viewport="Mobile"
  >
    <StockListView />
  </ScrollContainer>
</template>

<script lang="ts" setup>
import ScrollContainer from "@cosine/components/ScrollContainer.vue";
import StockListView from "@cosine/views/StockListView.vue";
import { useSeoMeta } from "@unhead/vue";

useSeoMeta({
  title: "Markets",
});
</script>

<style lang="scss" scoped>
.MarketsPage {
  height: 100%;
}
</style>
