<template>
  <div class="Logo">
    <svg class="Logo__svg" viewBox="0 0 41 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.8094 15.2017C28.8738 15.2017 26.749 13.4404 26.749 11.008C26.749 8.46383 28.5943 6.89818 31.8094 6.4788L34.7171 6.08739C35.4999 5.97556 35.8913 5.58414 35.8913 4.94111C35.8913 3.8787 34.8569 2.98404 33.1514 2.98404C31.5578 2.98404 30.3556 3.82278 30.2438 5.30456H26.8329C27.1684 2.20121 29.5169 0.244141 33.1514 0.244141C36.9817 0.244141 39.4141 2.42487 39.4141 5.86372V10.9801C39.4141 11.7908 39.7216 12.1543 40.3087 12.1543C40.5044 12.1543 40.784 12.0984 40.784 12.0984V14.9781C40.784 14.9781 39.9453 15.1458 39.1065 15.1458C37.6247 15.1458 36.5344 14.4469 36.3666 13.0769C35.7236 14.363 33.9622 15.2017 31.8094 15.2017ZM32.3966 12.5178C34.6612 12.5178 35.9752 10.8962 35.9752 8.71546V8.29608L32.3966 8.8832C30.9707 9.10687 30.2717 9.77786 30.2717 10.8403C30.2717 11.8188 31.1105 12.5178 32.3966 12.5178Z" />
      <path d="M26.1007 3.68299C25.7932 3.62707 25.2899 3.51524 24.7028 3.51524C22.6619 3.51524 20.7607 5.27661 20.7607 9.63807V14.9781H17.238V0.467805H20.7607V3.12383C21.4037 1.4743 23.3608 0.244141 25.1222 0.244141C25.6254 0.244141 25.933 0.300057 26.1007 0.328015V3.68299Z"/>
      <path d="M0.783997 7.70896C0.783997 3.93461 3.10452 0.244141 7.8574 0.244141C12.6103 0.244141 14.9029 3.82278 14.9029 7.31755C14.9029 7.90467 14.8469 8.60362 14.8469 8.60362H4.25081C4.55834 10.9241 6.01217 12.2382 8.13699 12.2382C9.75856 12.2382 10.9887 11.5113 11.3242 10.2252H14.819C14.3437 13.3845 11.6318 15.2017 8.05311 15.2017C3.27227 15.2017 0.783997 11.5113 0.783997 7.70896ZM4.36264 5.9476H11.1005C10.9608 4.21419 9.84243 2.92812 7.8574 2.92812C6.04012 2.92812 4.80997 4.01849 4.36264 5.9476Z"/>
    </svg>
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
.Logo {
  width: 40px;
}

.Logo__svg {
  display: block;
  width: 100%;
  fill: var(--colorSwissBlack);
}
</style>
