import { Environment } from "@shared/composables/config/useEnvironment";
import useRuntimeEnvironment from "@shared/composables/config/useRuntimeEnvironment";

const silentlyIgnore = (..._args: any[]): void => { };

// Wrap console and on production ignore anything other than console.error()
export function useLoggerForEnvironment(environment: Environment) {
  const isLogFriendlyEnvironment = ![Environment.Test, Environment.Production].includes(environment);

  return new Proxy(console, {
    get(target, prop, receiver) {
      if (isLogFriendlyEnvironment || String(prop) === "error") {
        return Reflect.get(target, prop, receiver);
      } else {
        return silentlyIgnore;
      }
    },
  });
}

let logger: Console;
export default function useLogger() {
  logger ??= useLoggerForEnvironment(useRuntimeEnvironment().currentEnvironment);
  return logger;
}
