<template>
  <section class="StockCard">
    <div class="StockCard__card">
      <div class="StockCard__content">
        <header class="StockCard__header">
          <UIText
            class="StockCard__title"
            tag="h3"
            weight="Medium"
            size="Large"
          >
            {{ modelValue.Summary.DisplaySymbol }}
          </UIText>
          <UIText
            class="StockCard__subtitle"
            tag="h4"
            size="XSmall"
            weight="Medium"
          >
            {{ modelValue.Summary.Name }}
          </UIText>
        </header>
        <div
          v-if="modelValue.Overall"
          class="StockCard__currentPrice"
        >
          <UIText
            class="StockCard__currentPriceAmount"
            size="Small"
            weight="Medium"
          >
            {{ formatCurrency(modelValue.Overall.Close) }}
          </UIText>
          <UIText
            class="StockCard__currentPriceChange"
            size="XSmall"
          >
            <span v-if="modelValue.Overall.PercentChange > 0">▲</span>
            <span v-else-if="modelValue.Overall.PercentChange < 0">▼</span>
            {{ formattedPercentChange }}
          </UIText>
        </div>
        <dl
          v-if="modelValue.Overall"
          class="StockCard__priceRange"
        >
          <UIText
            tag="dt"
            class="StockCard__priceRangeLabel"
            size="2XSmall"
          >
            Open
          </UIText>
          <UIText
            tag="dt"
            class="StockCard__priceRangeAmount--open StockCard__priceRangeAmount"
            size="2XSmall"
            weight="Medium"
          >
            {{ formatCurrency(modelValue.Overall.Open) }}
          </UIText>

          <UIText
            tag="dt"
            class="StockCard__priceRangeLabel"
            size="2XSmall"
          >
            High
          </UIText>
          <UIText
            tag="dt"
            class="StockCard__priceRangeAmount--high StockCard__priceRangeAmount"
            size="2XSmall"
            weight="Medium"
          >
            {{ formatCurrency(modelValue.Overall.High) }}
          </UIText>

          <UIText
            tag="dt"
            class="StockCard__priceRangeLabel"
            size="2XSmall"
          >
            Low
          </UIText>
          <UIText
            tag="dt"
            class="StockCard__priceRangeAmount--low StockCard__priceRangeAmount"
            size="2XSmall"
            weight="Medium"
          >
            {{ formatCurrency(modelValue.Overall.Low) }}
          </UIText>
        </dl>
      </div>

      <StockChart
        v-bind="{modelValue}"
      />
    </div>
  </section>
</template>

<script lang="ts" setup>
import StockChart from "@cosine/components/StockChart.vue";
import UIText from "@cosine/components/UIText.vue";
import { IAssetDetail, IAssetPrice, ITimeSeriesOf } from "@cosine/types/api-models";
import currency from "currency.js";
import { computed, toRefs } from "vue";

const props = withDefaults(defineProps<{
  modelValue: ITimeSeriesOf<IAssetDetail, IAssetPrice>,
  index?: number,
}>(), {
  index: 0,
});
const {
  modelValue,
} = toRefs(props);

const formattedPercentChange = computed((): string => {
  return `${Math.round((modelValue.value.Overall?.PercentChange || 0) * 100) / 100}%`;
});

function formatCurrency(amount: number): string {
  return currency(amount).format();
}
</script>

<style lang="scss" scoped>
.StockCard {
  transition: 350ms var(--easeOutCubic);
  transition-delay: calc(v-bind(index) * 75ms);
}

.StockCard__card {
  display: grid;
  grid: min-content auto / auto;
  height: 180px;
  border-block: 1px solid var(--colorSwissGrey100);
  background-color: var(--colorWhite);

  .StockCard.v-enter-active &,
  .StockCard.v-leave-active & {
    transition: inherit;
    transition-property: opacity, transform;
  }

  .StockCard.v-enter-from &,
  .StockCard.v-leave-to & {
    transform: scale(0.9);
    opacity: 0;
  }
}

.StockCard__content {
  display: grid;
  align-items: flex-start;
  grid: repeat(2, 1fr) / auto min-content;
  gap: 0 16px;
  padding: 16px 16px 14px;
}

.StockCard__header {
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  gap: 8px;
  align-items: baseline;
}

.StockCard__subtitle {
  color: var(--colorSwissAlphaBlack400);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.StockCard__currentPrice {
  align-self: flex-end;
  display: flex;
  gap: 8px;
  align-items: baseline;
}

.StockCard__currentPriceAmount {
  font-variant-numeric: tabular-nums;
}

.StockCard__currentPriceChange {
  color: var(--colorGreen500);
}

.StockCard__priceRange {
  grid-area: 1 / 2 / span 2 / 2;
  display: grid;
  grid: auto / repeat(2, 1fr);
  gap: 4px 8px;
}

.StockCard__priceRangeLabel {
  color: var(--colorSwissAlphaBlack400);
}

.StockCard__priceRangeAmount {
  font-variant-numeric: tabular-nums;
  text-align: right;
}
</style>
