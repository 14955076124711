import { FlagKey } from "@cosine/composables/useFlag.types";
import useFlagStore from "@cosine/stores/useFlagStore";
import { storeToRefs } from "pinia";
import { computed } from "vue";

export default function useFlag<T>(key: FlagKey | string, defaultValue: T) {
  const {
    isLaunchDarklyReady,
    flagMap,
  } = storeToRefs(useFlagStore());

  const flag = computed((): T => {
    if (!isLaunchDarklyReady.value || flagMap.value[key] === undefined) return defaultValue;

    return flagMap.value[key];
  });

  return {
    isFlagReady: isLaunchDarklyReady,
    flag,
  };
}
