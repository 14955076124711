import currency from "currency.js";

// TODO: support other currencies
export default function (
  value: currency | string | number | null | undefined,
): string | null {
  if (
    value === undefined ||
    value === null ||
    typeof value === "string" && value.trim() === ""
  ) return null;

  return currency(value).divide(100).format();
}
