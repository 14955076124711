<template>
  <ScrollContainer
    class="SettingsPage"
    viewport="Mobile"
  >
    <div class="SettingsPage__content">
      <MembershipView />
      <EditUserView />

      <section class="SettingsPage__support">
        <UIText
          class="SettingsPage__supportLink"
          tag="a"
          href="mailto:support@era.app"
          size="2XSmall"
          weight="Medium"
        >support@era.app</UIText>
      </section>

      <section class="SettingsPage__debug">
        <UIText
          size="2XSmall"
          weight="Medium"
        >{{ currentUser?.UserId }}</UIText>
      </section>
    </div>
  </ScrollContainer>
</template>

<script lang="ts" setup>
import ScrollContainer from "@cosine/components/ScrollContainer.vue";
import UIText from "@cosine/components/UIText.vue";
import useAuthStore from "@cosine/stores/useAuthStore";
import EditUserView from "@cosine/views/EditUserView.vue";
import MembershipView from "@cosine/views/MembershipView.vue";
import { useSeoMeta } from "@unhead/vue";

useSeoMeta({
  title: "Settings",
});

const {currentUser} = useAuthStore();
</script>

<style lang="scss" scoped>
.SettingsPage {
  height: 100%;
}

.SettingsPage__content {
  display: grid;
  grid: auto min-content min-content / auto;
  height: 100%;
}

.SettingsPage__support {
  margin-top: 16px;
  text-align: center;
}

.SettingsPage__supportLink {
  text-decoration: none;
}

.SettingsPage__debug {
  padding: 4px var(--layoutMargin) var(--viewportPaddingBottom);
  text-align: center;
  color: var(--colorSwissAlphaBlack500);
}
</style>
