<template>
  <component
    :is="tag"
    class="UIText"
    :class="{
      'UIText--isMultiline': isMultiline,
      [`UIText--casing${casing}`]: true,
      [`UIText--size${size}`]: true,
      [`UIText--weight${weight}`]: true,
    }"
  >
    <slot />
  </component>
</template>

<script lang="ts" setup>
import { TextCasing, TextSize, TextTag, TextWeight } from "@shared/components/Text.types";
import { toRefs } from "vue";

const props = withDefaults(defineProps<{
  casing?: keyof typeof TextCasing,
  isMultiline?: boolean,
  size?: keyof typeof TextSize | "3XSmall", // TODO once we completely replace Pressura
  tag?: keyof typeof TextTag | "legend" | "div", // TODO once we completely replace Pressura
  weight?: keyof typeof TextWeight, // TODO once we completely replace Pressura
}>(), {
  casing: "Normal",
  isMultiline: false,
  size: "Medium",
  tag: "span",
  weight: "Normal",
});

const {
  tag,
  weight,
} = toRefs(props);
</script>

<style lang="scss" scoped>
.UIText {
  font: var(--fontSaansMedium);
}

.UIText--casingNormal {
  text-transform: initial;
}

.UIText--casingUpper {
  text-transform: uppercase;
  letter-spacing: 1.7px; // TODO - use percent referenced from size
}

.UIText--size3XSmall {
  font: var(--fontSaans3XSmall);
}

.UIText--size2XSmall {
  font: var(--fontSaans2XSmall);
}

.UIText--sizeXSmall {
  font: var(--fontSaansXSmall);
}

.UIText--sizeSmall {
  font: var(--fontSaansSmall);
}

.UIText--sizeMedium {
  font: var(--fontSaansMedium);
}

.UIText--sizeLarge {
  font: var(--fontSaansLarge);
}

.UIText--sizeXLarge {
  font: var(--fontSaansXLarge);
}

.UIText--size2XLarge {
  font: var(--fontSaans2XLarge);
}

.UIText--size3XLarge {
  font: var(--fontSaans3XLarge);
}

.UIText--weightNormal {
  font-weight: var(--fontWeightSaansNormal);
}

.UIText--weightMedium {
  font-weight: var(--fontWeightSaansMedium);
}

.UIText--weightBold {
  font-weight: var(--fontWeightSaansBold);
}

.UIText--isMultiline {
  line-height: 1.4;

  &.UIText--sizeLarge,
  &.UIText--sizeXLarge {
    line-height: 1.3;
  }

  &.UIText--size2XLarge,
  &.UIText--size3XLarge {
    line-height: 1.05;
  }
}
</style>
