<template>
  <SheetScreen
    class="TransactionCategorySelectScreenView"
    title="Categories"
    :zIndex="1"
    @click:back="$emit('click:back')"
  >
    <ScrollContainer class="TransactionCategorySelectScreenView__scrollContainer">
      <CheckboxListFieldset
        v-bind="{
          modelValue,
          items,
        }"
        @update:modelValue="$emit('update:modelValue', $event as Array<TransactionCategory>)"
      />
    </ScrollContainer>
  </SheetScreen>
</template>

<script lang="ts" setup>
import { CheckboxListFieldsetItem } from "@cosine/components/CheckboxListFieldset.types";
import CheckboxListFieldset from "@cosine/components/CheckboxListFieldset.vue";
import ScrollContainer from "@cosine/components/ScrollContainer.vue";
import SheetScreen from "@cosine/components/SheetScreen.vue";
import { TransactionCategory } from "@cosine/stores/useTransactionStore.types";
import { computed } from "vue";

defineEmits<{
  "update:modelValue": [categories: Array<TransactionCategory>],
  "click:back": [],
}>();

defineProps<{
  modelValue: Array<TransactionCategory>,
}>();

const itemMap = computed(() => {
  return Object.values(TransactionCategory).reduce((acc, subcategoryCode) => {
    const [_, category, subcategory] = subcategoryCode.match(/\[([^\]]+)\]-\[([^\]]+)\]/) || [];
    const existingCategory = acc[category];

    if (category === subcategory) return acc;

    if (!existingCategory) {
      acc[category] = {
        label: category,
        value: `[${category}]-[${category}]`,
        subitems: [{
          label: subcategory,
          value: subcategoryCode,
        }],
      };
    } else {
      existingCategory.subitems?.push({
        label: subcategory,
        value: subcategoryCode,
      });
    }

    return acc;
  }, {} as {[key: string]: CheckboxListFieldsetItem});
});

const items = computed((): Array<CheckboxListFieldsetItem> => {
  return Object.values(itemMap.value);
});
</script>

<style lang="scss" scoped>
.TransactionCategorySelectScreenView__scrollContainer {
  height: 100%;
}

.TransactionCategorySelectScreenView__list {
  display: grid;
  gap: 1px;
}

.TransactionCategorySelectScreenView__listItem {
  padding: 24px 16px;
  outline: 1px solid var(--colorSwissGrey100);
}
</style>
