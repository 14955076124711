<template>
  <figure class="StockChart">
    <svg
      class="StockChart__svg"
      preserveAspectRatio="none"
      v-bind="{viewBox}"
    >
      <defs>
        <linearGradient
          :id="`stockChartGradient--${modelValue.Summary.DisplaySymbol}`"
          gradientTransform="rotate(90)"
        >
          <stop offset="25%" stop-color="var(--accentColor)" stop-opacity="0.5" />
          <stop offset="100%" stop-color="var(--accentColor)" stop-opacity="0" />
        </linearGradient>
      </defs>
      <path
        class="StockChart__path"
        :d="chartPath"
        :fill="`url(#stockChartGradient--${modelValue.Summary.DisplaySymbol})`"
        :stroke-width="chartStrokeWidth"
        stroke="var(--accentColor)"
      />
    </svg>
  </figure>
</template>

<script lang="ts" setup>
import { IAssetDetail, IAssetPrice, ITimeSeriesOf } from "@cosine/types/api-models";
import { computed, toRefs } from "vue";

// TODO: test once we figure out how we want to present this

const chartHeight = 100;
const chartSpacing = 16;
const chartStrokeWidth = 2;

const props = defineProps<{
  modelValue: ITimeSeriesOf<IAssetDetail, IAssetPrice>
}>();

const {
  modelValue,
} = toRefs(props);

// TODO: this stretches the data we have to go full-width
// but we should revisit this to indicate progress throughout the day
const viewBox = computed((): string => `0 0 ${modelValue.value.Data.length} 100`);

const chartData = computed(() => {
  return modelValue.value.Data.reduce((acc, { Value }, i, arr) => {
    acc.prices.push(Value.Open);
    acc.minPrice = Math.min(acc.minPrice, Value.Open, Value.Close);
    acc.maxPrice = Math.max(acc.maxPrice, Value.Open, Value.Close);

    if (i === arr.length - 1) { acc.prices.push(Value.Close); }

    return acc;
  }, {
    prices: [] as Array<number>,
    minPrice: Number.POSITIVE_INFINITY,
    maxPrice: Number.NEGATIVE_INFINITY,
  });
});

const chartPath = computed(() => {
  const bottomRight = `${chartData.value.prices.length - 1},${chartHeight + chartStrokeWidth / 2}`;
  const bottomLeft = `-${chartStrokeWidth / 2},${chartHeight + chartStrokeWidth / 2}`;

  return chartData.value.prices.reduce<string>((acc, price, i) => {
    const x = i;
    const y = getYForPrice(price);

    if (i === 0) {
      acc += ` L${x - chartStrokeWidth / 2},${y}`;
    }

    return `${acc} L${x},${y}`;
  }, `M${bottomRight} L${bottomLeft} `);
});

function getYForPrice(price: number): number {
  return (chartHeight - chartSpacing) -
     (price - chartData.value.minPrice) /
     (chartData.value.maxPrice - chartData.value.minPrice) *
     (chartHeight - chartSpacing * 2);
}
</script>

<style lang="scss" scoped>
.StockChart {
  --accentColor: var(--colorSwissBlack);

  position: relative;
  margin: 0 4px 4px;
  background-color: var(--colorSwissGrey50);
  overflow: hidden;
}

.StockChart__svg {
  width: 100%;
  height: 100%;
}

.StockChart__path {
  vector-effect: non-scaling-stroke;
}
</style>
