<template>
  <section
    class="TransfersPage"
  >
    <template v-if="isFlagReady">
      <TransferWaitlistView v-if="!transfersFlag" />
      <TransferListView v-else />
    </template>
  </section>
</template>

<script lang="ts" setup>
import useFlag from "@cosine/composables/useFlag";
import { UserFeatureFlags } from "@cosine/types/api-models";
import TransferListView from "@cosine/views/TransferListView.vue";
import TransferWaitlistView from "@cosine/views/TransferWaitlistView.vue";
import { useSeoMeta } from "@unhead/vue";

useSeoMeta({
  title: "Transfers",
});

const {
  flag: transfersFlag,
  isFlagReady,
} = useFlag(UserFeatureFlags.FundsTransfersEnabled, false);
</script>

<style lang="scss" scoped>
.TransfersPage {
  height: 100%;
}
</style>
