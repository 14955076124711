<template>
  <section class="EditUserView">
    <SectionHeader
      title="Profile"
    />

    <EditUserForm
      :profile="mergedProfile"
      :email="currentUser?.Email"
      :disabled="isRequesting"
      @submit="handleSubmit"
    />
  </section>
</template>

<script lang="ts" setup>
import EditUserForm from "@cosine/components/EditUserForm.vue";
import SectionHeader from "@cosine/components/SectionHeader.vue";
import useAppSpinner from "@cosine/composables/useAppSpinner";
import useRequest from "@cosine/composables/useRequest";
import useAuthStore from "@cosine/stores/useAuthStore";
import useUserProfileStore from "@cosine/stores/useUserProfileStore";
import { IUserProfileModel } from "@cosine/types/api-models";
import { storeToRefs } from "pinia";
import { computed, onMounted } from "vue";

const authStore = useAuthStore();
const userProfileStore = useUserProfileStore();
const { fetchUserProfile, updateUserProfile } = userProfileStore;
const { currentUserProfile } = storeToRefs(userProfileStore);
const { currentUser } = storeToRefs(authStore);

const {
  isRequesting,
  handleRequest,
} = useRequest();
useAppSpinner(isRequesting);

async function handleSubmit(updatedProfile: IUserProfileModel) {
  await handleRequest(updateUserProfile(updatedProfile), {
    showAlerts: true,
  });
}

onMounted(() => {
  // TODO: show inline error if fetch fails
  handleRequest(fetchUserProfile());
});

const mergedProfile = computed(() => ({
  ...currentUserProfile.value,
  FirstName: currentUserProfile.value?.FirstName || currentUser.value?.FirstName,
  OtherNames: currentUserProfile.value?.OtherNames || currentUser.value?.LastName,
}));
</script>

<style lang="scss" scoped>
.EditUserView {
  position: relative;
  z-index: 0;
}
</style>
