<template>
  <section
    class="ThreadModal"
    :class="{
      'ThreadModal--isMounted': isMounted,
      [`ThreadModal--visibility${visibility}`]: true,
      'ThreadModal--hasTitle': !!timelineEntry?.Title,
      'ThreadModal--hasMessages': messages.length > 0,
      'ThreadModal--hasChatLimit': isChatLimitVisible,
      'ThreadModal--isFormVisible': isFormVisible,
    }"
  >
    <div
      class="ThreadModal__background"
      @click="$emit('close')"
    />

    <div class="ThreadModal__container">
      <div class="ThreadModal__pane">
        <Transition>
          <header
            v-if="visibility === 'Thread'"
            class="ThreadModal__header"
          >
            <div class="ThreadModal__headerTagContainer">
              <IconTag
                iconName="Hexagon"
                :label="iconLabel"
              />

              <button
                class="ThreadModal__closeButton"
                @click="$emit('close')"
              >
                <Icon
                  name="X"
                />
              </button>
            </div>

            <div class="ThreadModal__headerTitleContainer">
              <div class="ThreadModal__headerTitleMask">
                <Transition>
                  <UIText
                    v-if="timelineEntry?.Title"
                    :key="timelineEntry.Title"
                    class="ThreadModal__headerTitle"
                    size="XLarge"
                    tag="h1"
                    weight="Medium"
                  >
                    {{ timelineEntry.Title }}
                  </UIText>
                </Transition>
              </div>
            </div>

            <LineSpinner
              class="ThreadModal__lineSpinner"
              :isVisible="isLoading"
            />
          </header>
        </Transition>

        <ChatLimitBanner
          v-if="isChatLimitVisible && chatLimitStatus"
          class="ThreadModal__chatLimitBanner"
          :modelValue="chatLimitStatus"
          v-bind="{ canUpgrade }"
          @upgrade="$emit('upgrade')"
        />

        <Transition>
          <ThreadMessageList
            v-if="visibility === 'Thread'"
            class="ThreadModal__messageList"
            v-bind="{
              timelineEntry,
              messages,
              courtesyMessage,
              isAgentProcessing,
            }"
            paddingBottom="var(--formUnderPadding)"
          />
        </Transition>
      </div>

      <Transition>
        <div
          v-if="isFormVisible"
          class="ThreadModal__formContainer"
        >
          <ThreadForm
            ref="formRef"
            :isDisabled="isFormDisabled"
            @focus="$emit('focus')"
            @submit="$emit('submit', $event)"
          />
        </div>
      </Transition>
    </div>
  </section>
</template>

<script lang="ts" setup>
import ChatLimitBanner from "@cosine/components/ChatLimitBanner.vue";
import Icon from "@cosine/components/Icon.vue";
import IconTag from "@cosine/components/IconTag.vue";
import LineSpinner from "@cosine/components/LineSpinner.vue";
import ThreadForm from "@cosine/components/ThreadForm.vue";
import ThreadMessageList from "@cosine/components/ThreadMessageList.vue";
import UIText from "@cosine/components/UIText.vue";
import { ThreadVisibility } from "@cosine/stores/useThreadStore";
import { IClientThreadEntry, IClientTimelineEntry, IFeatureLimitStatus, TimelineEntryFlag, TimelineEntrySources } from "@cosine/types/api-models";
import { computed, nextTick, onBeforeUnmount, onMounted, ref, toRefs, watch } from "vue";

defineEmits<{
  close: [],
  focus: [],
  submit: [message:string],
  upgrade: [],
}>();

const props = withDefaults(defineProps<{
  timelineEntry: IClientTimelineEntry | null,
  messages: Array<IClientThreadEntry>,
  isAgentProcessing?: boolean,
  courtesyMessage?: string | null,
  isLoading?: boolean,
  visibility: ThreadVisibility,
  chatLimitStatus?: IFeatureLimitStatus | null,
  canUpgrade?: boolean,
}>(), {
  isAgentProcessing: false,
  isLoading: false,
  courtesyMessage: null,
  chatLimitStatus: null,
  canUpgrade: false,
});

const {
  timelineEntry,
  visibility,
  chatLimitStatus,
} = toRefs(props);

const formRef = ref<typeof ThreadForm>();
const isMounted = ref(false);

defineExpose({
  formRef,
});

const isChat = computed((): boolean => !isReadOnly.value);
const isChatLimitVisible = computed((): boolean => isFormVisible.value && !!chatLimitStatus.value);
const isReadOnly = computed((): boolean => timelineEntry.value?.Flags?.includes(TimelineEntryFlag.ReadOnly) ?? false);
const isFormVisible = computed((): boolean => visibility.value !== "Hidden" && isChat.value);
const isFormDisabled = computed((): boolean => !!chatLimitStatus.value && chatLimitStatus.value.UsageCount >= chatLimitStatus.value.Limit);

const iconLabel = computed((): string => {
  if (isChat.value) {
    return "Chat";
  }

  if (timelineEntry.value?.Source === TimelineEntrySources.Announcement) {
    return "Announcement";
  }

  return "";  // TODO: other cases? Maybe refactor some logic with TimelineEntryIconTag.formattedSource
});

onMounted(() => {
  isMounted.value = true;
});

onBeforeUnmount(() => {
  isMounted.value = false;
});

watch(visibility, async () => {
  if (visibility.value === "Thread") {
    await nextTick();
    formRef.value?.inputRef.$el.focus();
  }
});
</script>

<style lang="scss" scoped>
.ThreadModal {
  --modalTop: var(--appLogoBottom);
  --modalTransition: 500ms var(--easeOutQuint);
  --headerWithoutTitleHeight: 0px;
  --headerWithTitleHeight: 64px; // TODO: calculate
  --headerPaddingTop: 0px;
  --titlePaddingTop: 16px;
  --titleTransition: 350ms var(--easeInOutCubic);
  --formUnderPadding: 0px;
  --backgroundColor: var(--colorSwissGrey50);

  display: grid;
  justify-items: flex-end;
  position: fixed;
  z-index: 999999;
  inset-inline: 0;
  top: var(--modalTop);
  height: calc(var(--visualViewportHeight) - var(--modalTop));
  transition: top var(--titleTransition);

  &:not(.ThreadModal--visibilityThread) {
    pointer-events: none;
  }

  &.ThreadModal--isFormVisible {
    --formUnderPadding: var(--threadInputHeight);
  }

  @media (max-width: 599px) {
    &.ThreadModal--hasTitle {
      --modalTop: calc(var(--appLogoBottom) - var(--titlePaddingTop));
    }
  }

  @media (min-width: 600px) {
    --modalTop: 0px;
    --headerWithoutTitleHeight: var(--titlePaddingTop);
    --headerPaddingTop: calc(8px + var(--viewportPaddingTop));
  }
}

.ThreadModal__background {
  position: absolute;
  inset: 0;
  z-index: -1;
  background: var(--colorSwissAlphaWhite800);
  backdrop-filter: blur(var(--backgroundBlur));
  transition: opacity var(--mobileThemeTransition);
  cursor: pointer;

  .ThreadModal:not(.ThreadModal--visibilityThread) & {
    opacity: 0;
  }
}

.ThreadModal__container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: var(--layoutMaxWidth);
  height: 100%;
}

.ThreadModal__pane {
  position: absolute;
  inset: 0;
  z-index: 0;
  display: grid;
  grid: min-content auto / auto;

  .ThreadModal--hasChatLimit & {
    grid: min-content min-content auto / auto;
  }

  .ThreadModal--isMounted & {
    transition: transform var(--modalTransition);

    @media (min-width: 600px) {
      transition: 600ms cubic-bezier(0.22, 1, 0.36, 1);
      transition-property: opacity, transform;
    }
  }

  .ThreadModal:not(.ThreadModal--visibilityThread) & {
    transform: translateY(var(--visualViewportHeight));

    @media (min-width: 600px) {
      transform: translateX(64px);
      opacity: 0;
    }
  }

  &::before {
    position: absolute;
    inset: 0;
    z-index: -1;
    background-color: var(--backgroundColor);
    content: "";
  }

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -1px;
    border-left: 1px solid var(--colorSwissGrey100);
    content: "";
  }
}

.ThreadModal__header {
  position: relative;
  padding: var(--headerPaddingTop) 0 0;
  z-index: 1;
  pointer-events: auto;

  &.v-enter-active,
  &.v-leave-active {
    transition: none var(--modalTransition); // using “none” delays the v-if until the duration of the visibility transition, so it’s in sync with the other transitions
  }

  &::before {
    position: absolute;
    inset: 0;
    z-index: -1;
    background-color: var(--backgroundColor);
    content: "";
  }

  &::after {
    position: absolute;
    bottom: 0px;
    width: 100%;
    border-bottom: solid 1px var(--colorSwissGrey100);
    content: "";
  }
}

.ThreadModal__headerTagContainer {
  display: grid;
  grid: auto / auto 24px;
  align-items: center;
  padding: 0 16px;

  @media (max-width: 599px) {
    display: none;
  }
}

.ThreadModal__headerTitleContainer {
  position: relative;
  height: var(--headerWithoutTitleHeight);
  overflow: hidden;
  mask-image: linear-gradient(to bottom, transparent 0px, black 24px, black calc(100% - 24px), transparent 100%);
  transition: height var(--titleTransition);

  .ThreadModal--hasTitle & {
    height: var(--headerWithTitleHeight);
  }
}

.ThreadModal__headerTitleMask {
  height: var(--headerWithTitleHeight);
  padding: var(--titlePaddingTop) 0 20px;
  overflow: hidden;
  mask-image: linear-gradient(to left, transparent 0px, black 48px);
}

.ThreadModal__headerTitle {
  position: absolute;
  left: 16px;
  overflow: hidden;
  white-space: nowrap;
  transition: var(--titleTransition);
  transition-property: opacity, transform;

  &.v-enter-from,
  &.v-leave-to {
    opacity: 0;
  }

  &.v-enter-from {
    transform: translateY(-100%);
  }

  &.v-leave-to {
    transform: translateY(100%);
  }
}

.ThreadModal__lineSpinner {
  position: absolute;
  inset-inline: 0;
  bottom: 0;
  z-index: 1;
}

.ThreadModal__closeButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ThreadModal__messageList {
  height: 100%;
  pointer-events: auto;

  &.v-enter-active,
  &.v-leave-active {
    transition: none var(--modalTransition);
  }
}

.ThreadModal__formContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  pointer-events: auto;
  border-top: 1px solid var(--colorSwissGrey100);

  .EraRoot:not([data-js-os="Android"]) & {
    &.v-enter-active,
    &.v-leave-active {
      transition: var(--modalTransition);
      transition-property: opacity, transform;

      &.v-enter-from {
        transform: translateY(calc(var(--iosKeyboardHeight) + var(--threadInputHeight) + var(--viewportPaddingBottom)));
        opacity: 0;
      }

      &.v-leave-to {
        transform: translateY(calc(1.15 * var(--threadInputHeight) + var(--viewportPaddingBottom)));
        opacity: 0;
      }
    }
  }

  &::before {
    position: absolute;
    inset: 0 0 calc(-1 * var(--iosKeyboardHeight)) 0;
    z-index: -1;
    background-color: var(--backgroundColor);
    content: "";
  }
}
</style>
