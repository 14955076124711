import useApiClient from "@cosine/composables/useApiClient";
import useAuthStore from "@cosine/stores/useAuthStore";
import { FeatureLimitTimePeriod, FeatureLimitType, IApiResponse, IFeatureLimitStatus } from "@cosine/types/api-models";
import { defineStore, storeToRefs } from "pinia";
import { ref, watch } from "vue";

export default defineStore("FeatureLimitStore", () => {
  const { apiClient } = useApiClient();
  const { currentUser } = storeToRefs(useAuthStore());

  const featureLimits = ref<Array<IFeatureLimitStatus>>([]);
  const isFetching = ref(false);

  watch(currentUser, () => {
    if (currentUser.value) {
      fetchFeatureLimits();
    }
  }, {
    immediate: true,
  });

  function getFeatureLimit(type: FeatureLimitType, timePeriod?: FeatureLimitTimePeriod): IFeatureLimitStatus | null {
    return featureLimits.value.find((featureLimit) => {
      return featureLimit.Feature === type && (!timePeriod || featureLimit.TimePeriod === timePeriod);
    }) || null;
  }

  async function fetchFeatureLimits() {
    if (isFetching.value) return;

    isFetching.value = true;
    const { data: { Result: _featureLimits } } = await apiClient.value.get<IApiResponse<Array<IFeatureLimitStatus>>>("/users/me/feature-limits");

    featureLimits.value = _featureLimits || [];
    isFetching.value = false;
  }

  return {
    featureLimits,
    fetchFeatureLimits,
    getFeatureLimit,
  };
});
