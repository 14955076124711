import googleTagManager from "@analytics/google-tag-manager";
import useAuthStore from "@cosine/stores/useAuthStore";
import useRuntimeEnvironment from "@shared/composables/config/useRuntimeEnvironment";
import Analytics from "analytics";
import { defineStore, storeToRefs } from "pinia";
import { watch } from "vue";

export default defineStore("AnalyticsStore", () => {
  const { isDevelopment } = useRuntimeEnvironment();
  const { currentUser } = storeToRefs(useAuthStore());
  const analytics = Analytics({
    plugins: [
      googleTagManager({
        containerId: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID,
        customScriptSrc: "https://www.googletagmanager.com/gtag/js",
        debug: isDevelopment,
      }),
    ],
  });

  const cancelWatch = watch(currentUser, () => {
    if (currentUser.value) {
      analytics.identify(currentUser.value.UserId);
      cancelWatch();
    }
  }, {
    immediate: true,
  });

  return {
    analytics,
  };
});
