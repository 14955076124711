<template>
  <RoutineView>
    <RouterView />
  </RoutineView>
</template>

<script lang="ts" setup>
import RoutineView from "@cosine/views/RoutineView.vue";
import { useSeoMeta } from "@unhead/vue";

useSeoMeta({
  title: "Routines",
});
</script>

<style lang="scss" scoped>
.RoutinesPage {
  height: 100%;
  background-color: var(--colorSwissGrey50);
}
</style>
