<template>
  <TimelineView>
    <RouterView />
  </TimelineView>
</template>

<script lang="ts" setup>
import TimelineView from "@cosine/views/TimelineView.vue";
import { useSeoMeta } from "@unhead/vue";

// TODO: e2e test

useSeoMeta({
  title: "Timeline",
});
</script>

<style lang="scss" scoped>
.TimelinePage {
  height: 100%;
}
</style>
