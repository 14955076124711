<template>
  <section class="BudgetSheetSummary">
    <div class="BudgetSheetSummary__icon">
      <Icon
        name="MoneyMemo"
      />
    </div>
    <div class="BudgetSheetSummary__content">
      <UIText
        class="BudgetSheetSummary__title"
        tag="h3"
        size="XSmall"
        weight="Medium"
      >
        {{ budgetDefinition.Name }}
      </UIText>

      <UIText
        class="BudgetSheetSummary__subtitle"
        tag="p"
        size="XSmall"
        weight="Medium"
      >
        {{ budgetDefinition.Period }}
      </UIText>

      <UIText
        class="BudgetSheetSummary__amount"
        size="XSmall"
        weight="Medium"
      >
        {{ formattedAmount }}
      </UIText>
    </div>
  </section>
</template>

<script lang="ts" setup>
import Icon from "@cosine/components/Icon.vue";
import UIText from "@cosine/components/UIText.vue";
import formatAmount from "@cosine/lib/utils/financial/formatAmount";
import { IBudgetDefinitionModel } from "@cosine/types/api-models";
import { computed, toRefs } from "vue";

const props = defineProps<{
  budgetDefinition: IBudgetDefinitionModel
}>();
const {budgetDefinition} = toRefs(props);

const formattedAmount = computed(() => formatAmount(budgetDefinition.value.DefaultBudgetAmount));
</script>

<style lang="scss" scoped>
.BudgetSheetSummary {
  display: grid;
  grid: auto / min-content auto;
  gap: 0 12px;
}

.BudgetSheetSummary__icon {
  --size: 56px;
  --radius: calc(var(--size) / 2);

  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  aspect-ratio: 1;
  background-color: var(--colorSwissPurple300);
  clip-path: polygon(
    calc(var(--radius) + cos(-90deg) * var(--radius)) calc(var(--radius) + sin(-90deg) * var(--radius)),
    calc(var(--radius) + cos(-30deg) * var(--radius)) calc(var(--radius) + sin(-30deg) * var(--radius)),
    calc(var(--radius) + cos(30deg) * var(--radius)) calc(var(--radius) + sin(30deg) * var(--radius)),
    calc(var(--radius) + cos(90deg) * var(--radius)) calc(var(--radius) + sin(90deg) * var(--radius)),
    calc(var(--radius) + cos(150deg) * var(--radius)) calc(var(--radius) + sin(150deg) * var(--radius)),
    calc(var(--radius) + cos(210deg) * var(--radius)) calc(var(--radius) + sin(210deg) * var(--radius)),
  );
}

.BudgetSheetSummary__content {
  display: grid;
  gap: 2px;
  align-content: center;
}

.BudgetSheetSummary__title,
.BudgetSheetSummary__subtitle {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.BudgetSheetSummary__amount {
  color: var(--colorSwissAlphaBlack600);
}
</style>
