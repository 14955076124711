import useApiClient from "@cosine/composables/useApiClient";
import useStytchStore from "@cosine/stores/useStytchStore";
import { IApiResponse, IUserModel } from "@cosine/types/api-models";
import useLogger from "@shared/composables/useLogger";
import { Session } from "@stytch/vanilla-js";
import { AxiosError, AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { ref } from "vue";

export default defineStore("AuthStore", () => {
  const { apiClient } = useApiClient();
  const { stytchClient } = useStytchStore();

  const currentUser = ref<IUserModel | null>(null);
  const session = ref<Session | null>(null);
  const isFetchingUser = ref(false);
  const fetchingUserPromise = ref<Promise<AxiosResponse<IApiResponse<IUserModel>>> | null>(null);

  async function fetchCurrentUser() {
    if (isFetchingUser.value) { return; }

    try {
      isFetchingUser.value = true;
      fetchingUserPromise.value = apiClient.value.get<IApiResponse<IUserModel>>(`/users/me`);
      const { data: { Result: _currentUser } } = await fetchingUserPromise.value;

      if (_currentUser) {
        currentUser.value = _currentUser;
      }
    } catch (error) {
      const axiosError = error as AxiosError;

      if (axiosError.response && [401, 403].includes(axiosError.response.status)) {
        stytchClient.session.revoke();
      }

      const logger = useLogger();
      logger.error(error);
    } finally {
      isFetchingUser.value = false;
    }
  }

  return {
    currentUser,
    session,
    isFetchingUser,
    fetchingUserPromise,

    fetchCurrentUser,
  };
});
