<template>
  <header
    class="AppHeader"
    @click="scrollToTop"
  >
    <div class="AppHeader__container">
      <h1 class="AppHeader__logo">
        <Logo />
        <UIText
          v-if="badge"
          class="AppHeader__logoBadge"
          :class="{
            [`AppHeader__logoBadge--color${badge.color}`]: true,
          }"
          size="2XSmall"
          weight="Medium"
          @click.stop="$emit('click:badge')"
        >{{ badge.label }}</UIText>
      </h1>

      <div class="AppHeader__buttonContainer">
        <Transition>
          <AppHeaderLogoButton
            v-if="!isThreadOpen"
            class="AppHeader__logoButton"
            v-bind="{ connectionState }"
            :isAnimating="isChatAnimating"
            @click.stop="$emit('click:logoIcon')"
          />

          <button
            v-else
            class="AppHeader__closeButton"
            @click.stop="$emit('close')"
          >
            <Icon
              name="X"
            />
          </button>
        </Transition>
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
import { AppHeaderBadge } from "@cosine/components/AppHeader.types";
import AppHeaderLogoButton from "@cosine/components/AppHeaderLogoButton.vue";
import Icon from "@cosine/components/Icon.vue";
import Logo from "@cosine/components/Logo.vue";
import UIText from "@cosine/components/UIText.vue";
import useTapToScrollToTop from "@cosine/composables/useTapToScrollToTop";
import { HubConnectionState } from "@microsoft/signalr";

defineEmits<{
  "click:logoIcon": [],
  "click:badge": [],
  "close": [],
}>();

withDefaults(defineProps<{
  badge?: AppHeaderBadge,
  connectionState?: HubConnectionState,
  isChatAnimating?: boolean,
  isThreadOpen?: boolean,
}>(), {
  connectionState: HubConnectionState.Connected,
  isChatAnimating: false,
  isThreadOpen: false,
});

const { scrollToTop } = useTapToScrollToTop();
</script>

<style lang="scss" scoped>
.AppHeader {
  display: grid;
  align-items: flex-end;
  height: var(--appLogoHeaderHeight);
  padding: 0 6px 0 16px;
}

.AppHeader__container {
  display: grid;
  grid: auto / auto 40px;
  justify-content: space-between;
  align-items: center;
}

.AppHeader__logo {
  display: flex;
  align-items: center;
  gap: 8px;
}

.AppHeader__logoBadge {
  position: relative;
  top: -1px;
  line-height: 16px;
  padding: 3px 6px;
  background-color: var(--colorSwissAlphaBlack50);
  cursor: pointer;

  &.AppHeader__logoBadge--colorTeal {
    background-color: var(--colorSwissTeal300);
  }
}

.AppHeader__buttonContainer {
  position: relative;
  width: 40px;
  height: 40px;
}

.AppHeader__closeButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.AppHeader__logoButton,
.AppHeader__closeButton {
  position: absolute;
  width: 40px;
  aspect-ratio: 1;
  transition: 350ms var(--easeOutCubic);

  &.v-enter-from,
  &.v-leave-to {
    transform: scale(0);
    opacity: 0;
  }
}
</style>
