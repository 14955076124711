import AppLayout from "@cosine/layouts/AppLayout.vue";
import Accounts from "@cosine/pages/accounts.vue";
import Authenticate from "@cosine/pages/authenticate.vue";
import Budgets from "@cosine/pages/budgets.vue";
import Investing from "@cosine/pages/investing.vue";
import Login from "@cosine/pages/login.vue";
import Markets from "@cosine/pages/markets.vue";
import Routines from "@cosine/pages/routines.vue";
import Settings from "@cosine/pages/settings.vue";
import Timeline from "@cosine/pages/timeline.vue";
import Transactions from "@cosine/pages/transactions.vue";
import Transfers from "@cosine/pages/transfers.vue";
import BudgetListView from "@cosine/views/BudgetListView.vue";
import InvestingBlueprintsView from "@cosine/views/InvestingBlueprintsView.vue";
import investingExploreView from "@cosine/views/InvestingExploreView.vue";
import InvestingListView from "@cosine/views/InvestingListView.vue";
import RoutineExploreView from "@cosine/views/RoutineExploreView.vue";
import RoutineListView from "@cosine/views/RoutineListView.vue";
import TimelineListView from "@cosine/views/TimelineListView.vue";
import { RouteRecordRaw } from "vue-router";

export enum RouteName {
  accounts = "accounts",
  authenticate = "authenticate",
  budgets = "budgets",
  budgetsMonthly = "budgetsMonthly",
  budgetsWeekly = "budgetsWeekly",
  budgetsYearly = "budgetsYearly",
  devtools = "devtools",
  index = "index",
  intro = "intro",
  investing = "investing",
  investingInvestments = "investingInvestments",
  investingBlueprints = "investingBlueprints",
  investingExplore = "investingExplore",
  login = "login",
  markets = "markets",
  routines = "routines",
  routinesYours = "routinesYours",
  routinesExplore = "routinesExplore",
  routinesView = "routinesView",
  routinesSettings = "routinesSettings",
  routinesNew = "routinesNew",
  settings = "settings",
  timeline = "timeline",
  timelineAll = "timelineAll",
  timelineNews = "timelineNews",
  timelineChats = "timelineChats",
  timelineBookmarks = "timelineBookmarks",
  transactions = "transactions",
  transfers = "transfers",
  transfersNew = "transfersNew",
  transfersEdit = "transfersEdit",
  transfersReview = "transfersReview",
  transfersView = "transfersView",
  transfersDemo = "transfersDemo",
  waitlist = "waitlist",
}

// TODO: refactor to remove this and any reference to it
// in favor of using RouteName and only referencing these paths
// in the routes array
export enum RoutePath {
  accounts = "/accounts",
  authenticate = "/authenticate",
  budgets = "/budgets",
  budgetsMonthly = "/budgets/monthly",
  budgetsWeekly = "/budgets/weekly",
  budgetsYearly = "/budgets/yearly",
  devtools = "/devtools",
  index = "/",
  intro = "/intro",
  login = "/login",
  markets = "/markets",
  routines = "/routines",
  settings = "/settings",
  timeline = "/timeline",
  timelineAll = "/timeline/all",
  timelineNews = "/timeline/news",
  timelineChats = "/timeline/chats",
  timelineBookmarks = "/timeline/bookmarks",
  transfers = "/transfers",
  transactions = "/transactions",
  transfersDemo = "/demo/transfers",
  waitlist = "/waitlist",
}

export const UngatedRoutePaths: Array<RoutePath> = [
  RoutePath.login,
  RoutePath.authenticate,
  RoutePath.waitlist,
];

export const routes: Array<RouteRecordRaw> = [
  {
    name: RouteName.login,
    path: RoutePath.login,
    component: Login,
  },
  {
    name: RouteName.authenticate,
    path: RoutePath.authenticate,
    component: Authenticate,
  },
  {
    name: RouteName.waitlist,
    path: RoutePath.waitlist,
    component: () => import("@cosine/pages/waitlist.vue"),
  },
  {
    name: RouteName.intro,
    path: RoutePath.intro,
    component: () => import("@cosine/pages/intro.vue"),
  },
  {
    name: RouteName.devtools,
    path: RoutePath.devtools,
    component: () => import("@cosine/pages/devtools.vue"),
    meta: {
      isEmployeeRequired: true,
      isAllowedInDevelopment: true,
    },
  },
  {
    name: RouteName.transfersDemo,
    path: RoutePath.transfersDemo,
    component: () => import("@cosine/pages/demo/transfers.vue"),
    meta: {
      isEmployeeRequired: true,
    },
  },
  {
    path: "",
    component: AppLayout,
    children: [
      {
        path: "",
        redirect: { name: RouteName.timelineAll },
      },
      {
        name: RouteName.settings,
        path: RoutePath.settings,
        component: Settings,
      },
      {
        name: RouteName.timeline,
        path: RoutePath.timeline,
        redirect: { name: RouteName.timelineAll },
        component: Timeline,
        children: [
          {
            name: RouteName.timelineAll,
            path: RoutePath.timelineAll,
            component: TimelineListView,
          },
          {
            name: RouteName.timelineNews,
            path: RoutePath.timelineNews,
            component: TimelineListView,
          },
          {
            name: RouteName.timelineChats,
            path: RoutePath.timelineChats,
            component: TimelineListView,
          },
          {
            name: RouteName.timelineBookmarks,
            path: RoutePath.timelineBookmarks,
            component: TimelineListView,
          },
        ],
      },
      {
        name: RouteName.routines,
        path: RoutePath.routines,
        redirect: { name: RouteName.routinesYours },
        component: Routines,
        children: [
          {
            name: RouteName.routinesYours,
            path: "/routines/list",
            component: RoutineListView,
            children: [
              {
                name: RouteName.routinesView,
                path: "/routines/:id",
                component: { template: "" },
              },
              {
                name: RouteName.routinesSettings,
                path: "/routines/:id/settings",
                component: { template: "" },
              },
            ],
          },
          {
            name: RouteName.routinesExplore,
            path: "/routines/explore",
            component: RoutineExploreView,
            children: [
              {
                name: RouteName.routinesNew,
                path: "/routines/:slug/new",
                component: { template: "" },
              },
            ],
          },
        ],
      },
      {
        name: RouteName.transfers,
        path: RoutePath.transfers,
        component: Transfers,
        children: [
          {
            name: RouteName.transfersNew,
            path: "/transfers/new",
            component: {
              template: "",
            },
          },
          {
            name: RouteName.transfersView,
            path: "/transfers/:id",
            component: {
              template: "",
            },
          },
          {
            name: RouteName.transfersEdit,
            path: "/transfers/:id/edit",
            component: {
              template: "",
            },
          },
          {
            name: RouteName.transfersReview,
            path: "/transfers/:id/review",
            component: {
              template: "",
            },
          },
        ],
      },
      {
        name: RouteName.budgets,
        path: RoutePath.budgets,
        redirect: { path: RoutePath.budgetsWeekly },
        component: Budgets,
        children: [
          {
            name: RouteName.budgetsWeekly,
            path: RoutePath.budgetsWeekly,
            component: BudgetListView,
          },
          {
            name: RouteName.budgetsMonthly,
            path: RoutePath.budgetsMonthly,
            component: BudgetListView,
          },
          {
            name: RouteName.budgetsYearly,
            path: RoutePath.budgetsYearly,
            component: BudgetListView,
          },
        ],
      },
      {
        name: RouteName.transactions,
        path: RoutePath.transactions,
        component: Transactions,
      },
      {
        name: RouteName.accounts,
        path: RoutePath.accounts,
        component: Accounts,
      },
      {
        name: RouteName.markets,
        path: RoutePath.markets,
        component: Markets,
      },
      {
        name: RouteName.investing,
        path: "/investing",
        component: Investing,
        redirect: { name: RouteName.investingInvestments },
        children: [
          {
            name: RouteName.investingInvestments,
            path: "/investing/list",
            component: InvestingListView,
          },
          {
            name: RouteName.investingBlueprints,
            path: "/investing/blueprints",
            component: InvestingBlueprintsView,
          },
          {
            name: RouteName.investingExplore,
            path: "/investing/explore",
            component: investingExploreView,
          },
        ],
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@cosine/pages/404.vue"),
  },
];
