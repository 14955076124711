<template>
  <ToastAlert
    status="Negative"
    :isVisible="hasError"
    @close="hideError"
  />
  <ToastAlert
    status="Positive"
    :isVisible="hasSuccess"
    @close="hideSuccess"
    @cancel="cancelSuccessTimeout"
  />
</template>

<script lang="ts" setup>
import ToastAlert from "@cosine/components/ToastAlert.vue";
import useAlertStore from "@cosine/stores/useAlertStore";
import { storeToRefs } from "pinia";

const alertStore = useAlertStore();
const {
  hideSuccess,
  cancelSuccessTimeout,
  hideError,
} = alertStore;
const {hasSuccess, hasError} = storeToRefs(alertStore);
</script>
