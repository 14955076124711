import pluralize from "@cosine/lib/utils/string/pluralize";

const labels = ["hour", "minute", "second"];

export default function (interval: string): string {
  const counts = interval.split(":").map((value) => parseInt(value));

  return counts.reduce<Array<string>>((acc, count, i) => {
    const formattedInterval = formatIntervalPart(count, labels[i], { isFirst: acc.length === 0 });

    if (formattedInterval) {
      acc.push(formattedInterval);
    }

    return acc;
  }, []).join(", ");
}

function formatIntervalPart(count: number, label: string, { isFirst }: { isFirst: boolean; }) {
  if (count === 0) return;

  return [
    isFirst && "every",
    (!isFirst || count > 1) && count,
    pluralize(count, label, `${label}s`, { excludeCount: true }),
  ].filter(Boolean).join(" ");
}
