<template>
  <section class="RoutineView">
    <TabNavView
      :items="tabNavItems"
      isExact
      isSubNav
    />

    <ScrollContainer
      viewport="Mobile"
    >
      <slot />
    </ScrollContainer>

    <Teleport to="body">
      <RoutineSheetModalView />
      <NewRoutineModalView />
    </Teleport>
  </section>
</template>

<script lang="ts" setup>
import ScrollContainer from "@cosine/components/ScrollContainer.vue";
import { TabNavItem } from "@cosine/components/TabNav.types";
import { RouteName } from "@cosine/routes";
import NewRoutineModalView from "@cosine/views/NewRoutineModalView.vue";
import RoutineSheetModalView from "@cosine/views/RoutineSheetModalView.vue";
import TabNavView from "@cosine/views/TabNavView.vue";
import { ref } from "vue";

const tabNavItems = ref<Array<TabNavItem>>([
  { label: "Your routines", to: { name: RouteName.routinesYours } },
  { label: "Explore", to: { name: RouteName.routinesExplore } },
]);
</script>

<style lang="scss" scoped>
.RoutineView {
  display: grid;
  grid: min-content auto / auto;
  height: 100%;
}
</style>
