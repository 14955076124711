<template>
  <Transition>
    <SheetModal
      v-if="isTransactionSheetModalOpen && editingTransaction"
      class="TransactionSheetModalView"
      theme="Light"
      @close="finishEditingTransaction"
    >
      <template #summary>
        <TransactionSheetSummary
          :transaction="editingTransaction"
        />
      </template>
      <template #form>
        <TransactionForm
          :transaction="editingTransaction"
          :isDisabled="isSavingTransaction"
          @submit="handleSubmit"
        />
      </template>
    </SheetModal>
  </Transition>
</template>

<script lang="ts" setup>
import SheetModal from "@cosine/components/SheetModal.vue";
import TransactionForm from "@cosine/components/TransactionForm.vue";
import TransactionSheetSummary from "@cosine/components/TransactionSheetSummary.vue";
import useRequest from "@cosine/composables/useRequest";
import useModalStore from "@cosine/stores/useModalStore";
import { ModalName } from "@cosine/stores/useModalStore.types";
import useTransactionStore from "@cosine/stores/useTransactionStore";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const {
  handleRequest,
  isRequesting: isSavingTransaction,
} = useRequest();
const { isModalOpen } = useModalStore();
const transactionStore = useTransactionStore();
const { editingTransaction } = storeToRefs(transactionStore);
const {
  finishEditingTransaction,
  updateTransaction,
} = transactionStore;

const isTransactionSheetModalOpen = computed(() => isModalOpen(ModalName.EditTransaction));

async function handleSubmit(form: {category: string}) {
  // TODO: confirm when closing the modal while saving
  if (!editingTransaction.value) return;

  await handleRequest(
    updateTransaction({
      id: editingTransaction.value.EraTransactionId,
      category: form.category,
    }), {
      showAlerts: true,
    },
  );
}
</script>
