export enum Country {
  "US" = "US",
  "GB" = "GB",
  "CA" = "CA",
  "AU" = "AU",
  "ES" = "ES",
  "MX" = "MX",
  "FR" = "FR",
  "DE" = "DE",
  "IT" = "IT",
  "JP" = "JP",
}

export const Countries: { [key in Country]: string } = {
  "US": "United States",
  "GB": "United Kingdom",
  "CA": "Canada",
  "AU": "Australia",
  "ES": "Spain",
  "MX": "Mexico",
  "FR": "France",
  "DE": "Germany",
  "IT": "Italy",
  "JP": "Japan",
};
