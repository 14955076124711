<template>
  <section class="ConnectAccountsView">
    <FormButton
      variant="Primary"
      class="ConnectAccountsView__button"
      @click="handleModalOpen"
    >Link accounts</FormButton>

    <Teleport to="body">
      <Transition @afterLeave="handleModalLeave">
        <MxWidgetModal
          v-show="isModalOpen(ModalName.ConnectAccounts)"
          @close="closeMxModal"
        >
          <MxSecurityScreen
            v-if="viewState === 'Security'"
            @click:continue="handleSecurityContinue"
          />
          <div
            v-show="viewState === 'Connect'"
            ref="mxWidgetEl"
            class="ConnectAccountsView__widget"
            :class="{
              'ConnectAccountsView__widget--isLoading': isRequesting,
            }"
          />
          <Spinner
            :isVisible="viewState === 'Connect' &&  isRequesting"
            isCentered
          />
        </MxWidgetModal>
      </Transition>
    </Teleport>
  </section>
</template>

<script lang="ts" setup>
import FormButton from "@cosine/components/FormButton.vue";
import MxSecurityScreen from "@cosine/components/MxSecurityScreen.vue";
import MxWidgetModal from "@cosine/components/MxWidgetModal.vue";
import Spinner from "@cosine/components/Spinner.vue";
import useMxConnectWidget from "@cosine/composables/useMxConnectWidget";
import useRequest from "@cosine/composables/useRequest";
import capitalize from "@cosine/lib/utils/string/capitalize";
import { RouteName } from "@cosine/routes";
import useFinancialStore from "@cosine/stores/useFinancialStore";
import useModalStore from "@cosine/stores/useModalStore";
import { ModalName } from "@cosine/stores/useModalStore.types";
import { IMxWidgetUrlRequest, MxWidgetMode } from "@cosine/types/api-models";
import useLogger from "@shared/composables/useLogger";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const {createMxAccount} = useFinancialStore();
const { openModal, closeModal, isModalOpen } = useModalStore();
const mxWidgetEl = ref<HTMLElement>();
const logger = useLogger();

const {
  handleRequest,
  isRequesting,
} = useRequest();
const {
  mountMxWidget,
  unmountMxWidget,
  onMemberConnected,
} = useMxConnectWidget(mxWidgetEl);

const viewState = ref<"Security" | "Connect">(
  router.currentRoute.value.query.mode !== MxWidgetMode.Verification
    ? "Security"
    : "Connect",
);

onMounted(async () => {
  const query = router.currentRoute.value.query;

  if (Object.keys(query).includes("connect")) {
    const options: IMxWidgetUrlRequest = {};
    if (query.connect) {
      options.MemberGuid = query.connect.toString();
    }
    if (query.mode) {
      options.Mode = MxWidgetMode[capitalize(query.mode.toString()) as keyof typeof MxWidgetMode];
      if (!options.Mode) logger.warn(`Invalid MxWidgetMode "${options.Mode}"`);
    }
    if (query.identity?.toString().toLowerCase() === "true") {
      options.IncludeIdentity = true;
    }
    openModal(ModalName.ConnectAccounts);
    await handleRequest(mountMxWidget(options));
  }
});

onMemberConnected(async (payload, widgetOptions) => {
  await handleRequest(createMxAccount(payload.member_guid, widgetOptions.Mode));
  closeMxModal();

  if (widgetOptions.Mode === MxWidgetMode.Verification) {
    router.push({name: RouteName.transfers});
  }
});

function handleSecurityContinue() {
  viewState.value = "Connect";
}

// TODO: replace with modal watcher to handle close
function closeMxModal() {
  closeModal(ModalName.ConnectAccounts);

  const query = { ...router.currentRoute.value.query };
  delete query.connect;
  router.replace({ query });
}

function handleModalLeave() {
  unmountMxWidget();
}

async function openMxModal() {
  viewState.value = "Security";
  openModal(ModalName.ConnectAccounts);
  await handleRequest(mountMxWidget());
}

function handleModalOpen() {
  openMxModal();
}
</script>

<style lang="scss" scoped>
.ConnectAccountsView {
  display: grid;
}

.ConnectAccountsView__widget {
  // MX takes ~2s to finish animating (or rather jittering) in, so this at least smooths that out.
  transition: opacity 2000ms var(--easeInOutCubic);

  &.ConnectAccountsView__widget--isLoading {
    opacity: 0;
  }
}
</style>
