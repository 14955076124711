<template>
  <div
    class="CurrencyInput"
    :class="{
      [`CurrencyInput--variant${variant}`]: !!variant,
    }"
  >
    <UIText
      class="CurrencyInput__placeholder"
      v-bind="textStyles"
    >
      {{ formattedValue || formattedPlaceholder }}
    </UIText>
    <UIText
      ref="inputRef"
      class="CurrencyInput__input"
      tag="input"
      v-bind="textStyles"
      inputmode="decimal"
      :value="formattedValue"
      :placeholder="formattedPlaceholder"
      :spellcheck="false"
      autocomplete="off"
      @input="handleInput"
      @focus="handleFocus"
      @blur="handleBlur"
      required
    />
  </div>
</template>

<script lang="ts" setup>
import { CurrencyInputVariant } from "@cosine/components/CurrencyInput.types";
import UIText from "@cosine/components/UIText.vue";
import formatAmount from "@cosine/lib/utils/financial/formatAmount";
import { unformatAmount } from "@cosine/lib/utils/financial/unformatAmount";
import { TextSize, TextWeight } from "@shared/components/Text.types";
import Text from "@shared/components/Text.vue";
import { computed, onMounted, ref, toRefs, watch } from "vue";

const emit = defineEmits<{
  "update:modelValue": [number | null]
}>();

// TODO: support multiple currencies
const props = withDefaults(defineProps<{
  modelValue: number | null,
  variant?: keyof typeof CurrencyInputVariant,
  autofocus?: boolean;
}>(), {
  variant: "Input",
  autofocus: false,
});
const {
  modelValue,
  variant,
  autofocus,
} = toRefs(props);

const inputRef = ref<typeof Text>();
const isFocused = ref(false);
const formattedValue = ref<string | null>(null);
const formattedPlaceholder = computed(() => formatAmount(0));

// Only doing this because the styles are shared between the input & placeholder.
// Normally, we’d just use CSS, so don’t do this elsewhere.
const textStyles = computed(() => {
  if (variant.value === "Inline") {
    return {
      size: TextSize["3XLarge"],
      weight: TextWeight.Medium,
    };
  }

  return {
    size: TextSize.Small,
  };
});

onMounted(() => {
  updateFormattedValue();

  if (inputRef.value && autofocus.value) {
    inputRef.value.$el.focus();
  }
});

watch(modelValue, () => {
  if (isFocused.value) return;
  updateFormattedValue();
});

function updateFormattedValue () {
  formattedValue.value = formatAmount(modelValue.value);
}

function handleInput() {
  const inputEl = inputRef.value?.$el;
  if (!inputEl) return;

  const newValue = unformatAmount(inputEl.value);

  if (newValue !== modelValue.value) {
    emit("update:modelValue", newValue);
  }
}

function handleFocus() {
  isFocused.value = true;
}

function handleBlur() {
  isFocused.value = false;
  updateFormattedValue();
}
</script>

<style lang="scss" scoped>
.CurrencyInput {
  flex: 1 1 auto;
  position: relative;
  display: flex;
  align-items: center;
}

.CurrencyInput--variantInput {
  --padding: 16px;

  border: solid 1px var(--colorSwissGrey100);
  background: var(--colorWhite);

  &:focus-within {
    border-color: var(--colorSwissGrey200);
  }
}

.CurrencyInput--variantInline {
  --padding: 0 var(--layoutMargin);
}

.CurrencyInput__placeholder {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  padding: var(--padding);
  white-space: nowrap;
  overflow: hidden;
  pointer-events: none;

  [data-js-mobile-keyboard-state="open"] & {
    opacity: 0;
  }

  @media (min-width: 600px) {
    display: none;
  }
}

.CurrencyInput__input {
  width: 100%;
  padding: var(--padding);
  border: none;
  background: none;
  outline: none;

  @media (max-width: 599px) {
    // This prevents mobile browsers from pushing the viewport off the screen when the keyboard opens
    [data-js-mobile-keyboard-state="closed"] & {
      opacity: 0;
    }
  }
}
</style>
