import { RoutePath } from "@cosine/routes";
import useRuntimeEnvironment from "@shared/composables/config/useRuntimeEnvironment";

export default function useAuthConfig() {
  const { cosineBaseUrl } = useRuntimeEnvironment();
  const authenticateUrl = `${cosineBaseUrl}${RoutePath.authenticate}`;
  const authExpirationMinuteCount = 15;

  return {
    authenticateUrl,
    authExpirationMinuteCount,
  };
}
