import { defineStore } from "pinia";
import { computed, ref } from "vue";

export default defineStore("AlertStore", () => {
  const successMessage = ref<any>(null);
  const successTimeoutId = ref<NodeJS.Timeout>();
  const error = ref<Error | null>(null);

  const hasSuccess = computed(() => !!successMessage.value);
  const hasError = computed(() => !!error.value);

  function cancelSuccessTimeout() {
    clearTimeout(successTimeoutId.value);
  }

  function showSuccess() {
    cancelSuccessTimeout();

    successMessage.value = {}; // TODO - improve to allow custom messages
    successTimeoutId.value = setTimeout(hideSuccess, 2000);
  }

  function hideSuccess() {
    cancelSuccessTimeout();
    successMessage.value = null;
  }

  function showError(_error: Error) {
    error.value = _error;
  }

  function hideError() {
    error.value = null;
  }

  return {
    showSuccess,
    hideSuccess,
    cancelSuccessTimeout,
    showError,
    hideError,
    hasSuccess,
    hasError,
  };
});
