import useEnvironment from "@shared/composables/config/useEnvironment";

export default function useRuntimeEnvironment() {
  const { VITE_ENVIRONMENT, VITE_IS_TUNNELING, VITE_TUNNEL_COSINE_BASE_URL, VITE_TUNNEL_API_BASE_URL } = import.meta.env;
  const environment = useEnvironment(String(VITE_ENVIRONMENT));

  if (VITE_IS_TUNNELING) {
    environment.cosineBaseUrl = String(VITE_TUNNEL_COSINE_BASE_URL);
    environment.apiBaseUrl = String(VITE_TUNNEL_API_BASE_URL);
  }

  return environment;
}
