<template>
  <RoutineListItem
    class="RoutineCard"
    :title="modelValue.Description || ''"
    :subtitle="formattedInterval"
    :iconColor="!modelValue.IsDisabled ? 'Yellow' : 'Grey'"
    v-bind="{iconName}"
  >
    <div
      v-if="isTransferRoutine"
      class="RoutineCard__accountMeta"
    >
      <UIText
        class="RoutineCard__account--source"
        size="2XSmall"
        weight="Medium"
      >
        <AccountLabel
          :modelValue="sourceAccount"
          prefix="From:"
        />
      </UIText>

      <UIText
        class="RoutineCard__account--destination"
        size="2XSmall"
        weight="Medium"
      >
        <AccountLabel
          :modelValue="destinationAccount"
          prefix="To:"
        />
      </UIText>
    </div>
  </RoutineListItem>
</template>

<script lang="ts" setup>
import AccountLabel from "@cosine/components/AccountLabel.vue";
import { BoxIconName } from "@cosine/components/BoxIcon.types";
import RoutineListItem from "@cosine/components/RoutineListItem.vue";
import UIText from "@cosine/components/UIText.vue";
import { formatCronExpression } from "@cosine/lib/utils/string/formatCronExpression";
import { IAbstractFundsTransferRoutineArgs, IEnrichedAccountModel, IRoutineModel, RoutineTypes } from "@cosine/types/api-models";
import { computed, toRefs } from "vue";

const props = withDefaults(defineProps<{
  modelValue: IRoutineModel,
  iconName?: keyof typeof BoxIconName,
  accountMap?: Map<string, IEnrichedAccountModel>,
}>(), {
  iconName: "ArrowCycleHexagon",
});
const {
  modelValue,
  accountMap,
} = toRefs(props);

const formattedInterval = computed(() => {
  return formatCronExpression(modelValue.value.CronExpression) || null;
});

const isTransferRoutine = computed(() => {
  return [
    RoutineTypes.UserFixedAmountFundsTransfer,
    RoutineTypes.UserThresholdBasedFundsTransfer,
    RoutineTypes.UserRoundUpSavingsFundsTransfer,
  ].includes(modelValue.value.Type);
});

const transferArgs = computed(() => {
  if (!modelValue.value.Args) return;

  if (isTransferRoutine.value) {
    return modelValue.value.Args as IAbstractFundsTransferRoutineArgs;
  }

  return null;
});

const sourceAccount = computed(() => {
  if (!accountMap.value || !transferArgs.value) return null;

  return accountMap.value.get(transferArgs.value.SourceEraAccountId) || null;
});

const destinationAccount = computed(() => {
  if (!accountMap.value || !transferArgs.value) return null;

  return accountMap.value.get(transferArgs.value.DestinationEraAccountId) || null;
});
</script>

<style lang="scss" scoped>
.RoutineCard__accountMeta {
  display: grid;
  gap: 4px;
}
</style>
