<template>
  <div class="SelectInput">
    <FormInput
      class="SelectInput__input"
      tag="select"
      v-bind="{
        disabled,
        required,
        name,
        readonly,
      }"
      :value="modelValue"
      @change="handleChange"
    >
      <slot />
    </FormInput>

    <Icon
      class="SelectInput__arrow"
      name="Pencil"
    />
  </div>
</template>

<script lang="ts" setup>
import FormInput from "@cosine/components/FormInput.vue";
import Icon from "@cosine/components/Icon.vue";

withDefaults(defineProps<{
  disabled?: boolean,
  modelValue?: string | null,
  name?: string,
  required?: boolean,
  readonly?: boolean,
}>(), {
  modelValue: null,
  required: false,
  type: "text",
});

const emit = defineEmits<{
  "update:modelValue": [string],
}>();

function handleChange(event: Event) {
  const target = event.target as HTMLInputElement;

  emit("update:modelValue", target.value);
}
</script>

<style scoped lang="scss">
.SelectInput {
  position: relative;
}

.SelectInput__input {
  width: 100%;
  font-weight: var(--fontWeightSaansMedium);

  &:not(:disabled) {
    cursor: pointer;
  }
}

.SelectInput__arrow {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity var(--defaultMobileTransition);

  .SelectInput__input:disabled + & {
    opacity: 0.2;
  }
}
</style>
