<template>
  <section class="FinancialAccountsView">
    <AlertBanner
      v-if="isSyncingAccounts"
      status="Positive"
      title="Sync in progress"
      body="Your accounts are currently being updated"
      iconName="Sync"
    />

    <SectionHeader
      v-if="accounts.length > 0"
      title="Accounts"
      :subtitle="`${accounts.length} connected`"
    />
    <FinancialAccountList
      v-if="activeAccounts.length > 0"
      title="Active"
      :subtitle="activeSubtitle"
      iconName="CheckSmall"
      iconColor="Teal"
      :accounts="activeAccounts"
      :includeUpgrade="canUpgrade"
      includeSyncDates
      @click:account="handleAccountClick"
      @click:upgrade="handleUpgradeClick"
    />
    <FinancialAccountList
      v-if="inactiveAccounts.length > 0"
      title="Inactive"
      :subtitle="inactiveSubtitle"
      iconName="EyeCrossed"
      :accounts="inactiveAccounts"
      includeSyncDates
      @click:account="handleAccountClick"
    />
    <FeatureCard
      :iconName="accounts.length === 0 ? 'Bank' : undefined"
      :title="accounts.length === 0 ? 'Get started' : 'Add more accounts'"
      body="Connect your checking accounts, credit cards, and 401Ks for Era to analyze your spending, provide balance monitoring, and personalize news alerts to your financial picture."
      color="Green"
    >
      <ConnectAccountsView />
    </FeatureCard>
  </section>
</template>

<script lang="ts" setup>
import AlertBanner from "@cosine/components/AlertBanner.vue";
import FeatureCard from "@cosine/components/FeatureCard.vue";
import FinancialAccountList from "@cosine/components/FinancialAccountList.vue";
import SectionHeader from "@cosine/components/SectionHeader.vue";
import useAppSpinner from "@cosine/composables/useAppSpinner";
import useFlag from "@cosine/composables/useFlag";
import useRequest from "@cosine/composables/useRequest";
import pluralize from "@cosine/lib/utils/string/pluralize";
import useAuthStore from "@cosine/stores/useAuthStore";
import useFinancialStore from "@cosine/stores/useFinancialStore";
import useSubscriptionStore from "@cosine/stores/useSubscriptionStore";
import { AccountScope, IEnrichedAccountModel, UserFeatureFlags } from "@cosine/types/api-models";
import ConnectAccountsView from "@cosine/views/ConnectAccountsView.vue";
import { storeToRefs } from "pinia";
import { computed, onMounted } from "vue";

const { currentUser } = storeToRefs(useAuthStore());
const subscriptionStore = useSubscriptionStore();
const {
  canUpgrade,
  isSubscribed,
} = storeToRefs(subscriptionStore);
const { startEditingSubscription } = subscriptionStore;
const financialStore = useFinancialStore();
const {
  accounts,
  isSyncingAccounts,
} = storeToRefs(financialStore);
const {
  fetchAccounts,
  startEditingAccount,
} = financialStore;

const {flag:maxConnectedAccountsFlag} = useFlag(UserFeatureFlags.MaxConnectedAccounts, -1);
const {
  handleRequest,
  isRequesting,
} = useRequest();
useAppSpinner(isRequesting);

onMounted(async () => {
  await handleRequest(fetchAccounts());
});

function pluralizeAccounts(count: number) {
  return pluralize(count, "account", "accounts");
}

const activeSubtitle = computed((): string => {
  if (maxConnectedAccountsFlag.value !== -1) {

    return `${activeAccounts.value.length} of ${maxConnectedAccountsFlag.value} (${isSubscribed.value ? "Plus" : "Trial"})`;
  }

  return pluralizeAccounts(activeAccounts.value.length);
});

const inactiveSubtitle = computed((): string => {
  return pluralizeAccounts(inactiveAccounts.value.length);
});

const activeAccounts = computed(() => {
  return accounts.value.filter((account) => account.Scope === AccountScope.Normal);
});

const inactiveAccounts = computed(() => {
  return accounts.value.filter((account) => account.Scope === AccountScope.Limited);
});

function handleAccountClick(account: IEnrichedAccountModel) {
  startEditingAccount(account);
}

function handleUpgradeClick() {
  startEditingSubscription(currentUser.value?.Subscription || null);
}
</script>
