import useApiClient from "@cosine/composables/useApiClient";
import useEditModal from "@cosine/composables/useEditModal";
import useFlag from "@cosine/composables/useFlag";
import useBackgroundJobStore from "@cosine/stores/useBackgroundJobStore";
import { ModalName } from "@cosine/stores/useModalStore.types";
import { AccountScope, AccountType, BackgroundJobStatus, BackgroundJobType, IApiResponse, IEnrichedAccountModel, IUpdateEnrichedAccountScopeRequestBody, MxWidgetMode, UserFeatureFlags } from "@cosine/types/api-models";
import { defineStore, storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";

export default defineStore("FinancialStore", () => {
  const backgroundStore = useBackgroundJobStore();
  const { backgroundJobMap } = storeToRefs(backgroundStore);
  const { flag: maxConnectedAccountsFlag } = useFlag(UserFeatureFlags.MaxConnectedAccounts, -1);
  const { apiClient } = useApiClient();
  const {
    editingItem: editingAccount,
    startEditingItem: startEditingAccount,
    finishEditingItem: finishEditingAccount,
  } = useEditModal<IEnrichedAccountModel>(ModalName.EditAccount);
  const isWaitingForAccounts = ref(false);

  const accounts = ref<Array<IEnrichedAccountModel>>([]);
  const accountMap = computed(() => {
    return new Map(accounts.value.map((account) => {
      return [account.EraAccountId, account];
    }));
  });
  const sortedAccounts = computed(() => {
    return [...accounts.value].sort((a, b) => {
      if (a.Scope === AccountScope.Normal && b.Scope === AccountScope.Limited) return -1;
      if (a.Scope === AccountScope.Limited && b.Scope === AccountScope.Normal) return 1;

      if (a.Type !== AccountType.Any && b.Type === AccountType.Any) return -1;
      if (a.Type === AccountType.Any && b.Type !== AccountType.Any) return 1;
      if (a.Type === b.Type) return a.DisplayName.localeCompare(b.DisplayName);

      return a.Type.localeCompare(b.Type);
    });
  });

  const activeAccounts = computed(() => {
    return sortedAccounts.value.filter((account) => account.Scope === AccountScope.Normal);
  });

  const canActivateMoreAccounts = computed((): boolean => {
    if (maxConnectedAccountsFlag.value === -1) return true;

    return activeAccounts.value.length < maxConnectedAccountsFlag.value;
  });

  const backgroundJobStatus = computed((): BackgroundJobStatus => {
    return backgroundJobMap.value.get(BackgroundJobType.EnrichedAccountsSync)?.Status || BackgroundJobStatus.NotStarted;
  });

  const isSyncingAccounts = computed(() => {
    return isWaitingForAccounts.value || backgroundJobStatus.value === BackgroundJobStatus.InProgress;
  });

  watch(backgroundJobStatus, () => {
    if (backgroundJobStatus.value === BackgroundJobStatus.Completed) {
      fetchAccounts();
    }
  });

  watch(accounts, (newAccounts, oldAccounts) => {
    if (newAccounts.length > oldAccounts.length) {
      isWaitingForAccounts.value = false;
    }
  });

  async function createMxAccount(memberGuid: string, connectionMode?: MxWidgetMode) {
    await apiClient.value.post("/mx/users/me/store-data", { MemberGuid: memberGuid, ConnectionMode: connectionMode });

    if (connectionMode !== MxWidgetMode.Verification) {
      isWaitingForAccounts.value = true;
    }
  }

  async function fetchAccounts() {
    const { data: { Result: _accounts } } = await apiClient.value.get<IApiResponse<Array<IEnrichedAccountModel>>>(`/finances/accounts`);
    accounts.value = _accounts || [];
  }

  async function updateAccountScope(account: IEnrichedAccountModel, scope: AccountScope) {
    const params: IUpdateEnrichedAccountScopeRequestBody = {
      Scope: scope,
    };

    const { data: { Result: updatedAccount } } = await apiClient.value.put<IApiResponse<IEnrichedAccountModel>>(`/finances/accounts/${account.EraAccountId}/scope`, params);

    const existingAccount = accounts.value.find(({ EraAccountId }) => {
      return EraAccountId === account.EraAccountId;
    });

    if (existingAccount && updatedAccount) {
      existingAccount.Scope = updatedAccount.Scope;
    }
  }

  return {
    accounts: sortedAccounts,
    activeAccounts,
    accountMap,
    isSyncingAccounts,
    editingAccount,
    canActivateMoreAccounts,

    createMxAccount,
    fetchAccounts,
    startEditingAccount,
    finishEditingAccount,
    updateAccountScope,
  };
});
