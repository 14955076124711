export enum Environment {
  Development = "development",
  Test = "test",
  PreProd = "pre-prod",
  Production = "prod",
  Unknown = "unknown",
}

export default function useEnvironment(env: string) {
  const currentEnvironment = (() => {
    switch (env) {
      case Environment.Development:
        return Environment.Development;
      case Environment.Test:
        return Environment.Test;
      case Environment.PreProd:
        return Environment.PreProd;
      case Environment.Production:
        return Environment.Production;
      default:
        return Environment.Unknown;
    }
  })();

  const isDevelopment = currentEnvironment === Environment.Development;
  const isTest = currentEnvironment === Environment.Test;
  const isPreProd = currentEnvironment === Environment.PreProd;
  const isProduction = currentEnvironment === Environment.Production;

  const cosineBaseUrl: string = (() => {
    switch (currentEnvironment) {
      case Environment.Development:
        return "https://localhost:3000";
      case Environment.PreProd:
        return "https://my.tinwell.dev";
      case Environment.Production:
        return "https://my.era.app";
      case Environment.Test:
        return "https://localhost:3000";
      default:
        return "http://localhost:0"; // use port 0 to indicate we're in an unexpected environment for debugging purposes
    }
  })();

  const wwwBaseUrl: string = (() => {
    switch (currentEnvironment) {
      case Environment.Development:
        return "https://localhost:3001";
      case Environment.PreProd:
        return "https://tinwell.dev";
      case Environment.Production:
        return "https://era.app";
      case Environment.Test:
        return "https://localhost:3001";
      default:
        return "http://localhost:0"; // use port 0 to indicate we're in an unexpected environment for debugging purposes
    }
  })();

  const apiBaseUrl: string = (() => {
    switch (currentEnvironment) {
      case Environment.Development:
        return "https://localhost:7141";
      case Environment.PreProd:
        return "https://api.tinwell.dev";
      case Environment.Production:
        return "https://api.era.app";
      case Environment.Test:
        return "https://localhost:7141";
      default:
        return "http://localhost:0"; // use port 0 to indicate we're in an unexpected environment for debugging purposes
    }
  })();

  return {
    currentEnvironment,
    isDevelopment,
    isTest,
    isPreProd,
    isProduction,
    cosineBaseUrl,
    wwwBaseUrl,
    apiBaseUrl,
  };
}
