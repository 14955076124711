<template>
  <SheetSelectButton
    :iconName="modelValue.length > 0 ? 'Pencil' : 'ArrowRight'"
    label="Categories"
    @click="$emit('update:viewState', TransactionCategorySelectViewState.CategorySelect)"
  >
    <span
      v-if="modelValue.length > 0"
    >{{ pluralize(modelValue.length, "category", "categories") }}</span>
    <span v-else>Select</span>
  </SheetSelectButton>

  <Teleport :to="portal">
    <Transition>
      <TransactionCategorySelectScreenView
        v-if="viewState === TransactionCategorySelectViewState.CategorySelect"
        :modelValue="modelValue"
        @update:modelValue="$emit('update:modelValue', $event)"
        @click:back="$emit('update:viewState', null)"
      />
    </Transition>
  </Teleport>
</template>

<script lang="ts" setup>
import SheetSelectButton from "@cosine/components/SheetSelectButton.vue";
import pluralize from "@cosine/lib/utils/string/pluralize";
import { TransactionCategory } from "@cosine/stores/useTransactionStore.types";
import TransactionCategorySelectScreenView from "@cosine/views/TransactionCategorySelectScreenView.vue";
import { TransactionCategorySelectViewState } from "@cosine/views/TransactionCategorySelectView.types";
import { toRefs } from "vue";

defineEmits<{
  "update:modelValue": [Array<TransactionCategory>],
  "update:viewState": [TransactionCategorySelectViewState | null],
}>();

const props = defineProps<{
  portal: string,
  modelValue: Array<TransactionCategory>,
  viewState: TransactionCategorySelectViewState | null,
}>();

const {
  modelValue,
} = toRefs(props);
</script>
