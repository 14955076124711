export enum TextAngle {
  Normal = "Normal",
  Italic = "Italic",
}

export enum TextCasing {
  Normal = "Normal",
  Upper = "Upper",
}

export enum TextFamily {
  Normal = "Normal",
  Mono = "Mono",
}

export enum TextSize {
  "2XSmall" = "2XSmall",
  XSmall = "XSmall",
  Small = "Small",
  Medium = "Medium",
  Large = "Large",
  XLarge = "XLarge",
  "2XLarge" = "2XLarge",
  "3XLarge" = "3XLarge",
  "4XLarge" = "4XLarge",
  Inherit = "Inherit", // TODO: potentially find better solution
}

export enum TextTag {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  h6 = "h6",
  p = "p",
  a = "a",
  span = "span",
  strong = "strong",
  dt = "dt",
  dd = "dd",
  em = "em",
  li = "li",
  time = "time",
  main = "main",
  input = "input",
  button = "button",
  section = "section",
  select = "select",
}

export enum TextWeight {
  Normal = "Normal",
  Medium = "Medium",
  Bold = "Bold",
}

export enum TextWidth {
  Normal = "Normal",
  Extended = "Extended",
}

export enum TextVariant {
  Text = "Text",
  Headline = "Headline",
  Display = "Display",
  UI = "UI",
}
