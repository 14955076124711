import useLiveTime from "@cosine/composables/useLiveTime";
import useMarketStore from "@cosine/stores/useMarketStore";
import { MarketSymbol } from "@cosine/stores/useMarketStore.types";
import { storeToRefs } from "pinia";
import { computed, watch } from "vue";

export default function useMarketWatch(symbols: Array<MarketSymbol>) {
  const { now } = useLiveTime();
  const marketStore = useMarketStore();
  const { marketData } = storeToRefs(marketStore);
  const { fetchStocks } = marketStore;

  watch(now, fetchStocks, { immediate: true });

  const marketSeries = computed(() => {
    return marketData.value
      ? marketData.value.Series.filter((series) => symbols.includes(series.Summary.DisplaySymbol as MarketSymbol))
      : [];
  });

  return {
    marketSeries,
  };
}
