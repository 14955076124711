<template>
  <section class="LoginPage">
    <div class="LoginPage__container">
      <header class="LoginPage__header">
        <ShapeIcon
          class="LoginPage__icon"
          name="Hexagon"
        />
        <Logo class="LoginPage__logo" />
      </header>
      <section class="LoginPage__form">
        <EmailFormView />
        <hr>
        <GoogleButtonView />
      </section>
    </div>
  </section>
</template>

<script lang="ts" setup>
import Logo from "@cosine/components/Logo.vue";
import ShapeIcon from "@cosine/components/ShapeIcon.vue";
import EmailFormView from "@cosine/views/auth/EmailFormView.vue";
import GoogleButtonView from "@cosine/views/auth/GoogleButtonView.vue";
import { useSeoMeta } from "@unhead/vue";

useSeoMeta({
  title: "Login",
});
</script>

<style lang="scss" scoped>
.LoginPage {
  display: grid;
  justify-items: center;
  height: 100%;
  background-color: var(--colorSwissGrey50);

  @media (min-width: 600px) {
    place-items: center;
  }
}

.LoginPage__container {
  display: grid;
  grid: auto min-content / auto;
  max-width: 400px;
  width: 100%;

  @media (min-width: 600px) {
    min-height: 450px;
  }
}

.LoginPage__header {
  display: grid;
  justify-items: center;
  align-content: flex-start;
  gap: 24px;

  @media (max-width: 599px) {
    place-items: center;
    place-content: center;
    padding-top: 8px;
  }
}

.LoginPage__icon {
  width: 39px;
  height: 46px;
}

.LoginPage__logo {
  width: 61px;
}

.LoginPage__form {
  display: grid;
  gap: 16px;
  width: 100%;
  padding: 0 24px 32px;

  :deep(hr) {
    border: none;
    border-top: 1px solid var(--colorSwissGrey100);
  }

  .EraRoot[data-js-mobile-keyboard-state="closed"] & {
    @media (display-mode: standalone) {
      padding-bottom: calc(32px + var(--viewportPaddingBottom));
    }
  }
}
</style>
