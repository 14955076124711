export interface TransactionFilter {
  accountIds?: Array<string>,
  budgetInstanceId?: string,
}

export enum TransactionCategory {
  "[Auto & Transport]-[Auto & Transport]" = "[Auto & Transport]-[Auto & Transport]",
  "[Auto & Transport]-[Auto Insurance]" = "[Auto & Transport]-[Auto Insurance]",
  "[Auto & Transport]-[Auto Payment]" = "[Auto & Transport]-[Auto Payment]",
  "[Auto & Transport]-[Gas]" = "[Auto & Transport]-[Gas]",
  "[Auto & Transport]-[Parking]" = "[Auto & Transport]-[Parking]",
  "[Auto & Transport]-[Public Transportation]" = "[Auto & Transport]-[Public Transportation]",
  "[Auto & Transport]-[Service & Parts]" = "[Auto & Transport]-[Service & Parts]",
  "[Bills & Utilities]-[Bills & Utilities]" = "[Bills & Utilities]-[Bills & Utilities]",
  "[Bills & Utilities]-[Domain Names]" = "[Bills & Utilities]-[Domain Names]",
  "[Bills & Utilities]-[Fraud Protection]" = "[Bills & Utilities]-[Fraud Protection]",
  "[Bills & Utilities]-[Home Phone]" = "[Bills & Utilities]-[Home Phone]",
  "[Bills & Utilities]-[Hosting]" = "[Bills & Utilities]-[Hosting]",
  "[Bills & Utilities]-[Internet]" = "[Bills & Utilities]-[Internet]",
  "[Bills & Utilities]-[Mobile Phone]" = "[Bills & Utilities]-[Mobile Phone]",
  "[Bills & Utilities]-[Television]" = "[Bills & Utilities]-[Television]",
  "[Bills & Utilities]-[Utilities]" = "[Bills & Utilities]-[Utilities]",
  "[Business Services]-[Advertising]" = "[Business Services]-[Advertising]",
  "[Business Services]-[Business Services]" = "[Business Services]-[Business Services]",
  "[Business Services]-[Legal]" = "[Business Services]-[Legal]",
  "[Business Services]-[Office Supplies]" = "[Business Services]-[Office Supplies]",
  "[Business Services]-[Printing]" = "[Business Services]-[Printing]",
  "[Business Services]-[Shipping]" = "[Business Services]-[Shipping]",
  "[Education]-[Books & Supplies]" = "[Education]-[Books & Supplies]",
  "[Education]-[Education]" = "[Education]-[Education]",
  "[Education]-[Student Loan]" = "[Education]-[Student Loan]",
  "[Education]-[Tuition]" = "[Education]-[Tuition]",
  "[Entertainment]-[Amusement]" = "[Entertainment]-[Amusement]",
  "[Entertainment]-[Arts]" = "[Entertainment]-[Arts]",
  "[Entertainment]-[Entertainment]" = "[Entertainment]-[Entertainment]",
  "[Entertainment]-[Movies & DVDs]" = "[Entertainment]-[Movies & DVDs]",
  "[Entertainment]-[Music]" = "[Entertainment]-[Music]",
  "[Entertainment]-[Newspapers & Magazines]" = "[Entertainment]-[Newspapers & Magazines]",
  "[Fees & Charges]-[ATM Fee]" = "[Fees & Charges]-[ATM Fee]",
  "[Fees & Charges]-[Banking Fee]" = "[Fees & Charges]-[Banking Fee]",
  "[Fees & Charges]-[Fees & Charges]" = "[Fees & Charges]-[Fees & Charges]",
  "[Fees & Charges]-[Finance Charge]" = "[Fees & Charges]-[Finance Charge]",
  "[Fees & Charges]-[Late Fee]" = "[Fees & Charges]-[Late Fee]",
  "[Fees & Charges]-[Service Fee]" = "[Fees & Charges]-[Service Fee]",
  "[Fees & Charges]-[Trade Commissions]" = "[Fees & Charges]-[Trade Commissions]",
  "[Financial]-[Financial Advisor]" = "[Financial]-[Financial Advisor]",
  "[Financial]-[Financial]" = "[Financial]-[Financial]",
  "[Financial]-[Life Insurance]" = "[Financial]-[Life Insurance]",
  "[Food & Dining]-[Alcohol & Bars]" = "[Food & Dining]-[Alcohol & Bars]",
  "[Food & Dining]-[Coffee Shops]" = "[Food & Dining]-[Coffee Shops]",
  "[Food & Dining]-[Fast Food]" = "[Food & Dining]-[Fast Food]",
  "[Food & Dining]-[Food & Dining]" = "[Food & Dining]-[Food & Dining]",
  "[Food & Dining]-[Groceries]" = "[Food & Dining]-[Groceries]",
  "[Food & Dining]-[Restaurants]" = "[Food & Dining]-[Restaurants]",
  "[Gifts & Donations]-[Charity]" = "[Gifts & Donations]-[Charity]",
  "[Gifts & Donations]-[Gift]" = "[Gifts & Donations]-[Gift]",
  "[Gifts & Donations]-[Gifts & Donations]" = "[Gifts & Donations]-[Gifts & Donations]",
  "[Health & Fitness]-[Dentist]" = "[Health & Fitness]-[Dentist]",
  "[Health & Fitness]-[Doctor]" = "[Health & Fitness]-[Doctor]",
  "[Health & Fitness]-[Eyecare]" = "[Health & Fitness]-[Eyecare]",
  "[Health & Fitness]-[Gym]" = "[Health & Fitness]-[Gym]",
  "[Health & Fitness]-[Health & Fitness]" = "[Health & Fitness]-[Health & Fitness]",
  "[Health & Fitness]-[Health Insurance]" = "[Health & Fitness]-[Health Insurance]",
  "[Health & Fitness]-[Pharmacy]" = "[Health & Fitness]-[Pharmacy]",
  "[Health & Fitness]-[Sports]" = "[Health & Fitness]-[Sports]",
  "[Home]-[Furnishings]" = "[Home]-[Furnishings]",
  "[Home]-[Home]" = "[Home]-[Home]",
  "[Home]-[Home Improvement]" = "[Home]-[Home Improvement]",
  "[Home]-[Home Insurance]" = "[Home]-[Home Insurance]",
  "[Home]-[Home Services]" = "[Home]-[Home Services]",
  "[Home]-[Home Supplies]" = "[Home]-[Home Supplies]",
  "[Home]-[Lawn & Garden]" = "[Home]-[Lawn & Garden]",
  "[Home]-[Mortgage & Rent]" = "[Home]-[Mortgage & Rent]",
  "[Income]-[Bonus]" = "[Income]-[Bonus]",
  "[Income]-[Interest Income]" = "[Income]-[Interest Income]",
  "[Income]-[Paycheck]" = "[Income]-[Paycheck]",
  "[Income]-[Reimbursement]" = "[Income]-[Reimbursement]",
  "[Income]-[Rental Income]" = "[Income]-[Rental Income]",
  "[Investments]-[Buy]" = "[Investments]-[Buy]",
  "[Investments]-[Deposit]" = "[Investments]-[Deposit]",
  "[Investments]-[Dividend & Cap Gains]" = "[Investments]-[Dividend & Cap Gains]",
  "[Investments]-[Investments]" = "[Investments]-[Investments]",
  "[Investments]-[Sell]" = "[Investments]-[Sell]",
  "[Investments]-[Withdrawal]" = "[Investments]-[Withdrawal]",
  "[Kids]-[Allowance]" = "[Kids]-[Allowance]",
  "[Kids]-[Baby Showers]" = "[Kids]-[Baby Showers]",
  "[Kids]-[Baby Supplies]" = "[Kids]-[Baby Supplies]",
  "[Kids]-[Babysitter & Daycare]" = "[Kids]-[Babysitter & Daycare]",
  "[Kids]-[Child Support]" = "[Kids]-[Child Support]",
  "[Kids]-[Kids Activities]" = "[Kids]-[Kids Activities]",
  "[Kids]-[Kids]" = "[Kids]-[Kids]",
  "[Kids]-[Toys]" = "[Kids]-[Toys]",
  "[Personal Care]-[Hair]" = "[Personal Care]-[Hair]",
  "[Personal Care]-[Laundry]" = "[Personal Care]-[Laundry]",
  "[Personal Care]-[Personal Care]" = "[Personal Care]-[Personal Care]",
  "[Personal Care]-[Spa & Massage]" = "[Personal Care]-[Spa & Massage]",
  "[Pets]-[Pet Food & Supplies]" = "[Pets]-[Pet Food & Supplies]",
  "[Pets]-[Pet Grooming]" = "[Pets]-[Pet Grooming]",
  "[Pets]-[Pets]" = "[Pets]-[Pets]",
  "[Pets]-[Veterinary]" = "[Pets]-[Veterinary]",
  "[Shopping]-[Books]" = "[Shopping]-[Books]",
  "[Shopping]-[Clothing]" = "[Shopping]-[Clothing]",
  "[Shopping]-[Electronics & Software]" = "[Shopping]-[Electronics & Software]",
  "[Shopping]-[Hobbies]" = "[Shopping]-[Hobbies]",
  "[Shopping]-[Shopping]" = "[Shopping]-[Shopping]",
  "[Shopping]-[Sporting Goods]" = "[Shopping]-[Sporting Goods]",
  "[Taxes]-[Federal Tax]" = "[Taxes]-[Federal Tax]",
  "[Taxes]-[Local Tax]" = "[Taxes]-[Local Tax]",
  "[Taxes]-[Property Tax]" = "[Taxes]-[Property Tax]",
  "[Taxes]-[Sales Tax]" = "[Taxes]-[Sales Tax]",
  "[Taxes]-[State Tax]" = "[Taxes]-[State Tax]",
  "[Taxes]-[Taxes]" = "[Taxes]-[Taxes]",
  "[Transfer]-[Credit Card Payment]" = "[Transfer]-[Credit Card Payment]",
  "[Transfer]-[Mortgage Payment]" = "[Transfer]-[Mortgage Payment]",
  "[Transfer]-[Transfer]" = "[Transfer]-[Transfer]",
  "[Transfer]-[Transfer for Cash Spending]" = "[Transfer]-[Transfer for Cash Spending]",
  "[Travel]-[Air Travel]" = "[Travel]-[Air Travel]",
  "[Travel]-[Hotel]" = "[Travel]-[Hotel]",
  "[Travel]-[Rental Car & Taxi]" = "[Travel]-[Rental Car & Taxi]",
  "[Travel]-[Travel]" = "[Travel]-[Travel]",
  "[Travel]-[Vacation]" = "[Travel]-[Vacation]",
  "[Uncategorized]-[Cash]" = "[Uncategorized]-[Cash]",
  "[Uncategorized]-[Check]" = "[Uncategorized]-[Check]",
}
