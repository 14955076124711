import * as Sentry from "@sentry/vue";
import { toString } from "cronstrue";

export function formatCronExpression(cronExpression: string): string {
  if (!cronExpression) {
    return "";
  }
  try {
    return toString(cronExpression);
  } catch (parseError) {
    Sentry.withScope((scope) => {
      scope.setContext("formatCronExpression", { cronExpression });
      Sentry.captureException(parseError);
    });

    return "";
  }
}
