<template>
  <Transition>
    <SheetModal
      v-if="isUpgradeModalOpen"
      class="UpgradeSheetModalView"
      theme="Light"
      @close="finishEditingSubscription"
    >
      <PromptPane
        iconName="ArrowUpHexagon"
        iconColor="Teal"
        title="Upgrade for the full picture"
        body="Get more chat credits, unlock all your connected accounts, and automate your money with custom routines."
      >
        <template #button>
          <FormButton
            :disabled="isRequesting"
            @click="handleUpgradeClick"
          >Upgrade now</FormButton>
        </template>
      </PromptPane>
    </SheetModal>
  </Transition>
</template>

<script lang="ts" setup>
import FormButton from "@cosine/components/FormButton.vue";
import PromptPane from "@cosine/components/PromptPane.vue";
import SheetModal from "@cosine/components/SheetModal.vue";
import useRequest from "@cosine/composables/useRequest";
import useModalStore from "@cosine/stores/useModalStore";
import { ModalName } from "@cosine/stores/useModalStore.types";
import useSubscriptionStore from "@cosine/stores/useSubscriptionStore";
import { computed } from "vue";

const { isModalOpen } = useModalStore();
const { redirectToCheckout, finishEditingSubscription } = useSubscriptionStore();
const {
  handleRequest,
  isRequesting,
} = useRequest();

const isUpgradeModalOpen = computed(() => isModalOpen(ModalName.UpgradeSubscription));

async function handleUpgradeClick() {
  await handleRequest(
    redirectToCheckout(),
    {
      showOnlyErrors: true,
    },
  );
}
</script>
