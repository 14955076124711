<template>
  <InfiniteScroll
    class="TimelineListView"
    ref="scrollRef"
    scrollViewport="Mobile"
    v-bind="{pagination}"
    @scrollEnd="handleScrollEnd"
  >
    <GroupedList>
      <LabeledList
        v-if="fromEraEntries.length > 0"
        class="TimelineListView__fromEraList"
        title="From Era"
      >
        <template #list>
          <CardCarousel>
            <TimelineEntryView
              v-for="entry in fromEraEntries"
              :key="entry.IdReference"
              :modelValue="entry"
              isPinned
            />
          </CardCarousel>
        </template>
      </LabeledList>
      <LabeledList
        v-for="list in lists"
        :key="list.title"
        :title="list.title"
      >
        <TimelineEntryView
          v-for="entry in list.items"
          :key="entry.IdReference"
          :modelValue="entry"
        />
      </LabeledList>
    </GroupedList>
  </InfiniteScroll>
</template>

<script lang="ts" setup>
import CardCarousel from "@cosine/components/CardCarousel.vue";
import GroupedList from "@cosine/components/GroupedList.vue";
import InfiniteScroll from "@cosine/components/InfiniteScroll.vue";
import LabeledList from "@cosine/components/LabeledList.vue";
import useAppSpinner from "@cosine/composables/useAppSpinner";
import useAppVisibilityCallback from "@cosine/composables/useAppVisibilityCallback";
import useRequest from "@cosine/composables/useRequest";
import groupByDate from "@cosine/lib/utils/collection/groupByDate";
import { RouteName } from "@cosine/routes";
import useTimelineStore from "@cosine/stores/useTimelineStore";
import { TimelineEntryFlag, TimelineEntrySources } from "@cosine/types/api-models";
import TimelineEntryView from "@cosine/views/TimelineEntryView.vue";
import { storeToRefs } from "pinia";
import { ComponentPublicInstance, computed, ref, watch } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const timelineStore = useTimelineStore();
const {
  fetchEntries,
} = timelineStore;
const {
  entries,
  pagination,
} = storeToRefs(timelineStore);
const {
  isRequesting,
  handleRequest,
} = useRequest();

useAppSpinner(isRequesting);
useAppVisibilityCallback(fetchEntries);
handleRequest(fetchEntries());

const scrollRef = ref<ComponentPublicInstance>();

const fromEraSources = [
  TimelineEntrySources.AccountConnectionStatus,
  TimelineEntrySources.Announcement,
  TimelineEntrySources.System,
];

const userSources = [
  TimelineEntrySources.RoutineMessage,
  TimelineEntrySources.User,
];

const fromEraEntries = computed(() => {
  if (route.name !== RouteName.timelineAll) return [];

  return entries.value.filter((entry) => fromEraSources.includes(entry.Source));
});

const notFromEraEntries = computed(() => {
  return entries.value.filter((entry) => !fromEraSources.includes(entry.Source));
});

const filteredEntries = computed(() => {
  switch (route.name) {
    case RouteName.timelineAll:
      return notFromEraEntries.value;
    case RouteName.timelineNews:
      return notFromEraEntries.value.filter((entry) => entry.Source === TimelineEntrySources.System_PeriodicBrief);
    case RouteName.timelineChats:
      return notFromEraEntries.value.filter((entry) => userSources.includes(entry.Source));
    case RouteName.timelineBookmarks:
      return notFromEraEntries.value.filter((entry) => entry.Flags.includes(TimelineEntryFlag.Pinned));
  }

  return [];
});

const lists = computed(() => {
  return groupByDate(filteredEntries.value, (entry) => new Date(entry.DateForDisplay || new Date));
});

watch(route, () => {
  if (scrollRef.value?.$el.scrollTo) {
    scrollRef.value.$el.scrollTo({top: 0, behavior: "instant"});
  }
});

function handleScrollEnd() {
  if (isRequesting.value) return;

  handleRequest(fetchEntries({pageIndex: pagination.value.pageIndex + 1}));
}
</script>

<style lang="scss" scoped>
.TimelineListView {
  @media (min-width: 600px) {
    --labeledListStickyTop: calc(var(--layoutStickyTop) + var(--tabNavHeight))
  ;}
}
</style>
