<template>
  <Transition>
    <SheetScreen
      v-show="!viewState"
      class="MinimumBalanceTransferRoutineFormView__screen"
    >
      <header class="MinimumBalanceTransferRoutineFormView__header">
        <IconTag
          iconName="Hexagon"
          label="Routine"
        />
        <UIText
          class="MinimumBalanceTransferRoutineFormView__title"
          tag="h1"
          size="XLarge"
          weight="Medium"
        >
          Minimum balance
        </UIText>
      </header>

      <Form
        class="MinimumBalanceTransferRoutineFormView__form"
        :isDisabled="isRequesting"
        @submit="handleSubmit"
      >
        <CurrencyInput
          class="MinimumBalanceTransferRoutineFormView__amountInput"
          v-model="form.balance"
          variant="Inline"
        />

        <ScrollContainer
          class="MinimumBalanceTransferRoutineFormView__scrollContainer"
          hasBottomFade
        >
          <div class="MinimumBalanceTransferRoutineFormView__scrollContainerFieldset">
            <TransferAccountSelectView
              v-if="portalRef"
              :portal="`#${portalRef.id}`"
              v-model:sourceAccountId="form.sourceAccountId"
              v-model:destinationAccountId="form.destinationAccountId"
              v-model:viewState="viewState"
            />

            <RoutineScheduleFieldset
              v-model="form.cronExpression"
            />

            <ChannelFieldset
              v-model="form.channels"
              :supportedChannels="[
                MessageChannel.Timeline,
                MessageChannel.Email,
                MessageChannel.Sms
              ]"
              :email="currentUser?.Email"
            />
          </div>
        </ScrollContainer>

        <div class="MinimumBalanceTransferRoutineFormView__formButtonContainer">
          <FormButton
            variant="Primary"
            type="submit"
            :disabled="!validatedForm"
          >
            <span v-if="!isRequesting">Schedule routine</span>
            <span v-else>Scheduling routine…</span>
          </FormButton>
        </div>
      </Form>
    </SheetScreen>
  </Transition>
</template>

<script lang="ts" setup>
import ChannelFieldset from "@cosine/components/ChannelFieldset.vue";
import CurrencyInput from "@cosine/components/CurrencyInput.vue";
import Form from "@cosine/components/Form.vue";
import FormButton from "@cosine/components/FormButton.vue";
import IconTag from "@cosine/components/IconTag.vue";
import RoutineScheduleFieldset from "@cosine/components/RoutineScheduleFieldset.vue";
import ScrollContainer from "@cosine/components/ScrollContainer.vue";
import SheetScreen from "@cosine/components/SheetScreen.vue";
import UIText from "@cosine/components/UIText.vue";
import useRequest from "@cosine/composables/useRequest";
import { RouteName } from "@cosine/routes";
import useAuthStore from "@cosine/stores/useAuthStore";
import { RoutineTemplate } from "@cosine/stores/useRoutineStore.types";
import useTransferStore from "@cosine/stores/useTransferStore";
import { NullableValues } from "@cosine/types";
import { MessageChannel } from "@cosine/types/api-models";
import { MinimumBalanceTransferRoutineOptions } from "@cosine/views/MinimumBalanceTransferRoutineFormView.types";
import { TransferAccountSelectViewState } from "@cosine/views/TransferAccountSelectView.types";
import TransferAccountSelectView from "@cosine/views/TransferAccountSelectView.vue";
import { storeToRefs } from "pinia";
import { computed, inject, Ref, ref, toRefs } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const {currentUser} = storeToRefs(useAuthStore());
const {createMinimumBalanceRoutine} = useTransferStore();
const {
  handleRequest,
  isRequesting,
} = useRequest();

const props = defineProps<{
  routineTemplate: RoutineTemplate
}>();
const {routineTemplate} = toRefs(props);

const portalRef = inject<Ref<HTMLElement>>("portalRef");
const viewState = ref<TransferAccountSelectViewState | null>(null);

// TODO: extract into useRoutineForm composable along with portal and view state?
const form = ref(getInitialForm());
defineExpose({
  form,
});

function getInitialForm(): NullableValues<MinimumBalanceTransferRoutineOptions> & Required<Pick<MinimumBalanceTransferRoutineOptions, "cronExpression" | "channels">> {
  const query = routineTemplate.value?.route.query;

  return {
    sourceAccountId: query?.from?.toString() || null,
    destinationAccountId: query?.to?.toString() || null,
    balance: query?.balance ? parseInt(String(query?.balance)) : null,
    cronExpression: query?.cron ? String(query.cron) : "0 0 8 ? * MON",
    channels: [MessageChannel.Timeline, MessageChannel.Email],
  };
}

const validatedForm = computed((): MinimumBalanceTransferRoutineOptions | void => {
  if (
    !form.value.sourceAccountId ||
    !form.value.destinationAccountId ||
    !form.value.balance
  ) return;

  return {
    sourceAccountId: form.value.sourceAccountId,
    destinationAccountId: form.value.destinationAccountId,
    balance: form.value.balance,
    cronExpression: form.value.cronExpression,
    channels: form.value.channels,
  };
});

async function handleSubmit() {
  if (!validatedForm.value) return;

  const [err] = await handleRequest(createMinimumBalanceRoutine(validatedForm.value), {
    showOnlyErrors: true,
  });

  if (!err) {
    router.push({name: RouteName.routinesYours});
  }
}
</script>

<style lang="scss" scoped>
.MinimumBalanceTransferRoutineFormView__screen {
  display: grid;
  grid: min-content auto / auto;
}

.MinimumBalanceTransferRoutineFormView__form {
  // TODO: figure out a better way to do this
  > :deep(fieldset) {
    display: grid;
    grid: min-content auto min-content / auto;
    height: 100%;
  }
}

.MinimumBalanceTransferRoutineFormView__scrollContainer {
  border-top: 1px solid var(--colorSwissGrey100);
}

.MinimumBalanceTransferRoutineFormView__scrollContainerFieldset {
  display: grid;
  gap: 1px;
}

.MinimumBalanceTransferRoutineFormView__header {
  display: grid;
  gap: 20px;
  padding: 24px var(--layoutMargin) 8px;
}

.MinimumBalanceTransferRoutineFormView__title {
  text-wrap: balance;
}

.MinimumBalanceTransferRoutineFormView__amountInput {
  margin-bottom: 16px;
}

.MinimumBalanceTransferRoutineFormView__formButtonContainer {
  padding: 16px var(--layoutMargin);

  // TODO: extract
  @media (display-mode: standalone) {
    padding-bottom: calc(24px + var(--viewportPaddingBottom));
  }
}
</style>
