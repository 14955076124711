<template>
  <div class="ModalPage">
    <slot />
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
.ModalPage {
  display: grid;
  place-items: center;
  background: var(--colorSwissGrey50);
  height: 100%;
}
</style>
