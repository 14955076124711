<template>
  <section
    class="BudgetListView"
    :class="{
      'BudgetListView--hasBudgets': budgetDefinitions.length > 0,
    }"
  >
    <TabNavView
      v-if="budgetDefinitions.length > 0"
      class="BudgetListView__tabNav"
      :items="tabNavItems"
      isSubNav
    />

    <ScrollContainer
      viewport="Mobile"
    >
      <slot />

      <GetStartedView v-if="!isRequesting && budgetDefinitions.length === 0 && accounts.length === 0 && !isSyncingAccounts" />
      <FeatureCard
        v-else-if="!isRequesting"
        title="Budgeting for needs vs wants"
        body="You can track any kind of budget in Era, but we get you started with what matters most: tracking spending on necessary items. Everything else is optional, right?"
        color="Green"
      />

      <div class="BudgetListView__scrollSpacer" />
    </ScrollContainer>

    <Teleport to="body">
      <BudgetSheetModalView />
    </Teleport>
  </section>
</template>

<script lang="ts" setup>
import FeatureCard from "@cosine/components/FeatureCard.vue";
import ScrollContainer from "@cosine/components/ScrollContainer.vue";
import { TabNavItem } from "@cosine/components/TabNav.types";
import useAppSpinner from "@cosine/composables/useAppSpinner";
import useRequest from "@cosine/composables/useRequest";
import { RouteName } from "@cosine/routes";
import useBudgetStore from "@cosine/stores/useBudgetStore";
import useFinancialStore from "@cosine/stores/useFinancialStore";
import BudgetSheetModalView from "@cosine/views/BudgetSheetModalView.vue";
import GetStartedView from "@cosine/views/GetStartedView.vue";
import TabNavView from "@cosine/views/TabNavView.vue";
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";

const financialStore = useFinancialStore();
const {
  accounts,
  isSyncingAccounts,
} = storeToRefs(financialStore);
const budgetStore = useBudgetStore();
const {
  fetchBudgetInstances,
  fetchBudgetDefinitions,
} = budgetStore;
const {
  budgetDefinitions,
} = storeToRefs(budgetStore);
const {
  isRequesting,
  handleRequest,
} = useRequest();
useAppSpinner(isRequesting);

onMounted(async () => {
  await handleRequest(Promise.all([
    fetchBudgetInstances(),
    fetchBudgetDefinitions(),
  ]));
});

const tabNavItems = ref<Array<TabNavItem>>([
  { label: "Week", to: { name: RouteName.budgetsWeekly } },
  { label: "Month", to: { name: RouteName.budgetsMonthly } },
  { label: "Year", to: { name: RouteName.budgetsYearly } },
]);
</script>

<style lang="scss" scoped>
.BudgetListView {
  position: relative;
  z-index: 0;
  display: grid;

  &.BudgetListView--hasBudgets {
    grid: min-content auto / auto;
  }
}

.BudgetListView__scrollSpacer {
  // TODO: extract
  @media (display-mode: standalone) {
    padding-bottom: calc(24px + var(--viewportPaddingBottom));
  }
}
</style>
