<template>
  <section
    class="TransactionsPage"
  >
    <TransactionsFilterView class="TransactionsPage__filter" />
    <TransactionsListView class="TransactionsPage__list" />
  </section>
</template>

<script lang="ts" setup>
import TransactionsFilterView from "@cosine/views/TransactionsFilterView.vue";
import TransactionsListView from "@cosine/views/TransactionsListView.vue";
import { useSeoMeta } from "@unhead/vue";

useSeoMeta({
  title: "Transactions",
});
</script>

<style lang="scss" scoped>
.TransactionsPage {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--colorSwissGrey50);
}

.TransactionsPage__filter {
  flex: 0 0 auto;
}

.TransactionsPage__list {
  flex: 1 1 auto;
}
</style>
