<template>
  <Transition>
    <SheetModal
      v-if="isRoutineModalOpen && routineTemplate"
      class="NewRoutineModalView"
      theme="Light"
      size="Fill"
      @close="$router.push({name: RouteName.routinesExplore})"
    >
      <Component
        :is="formView"
        v-bind="{routineTemplate}"
      />
      <div
        ref="portalRef"
        id="NewRoutineModalViewPortal"
      />
    </SheetModal>
  </Transition>
</template>

<script lang="ts" setup>
import SheetModal from "@cosine/components/SheetModal.vue";
import { RouteName } from "@cosine/routes";
import useRoutineExploreStore from "@cosine/stores/useRoutineExploreStore";
import { RoutineTemplateSlug } from "@cosine/stores/useRoutineStore.types";
import { RoutineTypes } from "@cosine/types/api-models";
import FixedAmountTransferRoutineFormView from "@cosine/views/FixedAmountTransferRoutineFormView.vue";
import MinimumBalanceTransferRoutineFormView from "@cosine/views/MinimumBalanceTransferRoutineFormView.vue";
import RoundUpSavingsRoutineFormView from "@cosine/views/RoundUpSavingsRoutineFormView.vue";
import { storeToRefs } from "pinia";
import { computed, provide, ref } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();

const {
  transferTemplates,
} = storeToRefs(useRoutineExploreStore());

const portalRef = ref<HTMLElement>();
provide("portalRef", portalRef);

const routineTemplate = computed(() => {
  return transferTemplates.value.find((template) => {
    return template.route.params?.slug === route.params.slug &&
      JSON.stringify(template.route.query || {}) === JSON.stringify(route.query || {});
  });
});

const formView = computed(() => {
  switch (routineTemplate.value?.type) {
    case RoutineTypes.UserFixedAmountFundsTransfer:
      return FixedAmountTransferRoutineFormView;
    case RoutineTypes.UserThresholdBasedFundsTransfer:
      if (routineTemplate.value.route.params?.slug === RoutineTemplateSlug.MinimumBalance) return MinimumBalanceTransferRoutineFormView;
      break;
    case RoutineTypes.UserRoundUpSavingsFundsTransfer:
      return RoundUpSavingsRoutineFormView;
  }

  return null;
});

const isRoutineModalOpen = computed(() => {
  return route.name === RouteName.routinesNew;
});
</script>
