import useApiClient from "@cosine/composables/useApiClient";
import { IApiResponse, IFileDetails, IPaginatedList } from "@cosine/types/api-models";
import { defineStore } from "pinia";

import { ref } from "vue";

export default defineStore("MintStore", () => {
  const { apiClient } = useApiClient();

  const uploadedFiles = ref<Array<IFileDetails>>([]);
  const filesBeingDeleted = ref<Array<IFileDetails>>([]);
  const filesSelectedForUpload = ref<Array<File>>([]);

  async function fetchUploadedFiles() {
    const { data: { Result: pagindatedList } } = await apiClient.value.get<IApiResponse<IPaginatedList<IFileDetails>>>(`/mint/files`);
    uploadedFiles.value = pagindatedList?.Items || [];
  }

  async function deleteFile(file: IFileDetails) {
    filesBeingDeleted.value.push(file);

    await apiClient.value.delete(`/mint/files/${encodeURIComponent(file.Id)}`);

    uploadedFiles.value = uploadedFiles.value.filter(({ Id }) => Id !== file.Id);
    filesBeingDeleted.value = filesBeingDeleted.value.filter(({ Id }) => Id !== file.Id);
  }

  async function uploadSelectedFiles() {
    await Promise.all(filesSelectedForUpload.value.map(async (file) => {
      const formData = new FormData();
      formData.append("content", file);

      await apiClient.value.post("/mint/import", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    }));

    filesSelectedForUpload.value = [];
  }

  function removeFileFromSelectedFilesAtIndex(index: number) {
    filesSelectedForUpload.value.splice(index, 1);
  }

  return {
    uploadedFiles,
    filesBeingDeleted,
    filesSelectedForUpload,

    fetchUploadedFiles,
    deleteFile,
    uploadSelectedFiles,
    removeFileFromSelectedFilesAtIndex,
  };
});

