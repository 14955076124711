<template>
  <Form
    v-if="routine"
    class="RoutineSettingsView"
    :isDisabled="isRequesting"
    @submit="handleSubmit"
  >
    <div class="RoutineSettingsView__fieldsetContainer">
      <div class="RoutineSettingsView__fieldset">
        <RoutineScheduleFieldset
          v-if="canChangeSchedule"
          v-model="form.cronExpression"
        />

        <ChannelFieldset
          v-if="routine.SupportedChannels.length > 0"
          v-model="form.channels"
          :supportedChannels="routine.SupportedChannels"
          :email="currentUser?.Email"
        />
      </div>
    </div>

    <div class="RoutineSettingsView__formButtonContainer">
      <FormButton
        variant="Primary"
        type="submit"
      >
        <span v-if="isRequesting">
          Saving settings…
        </span>
        <span v-else>
          Save settings
        </span>
      </FormButton>
    </div>
  </Form>
</template>

<script lang="ts" setup>
import ChannelFieldset from "@cosine/components/ChannelFieldset.vue";
import Form from "@cosine/components/Form.vue";
import FormButton from "@cosine/components/FormButton.vue";
import RoutineScheduleFieldset from "@cosine/components/RoutineScheduleFieldset.vue";
import useRequest from "@cosine/composables/useRequest";
import useAuthStore from "@cosine/stores/useAuthStore";
import useRoutineStore from "@cosine/stores/useRoutineStore";
import { ICronRoutineInterval, IJsonRoutineArgsWithMessageChannels, MessageChannel, RoutineTypes } from "@cosine/types/api-models";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const {currentUser} = useAuthStore();
const routineStore = useRoutineStore();
const {
  userRoutines,
} = storeToRefs(routineStore);
const {
  updateRoutine,
} = routineStore;
const {
  handleRequest,
  isRequesting,
} = useRequest();

const form = ref<{
  cronExpression: string,
  channels: Array<MessageChannel>
}>({
  cronExpression: "0 0 8 ? * MON",
  channels: [MessageChannel.Timeline, MessageChannel.Email],
});

// TODO: fetch routine if not in store
const routine = computed(() => {
  return userRoutines.value.find((routine) => routine.RoutineId === route.params.id);
});

const canChangeSchedule = computed(() => {
  return routine.value &&
  [
    RoutineTypes.UserFixedAmountFundsTransfer,
    RoutineTypes.UserPercentageBasedFundsTransfer,
    RoutineTypes.UserThresholdBasedFundsTransfer,
    // TODO: when merged: RoutineTypes.UserRoundUpSavingsFundsTransfer,
  ].includes(routine.value.Type);
});

const existingChannels = computed((): Array<MessageChannel> => {
  if (!routine.value?.Args) return [];

  return (routine.value.Args as Partial<IJsonRoutineArgsWithMessageChannels>).Channels ?? [];
});

watch(routine, initForm, {immediate: true});

function initForm() {
  if (!routine.value) return;

  form.value.cronExpression = routine.value.CronExpression;
  form.value.channels = [...existingChannels.value];
}

function handleSubmit() {
  if (!routine.value) return;

  handleRequest(updateRoutine({
    RoutineId: routine.value.RoutineId,
    Interval: {
      CronExpression: form.value.cronExpression,
    } as ICronRoutineInterval,
  }, {
    Channels: form.value.channels,
  }), {
    showAlerts: true,
  });
}
</script>

<style lang="scss" scoped>
.RoutineSettingsView {
  position: relative;
  height: 100%;

  // TODO: find a way to extract this
  > :deep(fieldset) {
    display: grid;
    grid: auto min-content / auto;
    height: 100%;
  }
}

.RoutineSettingsView__fieldsetContainer {
  position: relative;
}

.RoutineSettingsView__fieldset {
  display: grid;
  gap: 1px;
  align-content: flex-start;
  position: absolute;
  inset: 0;
  overflow-y: auto;
  mask-image: linear-gradient(to top, transparent 0%, black 24px);
}

.RoutineSettingsView__formButtonContainer {
  padding: 0 var(--layoutMargin) 16px;
}
</style>
