<template>
  <Transition>
    <SheetModal
      v-if="isBudgetSheetModalOpen && editingBudgetDefinition"
      class="BudgetSheetModalView"
      theme="Light"
      @close="finishEditingBudgetDefinition"
    >
      <template #summary>
        <BudgetSheetSummary
          :budgetDefinition="editingBudgetDefinition"
        />
      </template>
      <template #buttonList>
        <SheetButton
          iconName="Dollar"
          label="View transactions"
          color="Green"
          @click="handleViewTransactions"
        />
      </template>
      <template #form>
        <BudgetForm
          :budgetDefinition="editingBudgetDefinition"
          :isDisabled="isSavingDefinition"
          @submit="handleSubmit"
        />
      </template>
    </SheetModal>
  </Transition>
</template>

<script lang="ts" setup>
import BudgetForm from "@cosine/components/BudgetForm.vue";
import BudgetSheetSummary from "@cosine/components/BudgetSheetSummary.vue";
import SheetButton from "@cosine/components/SheetButton.vue";
import SheetModal from "@cosine/components/SheetModal.vue";
import useRequest from "@cosine/composables/useRequest";
import { RouteName } from "@cosine/routes";
import useBudgetStore from "@cosine/stores/useBudgetStore";
import useModalStore from "@cosine/stores/useModalStore";
import { ModalName } from "@cosine/stores/useModalStore.types";
import { BudgetRemainingDestination } from "@cosine/types/api-models";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const {
  isModalOpen,
  closeModal,
} = useModalStore();
const budgetStore = useBudgetStore();
const {
  editingBudgetDefinition,
  budgetInstances,
} = storeToRefs(budgetStore);
const {
  finishEditingBudgetDefinition,
  updateBudgetDefinition,
  fetchBudgetInstances,
} = budgetStore;

const {
  handleRequest,
  isRequesting: isSavingDefinition,
} = useRequest();

const isBudgetSheetModalOpen = computed(() => isModalOpen(ModalName.EditBudgetDefinition));

function handleViewTransactions() {
  // TODO: filter for most recent instance in case there are multiple
  const budgetInstance = budgetInstances.value.find((budgetInstance) => {
    return budgetInstance.BudgetDefinitionId === editingBudgetDefinition.value?.EraId;
  });

  // TODO: handle more gracefully
  if (!budgetInstance) return;

  closeModal(ModalName.EditBudgetDefinition);
  router.push({ name: RouteName.transactions, query: { budget: budgetInstance.EraId }});
}

async function handleSubmit(form: {amount: number}) {
  if (!editingBudgetDefinition.value) return;

  const {
    EraId,
    Name,
    Period,
    AssignedTransactionCategories,
  } = editingBudgetDefinition.value;

  // TODO: prevent this boolean that disables the form from closing the keyboard
  const [err] = await handleRequest(
    updateBudgetDefinition({
      BudgetId: EraId,
      Name,
      Period,
      DefaultAmount: form.amount,
      AssignedTransactionCategories,
      IsUpdateAllInstances: false,
      BudgetRemainingDestination: BudgetRemainingDestination.None,
    }),
    {
      showAlerts: true,
    },
  );


  if (!err) {
    closeModal(ModalName.EditBudgetDefinition);
    fetchBudgetInstances();
  }
}
</script>
