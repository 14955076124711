<template>
  <ThreadModal
    v-bind="{
      timelineEntry,
      messages,
      isAgentProcessing,
      courtesyMessage,
      chatLimitStatus,
      canUpgrade,
      isLoading,
    }"
    :visibility="threadVisibility"
    @close="handleClose"
    @focus="handleFocus"
    @submit="handleSubmit"
    @upgrade="handleUpgrade"
  />
</template>

<script lang="ts" setup>
import ThreadModal from "@cosine/components/ThreadModal.vue";
import useRequest from "@cosine/composables/useRequest";
import useAuthStore from "@cosine/stores/useAuthStore";
import useFeatureLimitStore from "@cosine/stores/useFeatureLimitStore";
import useSubscriptionStore from "@cosine/stores/useSubscriptionStore";
import useThreadStore from "@cosine/stores/useThreadStore";
import { ErrorType, FeatureLimitType } from "@cosine/types/api-models";
import useLogger from "@shared/composables/useLogger";
import { storeToRefs } from "pinia";
import { computed, onBeforeUnmount, onMounted, watch } from "vue";

// TODO: test

const { currentUser } = storeToRefs(useAuthStore());
const { getFeatureLimit } = useFeatureLimitStore();
const subscriptionStore = useSubscriptionStore();
const { canUpgrade } = storeToRefs(subscriptionStore);
const { startEditingSubscription } = subscriptionStore;

const threadStore = useThreadStore();
const {
  connectThread,
  disconnectThread,
  fetchMessages,
  sendMessage,
} = threadStore;
const {
  messages,
  courtesyMessage,
  isAgentProcessing,
  timelineEntry,
  threadVisibility,
} = storeToRefs(threadStore);

const {
  isRequesting: isLoading,
  handleRequest,
} = useRequest();

const logger = useLogger();

onMounted(connectThread);
onBeforeUnmount(disconnectThread);

const chatLimitStatus = computed(() => getFeatureLimit(FeatureLimitType.ChatMessageCount));

watch(timelineEntry, async (newTimelineEntry, oldTimelineEntry) => {
  // TODO
  if (newTimelineEntry && newTimelineEntry.IdReference !== oldTimelineEntry?.IdReference && !!newTimelineEntry.Title) {
    await handleRequest(fetchMessages());
    // TODO: handle errors
  }
});

function handleClose() {
  threadStore.closeThread();
}

function handleFocus() {
  if (messages.value.length > 0) {
    threadStore.openThread();
  }
}

async function handleUpgrade() {
  startEditingSubscription(currentUser.value?.Subscription || null);
}

async function handleSubmit(message: string) {
  try {
    await sendMessage(message);
  } catch (err) {
    var error = err as Error;
    var userFriendlyErrorMessage = "an unexpected error occurred";
    switch(error.message) {
      case ErrorType.ChatCharacterLimitExceeded:
        userFriendlyErrorMessage = "your message was over the maximum allowed length";
        break;
      case ErrorType.DailyChatCountLimitExceeded:
        userFriendlyErrorMessage = "your daily chat limit has been reached";
        break;
    }
    // TODO: present to the user in an appropriate way
    logger.error(userFriendlyErrorMessage);
  }
}
</script>
