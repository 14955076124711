<template>
  <section class="InvestingExploreView">
    Investing Explore View
  </section>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>
.InvestingExploreView {
  width: 100%;
}
</style>
