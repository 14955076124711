import useApiClient from "@cosine/composables/useApiClient";
import useEditModal from "@cosine/composables/useEditModal";
import useFlag from "@cosine/composables/useFlag";
import { FlagKey } from "@cosine/composables/useFlag.types";
import useAuthStore from "@cosine/stores/useAuthStore";
import { ModalName } from "@cosine/stores/useModalStore.types";
import { SubscriptionPriceKey } from "@cosine/stores/useSubscriptionStore.types";
import { IApiResponse, IPaymentSessionLink, IUserSubscriptionModel, SubscriptionPeriod } from "@cosine/types/api-models";

import { defineStore, storeToRefs } from "pinia";
import { computed } from "vue";

export default defineStore("SubscriptionStore", () => {
  const { apiClient } = useApiClient();
  const { currentUser } = storeToRefs(useAuthStore());
  const { flag: upgradeFlag } = useFlag(FlagKey.EnableUpgradeCTAs, false);

  const {
    editingItem: editingSubscription,
    startEditingItem: startEditingSubscription,
    finishEditingItem: finishEditingSubscription,
  } = useEditModal<IUserSubscriptionModel | null>(ModalName.UpgradeSubscription);

  const isSubscribed = computed((): boolean => {
    return !!currentUser.value?.Subscription && currentUser.value.Subscription.Period !== SubscriptionPeriod.Unknown;
  });

  const canUpgrade = computed((): boolean => {
    return upgradeFlag.value && !isSubscribed.value;
  });

  async function redirectToCheckout() {
    const priceKey = SubscriptionPriceKey.EssentialsMonthly;
    const url = window.location.href;

    const response = await apiClient.value.get<IApiResponse<IPaymentSessionLink>>(`/subscriptions/payment-link?PriceKey=${priceKey}&SuccessUrl=${url}&CancelUrl=${url}`);

    if (response.data.Result?.Url) {
      window.location.href = response.data.Result.Url;
    }
  }

  async function redirectToCustomerPortal() {
    const url = window.location.href;

    const response = await apiClient.value.get<IApiResponse<IPaymentSessionLink>>(`/subscriptions/portal-link?ReturnUrl=${url}`);

    if (response.data.Result?.Url) {
      window.location.href = response.data.Result.Url;
    }
  }

  return {
    editingSubscription,
    isSubscribed,
    canUpgrade,

    redirectToCheckout,
    redirectToCustomerPortal,
    startEditingSubscription,
    finishEditingSubscription,
  };
});
